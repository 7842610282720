import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const DisclaimerTitle = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 22px;
    margin-bottom: 30px;
    color: #101214;
    text-align: center;
`;

export const DisclaimerTxt = styled.p`
    margin: 0%;
    margin-bottom: 20px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    font-size: 16px;
    color: #101214;
    text-align: center;

    @media (min-width: 760px){
        width: 70%;
    }
`;
export const DisclaimerLabel = styled.label`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;
export const TestCardSubTitle = styled.p`
    margin: 0%;
    font-size: 12px;
    margin-top: 4px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};

    @media (min-width: 760px){
        font-size: 18px;
        margin-top: 10px;
    }
`;

export const ModalContainer = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const ModalTxt = styled.p`
    margin: 0%;
    margin-top: 30px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    font-size: 16px;
    color: #101214;
    text-align: center;
`;

export const QuestionListWrapper = styled.div`
    width: 100%;
    padding: 0px 40px 0px 40px;
    margin-top: 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
`;

export const RoundedQuestionNumber = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: solid 1px #CECECE;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #000000;
    font-size: 19px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

export const DisplayAllQuestion = styled.p`
    text-align: center;
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 14px;
    color: #101214;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 30px;
`;

export const ModalQuestion = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 16px;
    color: #101214;
    text-align: center;
`;

export const QuestionSection = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 70px;
`;

export const QuestionWrapper = styled.div`
    cursor: pointer;
    border: solid 1px #F1F1EF;
    border-radius: 8px;
    padding: 15px;
    background-color: #ffffff;
`;

export const QuestionTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    font-size: 14px;
    color: #101214;
`;