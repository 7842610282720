import { CSSProperties, FC, useCallback, useEffect, useState } from "react";
import { 
    CompetencesTitle, ProfileSectionBody, 
} from "../styles";
import { 
    InfoLabel, 
    InfosWrapper,
    BadgeWrapper,
    CardPage, 
    CardPageSubTitle, 
    CardPageTitle, 
    FixedModalFooter, 
    Input, InputLabel, 
    InputWrapper, Modal, 
    ModalContainer, 
    ModalSubTitle, 
    ModalTitle, 
    SimpleBadge, 
    SimpleButton,
} from "@components";
import { useScreenSize } from "@utilities/hooks";
import { CV } from "@utilities/types";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    talentCV: CV | null
    onSaveTalentCVRequest: (cv: CV)=> void
}

export const OtherRessources:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
    talentCV,
    onSaveTalentCVRequest,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [linkedin, setLinkedin] = useState<string>('');
    const [webSite, setWebSite] = useState<string>('');
    const [otherLink, setOtherLink] = useState<string>('');

    const handleClose = useCallback(()=> {
        setLinkedin(talentCV?.linkedin || '');
        setWebSite(talentCV?.website || '');
        setOtherLink(talentCV?.otherLink || '');
        setIsVisible(false);
    }, [talentCV]);

    const handleSaveDate = useCallback(()=> {
        onSaveTalentCVRequest({
            ...talentCV!,
            linkedin,
            otherLink,
            website: webSite,
        });

        handleClose();
    }, [linkedin, otherLink, talentCV, webSite, onSaveTalentCVRequest, handleClose]);

    useEffect(()=> {
        if(talentCV){
            setLinkedin(talentCV?.linkedin || '');
            setWebSite(talentCV?.website || '');
            setOtherLink(talentCV?.otherLink || '');
        }
    }, [talentCV])

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Autres ressources
                </CardPageTitle>
                <CardPageSubTitle>
                    Ajoutez des fichiers,  liens pour soutenir et accélérer vos candidatures.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={simpleButtonStyle}
                >
                    Modifier
                </SimpleButton>

                {
                    (talentCV?.linkedin || talentCV?.website || talentCV?.otherLink) && (
                        <ProfileSectionBody style={{marginTop: 40}}>
                            <CompetencesTitle style={{marginTop: 0}}>
                                PRÉSENCE SUR LE WEB
                            </CompetencesTitle>

                            {
                                talentCV.linkedin && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            LinkedIn
                                        </InfoLabel>

                                        <BadgeWrapper>
                                            <SimpleBadge>
                                                🤝️  {talentCV.linkedin}
                                            </SimpleBadge>
                                        </BadgeWrapper>
                                    </InfosWrapper>
                                )
                            }

                            {
                                talentCV.website && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Site internet
                                        </InfoLabel>

                                        <BadgeWrapper>
                                            <SimpleBadge>
                                                🖥️  {talentCV.website}
                                            </SimpleBadge>
                                        </BadgeWrapper>
                                    </InfosWrapper>
                                )
                            }

                            {
                                talentCV.otherLink && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Autre lien
                                        </InfoLabel>

                                        <BadgeWrapper>
                                            <SimpleBadge>
                                                🖥️  {talentCV.otherLink}
                                            </SimpleBadge>
                                        </BadgeWrapper>
                                    </InfosWrapper>
                                )
                            }
                        </ProfileSectionBody>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Autres ressources
                        </ModalTitle>
                        <ModalSubTitle>
                            Ajoutez des fichiers,  liens pour soutenir et accélérer vos candidatures.
                        </ModalSubTitle>

                        <CompetencesTitle>
                            PRÉSENCE SUR LE WEB
                        </CompetencesTitle>

                        <InputWrapper>
                            <InputLabel>
                                🤝 LinkedIn
                            </InputLabel>
                            <Input
                                placeholder="ex : https://linkedin.com/pub/moi"
                                value={linkedin}
                                onChange={setLinkedin}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🖥️ Site internet
                            </InputLabel>
                            <Input
                                placeholder="ex : https://monsite.com"
                                value={webSite}
                                onChange={setWebSite}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🔗 Autre lien 
                            </InputLabel>
                            <Input
                                placeholder="ex : https://github.com/moi"
                                value={otherLink}
                                onChange={setOtherLink}
                            />
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSaveDate}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={handleClose} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}