import { Container, SimpleButton } from "@components";
import { FC, useCallback } from "react";
import { 
    AddButtonContainer, Jobcard, 
    JobcardBody, Jobcardfooter, 
    JobDivider, JobEntitle, 
    JobRightSection, JobsCardContainer, JobSection, 
    PublishDate, SubTitle, 
    TextLink, Title,
} from "../styles";
import { Post } from "@utilities/types";
import { timeSince } from "@utilities/functions";

interface Props {
    jobs: Post[]
    onPreview: (el: Post)=> void
    onEdit: (el: Post)=> void
    onDisplayCreateJobOfferForm: ()=> void
    onDisplayCandidature: (job: Post)=> void
}

export const JobOfferList:FC<Props> = ({
    jobs,
    onPreview, onEdit,
    onDisplayCreateJobOfferForm,
    onDisplayCandidature,
})=> {

    const renderJobCard = useCallback((job: Post, index: number)=> (
        <Jobcard key={index}>
            <JobcardBody>
                <JobSection>
                    <JobEntitle>
                        {job.entitle}
                    </JobEntitle>
                    <JobEntitle>
                        {job.criteria.contratType} - Abidjan
                    </JobEntitle>
                </JobSection>

                <JobRightSection>
                    <JobSection>
                        <JobEntitle>
                            0
                        </JobEntitle>
                        <JobEntitle>
                            A traiter
                        </JobEntitle>
                    </JobSection>

                    <JobDivider />

                    <JobSection style={{cursor: 'pointer'}}
                        onClick={()=> onDisplayCandidature(job)}
                    >
                        <JobEntitle>
                            0
                        </JobEntitle>
                        <JobEntitle>
                            Candidatures
                        </JobEntitle>
                    </JobSection>
                </JobRightSection>
            </JobcardBody>

            <Jobcardfooter>
                <PublishDate>
                    Publié il y a {timeSince(job.createAt)}
                </PublishDate>

                <JobRightSection>
                    <TextLink onClick={()=> onEdit(job)}>
                        Éditer
                    </TextLink>

                    <TextLink onClick={()=> onPreview(job)}>
                        Aperçu
                    </TextLink>
                </JobRightSection>
            </Jobcardfooter>
        </Jobcard>
    ), [onPreview, onEdit, onDisplayCandidature]);

    return (
        <Container>
            <Title>Offres d’emploi</Title>

            <SubTitle>Retrouvez ici toutes vos offres d’emploi courantes ou passées</SubTitle>
        
            <AddButtonContainer>
                <SimpleButton onClick={onDisplayCreateJobOfferForm}>
                    + Ajouter une offre
                </SimpleButton>
            </AddButtonContainer>

            <JobsCardContainer>
                {
                    jobs.map((job, i)=> renderJobCard(job, i))
                }
            </JobsCardContainer>
        </Container>
    )
}