import React from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { StackEnum } from "@utilities/enums";
import { Container, Page } from "@components";

export const EmployerBrandOffices:React.FC = ()=> {

    useAnalyticsPageTracking('EmployerBrandOffices');
    const translate = useTranslation();
    const { itemsMenuNav } = useController();

    return (
        <TabNavigation 
            customBG="#EFECE6"
            activeStack={StackEnum.OrganisationBranding}
        >
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[2].label}
                />

                <Container>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, a deleniti! Dignissimos repellat culpa tempora provident reprehenderit consequatur. Qui quos earum nesciunt repellendus error animi impedit eos facilis voluptatem voluptate?
                </Container>
            </Page>
        </TabNavigation>
    )
}
