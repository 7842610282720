import { CSSProperties, FC, useCallback, useMemo } from "react";
import { Container, Icon, JobOfferCard, Modal, SimpleButton } from "@components";
import { SectionContiner } from "./styles";
import { ApplicationStatusEnum, IconNameEnum } from "@utilities/enums";
import { TalentCVModal, TestCorrectionModal } from "@connectedComponents";
import { Matching, Post } from "@utilities/types";
import { useScreenSize } from "@utilities/hooks";
import { useControllers } from "./useControllers";
import { LeftSection, NavSteps, RightSection } from "./components";

import DefaultAvatar from '@assets/images/defaultImg.png';

interface Props {
    job: Post | null
    postMatching: Matching | null
    handleClose: ()=> void
}

export const JobCadidatureDetails:FC<Props> = ({
    job,
    postMatching,
    handleClose
})=> {

    const { isMobile } = useScreenSize();
    const {
        activeIndex,
        activeSection,
        activeMatching,
        displayTalentCV,
        seletedTalent,
        displayJobOffer,
        displayQcmResult,
        navsSteps,
        setActiveIndex,
        setDisplayTalentCV,
        setSeletedTalent,
        handleNavStepSelected,
        toggleDisplayQcmResult,
        handleDisplayCV,
        toggleDisplayJobOffer,
        handleChangeUseApplicationStatus,
    } = useControllers(postMatching);

    const modalContainerStyles:CSSProperties = useMemo(()=> (
        {
            width: isMobile ? '100%' : '85%',
        }
    ), [isMobile]);

    const buttonStyles:CSSProperties = useMemo(()=> (
        {
            color: '#101214',
            backgroundColor: '#F4F3EF',
        }
    ), []);

    const renderAllAplication = useCallback(()=> (
        <SectionContiner>
            <LeftSection 
                activeMatching={activeMatching} 
                activeIndex={activeIndex} 
                setActiveIndex={setActiveIndex} 
                setSeletedTalent={setSeletedTalent}
            />

            {
                seletedTalent && (
                    <RightSection 
                        handleDisplayCV={handleDisplayCV}
                        toggleDisplayJobOffer={toggleDisplayJobOffer}
                        DefaultAvatar={DefaultAvatar}
                        seletedTalent={seletedTalent}
                        toggleDisplayQcmResult={toggleDisplayQcmResult}
                        handleChangeUseApplicationStatus={handleChangeUseApplicationStatus}
                    />
                )
            }
        </SectionContiner>
    ), [
        activeMatching, activeIndex, 
        seletedTalent, toggleDisplayQcmResult,
        handleDisplayCV, toggleDisplayJobOffer, 
        setActiveIndex, setSeletedTalent,
        handleChangeUseApplicationStatus,
    ]);

    const renderContent = useCallback(()=> {
        switch (activeSection) {
            case ApplicationStatusEnum.All:
                return renderAllAplication();
        
            default:
                return renderAllAplication();
        }
    }, [
        activeSection,
        renderAllAplication,
    ]);

    return (
        <Container>
            <NavSteps 
                navsSteps={navsSteps}
                activeSection={activeSection}
                handleNavStepSelected={handleNavStepSelected}
            />

            <SimpleButton style={buttonStyles} onClick={handleClose}>
                <Icon iconName={IconNameEnum.arrowLeft} color="#101214" /> Retour
            </SimpleButton>

            {renderContent()}

            {
                seletedTalent && (
                    <TalentCVModal 
                        isVisible={displayTalentCV}
                        talent={seletedTalent?.talent!}
                        talentCV={seletedTalent?.cv!}
                        onClose={()=> setDisplayTalentCV(false)}
                    />
                )
            }

            {
                seletedTalent?.qcmResult && (
                    <TestCorrectionModal
                        isVisible={displayQcmResult}
                        qcmSolution={seletedTalent.qcmResult}
                        onClose={toggleDisplayQcmResult}
                    />
                )
            }

            <Modal
                isVisible={displayJobOffer}
                modalContainerStyles={modalContainerStyles}
                onClose={toggleDisplayJobOffer}
            >
                <JobOfferCard 
                    isOpen isOnlyReadMode
                    job={job!}
                />
            </Modal>
        </Container>
    )
}