import React from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { Page } from "@components";

export const RecruiterSecurity:React.FC = ()=> {

    useAnalyticsPageTracking('RecruiterSecurity');
    const translate = useTranslation();
    const { itemsMenuNav } = useController();

    return (
        <TabNavigation customBG="#EFECE6">
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[2].label}
                />


            </Page>
        </TabNavigation>
    )
}
