import { NavigationPathsEnum } from "@utilities/enums";
import { useNavigation, useRecruiterSelectors } from "@utilities/hooks";
import { useCallback, useMemo, useState } from "react";

export const useController = ()=> {

    const { navigateTo } = useNavigation();
    const { recruiter } = useRecruiterSelectors();

    const [isOpenChangeInfosModal, setIsOpenChangeInfosModal] = useState<boolean>(false);
    const [isOpenChangeContactsModal, setIsOpenChangeContactsModal] = useState<boolean>(false);
    const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState<boolean>(false);

    const itemsMenuNav = useMemo(()=> [
        {
            label: 'Informations personnelles',
            goTo: ()=> navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT),
        },
        {
            label: 'Accès et permissions',
            goTo: ()=> navigateTo(NavigationPathsEnum.RECRUITER_PERMISSIONS),
        },
        {
            label: 'Sécurité',
            goTo: ()=> navigateTo(NavigationPathsEnum.RECRUITER_SECURITY),
        },
        {
            label: 'Notifications',
            goTo: ()=> navigateTo(NavigationPathsEnum.RECRUITER_NITIFICATION),
        },
    ], [navigateTo]);

    const toggleIsOpenChangeInfosModal = useCallback(()=> {
        setIsOpenChangeInfosModal(!isOpenChangeInfosModal);
    }, [isOpenChangeInfosModal]);

    const toggleIsOpenChangeContactsModal = useCallback(()=> {
        setIsOpenChangeContactsModal(!isOpenChangeContactsModal);
    }, [isOpenChangeContactsModal]);

    const toggleIsOpenChangePasswordModal = useCallback(()=> {
        setIsOpenChangePasswordModal(!isOpenChangePasswordModal);
    }, [isOpenChangePasswordModal]);

    const handleSaveInfos = useCallback(()=> {

    }, []);

    const handleSaveContacts = useCallback(()=> {

    }, []);

    const handleSavePassword = useCallback(()=> {

    }, []);

    return {
        itemsMenuNav,
        recruiter,
        isOpenChangeInfosModal,
        isOpenChangeContactsModal,
        isOpenChangePasswordModal,
        toggleIsOpenChangeInfosModal,
        toggleIsOpenChangeContactsModal,
        toggleIsOpenChangePasswordModal,
        handleSaveInfos,
        handleSaveContacts,
        handleSavePassword,
    }
};
