import { FC, useState } from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { CreateNewPassword, LoginComponent } from '@connectedComponents';
import { Modal } from "@components";
import { useControlers } from "./useControlers";

export const CreateNewPasswordPage:FC = ()=> {

    useAnalyticsPageTracking('CreateNewPasswordPage');
    const translate = useTranslation();
    const { index, handleGoToHome, handleChangePassword } = useControlers();

    const [isLoginComponent, setIsLoginComponent] = useState<boolean>(false);

    return (
        <>
            <Modal
                isVisible
                onClose={handleGoToHome}
            >
                {
                    isLoginComponent ? (
                        <LoginComponent onCloseModal={handleGoToHome} />
                    ) : (
                        <CreateNewPassword 
                            index={index}
                            handleGoToSignin={()=> setIsLoginComponent(true)}
                            handleChangePassword={handleChangePassword}
                        />
                    )}
            </Modal>
        </>
    )
}
