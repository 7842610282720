import { useCallback, useEffect, useMemo, useState } from "react";
import { EnumContratType, NavigationPathsEnum } from "@utilities/enums";
import { 
    useAppointmentDispatch,
    useAppointmentSelectors,
    useNavigation, usePostsDispatch, 
    usePostSelectors, useRecruiterDispatch, 
    useRecruiterSelectors,
} from "@utilities/hooks";
import { EnumUserLevel, EnumWorkTime, Post, qcmTestType, TEST_QCM } from "@utilities/types";

export const useController = ()=> {

    const { recruiter } = useRecruiterSelectors();
    const { organisationPosts, postMatching } = usePostSelectors();
    const { recruiterAppointment } = useAppointmentSelectors();
    const { navigateTo } = useNavigation();
    const { 
        onSaveOrganisationPostRequest, 
        onGetOrganisationPostsRequest,
        onGetResponseToJobOfferRequest,
    } = usePostsDispatch();
    const {
        onGenerateTestByIA,
        onSaveOrganisationTestRequest,
    } = useRecruiterDispatch();
    const {
        onGetRecruiterAppointmentRequest,
        onCreateNewAppointmentRequest,
        onDeletedAppointmentRequest,
    } = useAppointmentDispatch();

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [displayJobOfferPreview, setDisplayJobOfferPreview] = useState<boolean>(false);
    const [displayTestGeneratedForJobOfferModal, setDisplayTestGeneratedForJobOfferModal] = useState<boolean>(false);
    const [entitle, setEntitle] = useState<string>('');
    const [sector, setSector] = useState<string>('');
    const [office, setOffice] = useState<string>('');
    const [department, setDepartment] = useState<string>('');
    const [postDescription, setPostDescription] = useState<string>('');
    const [profileSought, setProfileSought] = useState<string>('');
    const [conductOfInterviews, setConductOfInterviews] = useState<string>('');
    const [schoolLevel, setSchoolLevel] = useState<string>('');
    const [experienceLevel, setExperienceLevel] = useState<string>('');
    const [contratType, setContratType] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [teleworking, setTeleworking] = useState<string>('');
    const [workingTime, setWorkingTime] = useState<string>('');
    const [minSalary, setMinSalary] = useState<string>('');
    const [maxSalary, setMaxSalary] = useState<string>('');
    const [faq, setFaq] = useState<{question: string, response: string}[]>([{question: '', response: '' }]);
    
    const [entitleSave, setEntitleSave] = useState<string>('');
    const [selectedPost, setSelectedPost] = useState<Post | null>(null);

    const [testGeneratedForJobOfferModalIndex, setTestGeneratedForJobOfferModalIndex] = useState<number>(0);
    const [generatedTestTmp, setGeneratedTestTmp] = useState<any>(null);

    const itemsMenuNav = useMemo(()=> [
        {
            label: 'Offres d’emploi et candidatures',
            goTo: ()=> navigateTo(NavigationPathsEnum.JOB_OFFER_MANAGEMENT),
        },
        {
            label: 'Tests de compétence',
            goTo: ()=> navigateTo(NavigationPathsEnum.JOB_OFFER_MANAGEMENT_TEST),
        },
        {
            label: 'Mes rendez-vous',
            goTo: ()=> navigateTo(NavigationPathsEnum.JOB_OFFER_MANAGEMENT_APPOINTMENT),
        }
    ], [navigateTo]);

    const handleInitForm = useCallback(()=> {
        setEntitle('');
        setSector('');
        setOffice('');
        setDepartment('');
        setPostDescription('');
        setProfileSought('');
        setConductOfInterviews('');
        setSchoolLevel('');
        setExperienceLevel('');
        setContratType('');
        setStartDate('');
        setTeleworking('');
        setWorkingTime('');
        setMinSalary('');
        setMaxSalary('');
        setFaq([{question: '', response: '' }]);
        setSelectedPost(null);
    }, []);

    const handleCloseForm = useCallback(()=> {
        setActiveIndex(0);
        handleInitForm();
    }, [handleInitForm]);

    const onToggleDisplayJobOfferPreview = useCallback(()=> {
        setDisplayJobOfferPreview(!displayJobOfferPreview);
    }, [displayJobOfferPreview]);

    const onToggleDisplayTestGeneratedForJobOfferModal = useCallback(()=> {
        setDisplayTestGeneratedForJobOfferModal(!displayTestGeneratedForJobOfferModal);
        if(displayTestGeneratedForJobOfferModal){
            handleCloseForm();
        }
    }, [displayTestGeneratedForJobOfferModal, handleCloseForm]);

    const handleAddtest = useCallback(()=> {
        onToggleDisplayJobOfferPreview();
        onToggleDisplayTestGeneratedForJobOfferModal();
    }, [onToggleDisplayJobOfferPreview, onToggleDisplayTestGeneratedForJobOfferModal]);

    const addFaq = useCallback(()=> {
        setFaq(e=> [...e, {question: '', response: ''}]);
    }, []);

    const deletedFaq = useCallback((index: number)=> {
        setFaq(e=> e.filter((el, i)=> i !== index));
    }, []);

    const handleUpdatedFaqQuestion = useCallback((str: string, index: number)=> {
        setFaq(e=> e.map((el, i)=> {
            if(i === index){
                el.question = str;
            }
            return el;
        }))
    }, []);

    const handleUpdatedFaqResponse = useCallback((str: string, index: number)=> {
        setFaq(e=> e.map((el, i)=> {
            if(i === index){
                el.response = str;
            }
            return el;
        }))
    }, []);

    const onSaveJobOffer = useCallback(()=> {
        if(
            entitle || sector || postDescription || experienceLevel ||
            profileSought || conductOfInterviews || schoolLevel || 
            contratType || startDate || teleworking || workingTime
        ){
            onSaveOrganisationPostRequest({
                entitle,
                sector,
                office,
                department,
                description: postDescription,
                profileSought,
                conductOfInterviews,
                levelOfStudy: schoolLevel,
                experience: Number(experienceLevel),
                criteria: {
                    contratType: contratType as EnumContratType,
                    teleworking: teleworking,
                    workTime: workingTime as EnumWorkTime,
                    minSalaryMonth: Number(minSalary),
                    maxSalaryMonth: Number(maxSalary)
                },
                faq,
                location: '',
                startAt: new Date(startDate),
            });
            setEntitleSave(entitle);
            onToggleDisplayJobOfferPreview();
            handleInitForm();
        }
        
    }, [
        conductOfInterviews, contratType, 
        department, entitle, experienceLevel, 
        faq, maxSalary, minSalary, office, 
        postDescription, profileSought, 
        schoolLevel, sector, startDate, 
        teleworking, workingTime,
        onSaveOrganisationPostRequest, 
        onToggleDisplayJobOfferPreview,
        handleInitForm,
    ]);

    const handlePreviewJob = useCallback((job: Post)=> {
        setSelectedPost(job);
        onToggleDisplayJobOfferPreview();
    }, [onToggleDisplayJobOfferPreview]);

    const handleEditJob = useCallback((job: Post)=> {
        setEntitle(job.entitle);
        setSector(job.sector);
        setOffice(job.office || '');
        setDepartment(job.department || '');
        setPostDescription(job.description);
        setProfileSought(job.profileSought);
        setConductOfInterviews(job.conductOfInterviews || '');
        setSchoolLevel(job.levelOfStudy);
        setExperienceLevel(String(job.experience));
        setContratType(job.criteria.contratType);
        setStartDate('');
        setTeleworking(job.criteria.teleworking);
        setWorkingTime(job.criteria.workTime);
        setMinSalary(String(job.criteria.minSalaryMonth));
        setMaxSalary(String(job.criteria.maxSalaryMonth));
        setFaq(job.faq || [{question: '', response: '' }]);
        setSelectedPost(job);
        onToggleDisplayJobOfferPreview();
    }, [onToggleDisplayJobOfferPreview]);

    const onGeneratedTest = useCallback(async(testLevel: string)=> {
        const result = await onGenerateTestByIA(selectedPost?._id!, testLevel);
        setGeneratedTestTmp({...result, level: testLevel});
        setTestGeneratedForJobOfferModalIndex(1);
    }, [selectedPost, onGenerateTestByIA]);

    const handleSelectedCandidature = useCallback(async(job: Post)=> {
        await onGetResponseToJobOfferRequest(job._id!);
        setActiveIndex(2);
        setSelectedPost(job);
    }, [onGetResponseToJobOfferRequest]);

    const handleSaveJobTest = useCallback(async(
        testTitle: string,
        testLevel: string,
        selectedGoodResponseNumber: number,
        qcm: qcmTestType[]
    )=> {
        if(recruiter){
            const time = testLevel === EnumUserLevel.debutant ? 5
                : testLevel === EnumUserLevel.intermediaire ? 10
                : testLevel === EnumUserLevel.confirme ? 15 : 10;

            const newOrganisationTest:TEST_QCM = {
                _createdBy_ID: recruiter._organisationID!,
                _isOrganisationTest: true,
                entitle: testTitle,
                time,
                level: testLevel as EnumUserLevel,
                qcm: qcm,
                minimumOfCorrectAnswer: selectedGoodResponseNumber,
            };
            const postID = selectedPost ? selectedPost._id : '';

            const result = await onSaveOrganisationTestRequest(newOrganisationTest, postID);

            setActiveIndex(0);
            setTestGeneratedForJobOfferModalIndex(0);
            setSelectedPost(null);
            onToggleDisplayTestGeneratedForJobOfferModal();
        }
    }, [
        selectedPost, recruiter,
        onSaveOrganisationTestRequest,
        onToggleDisplayTestGeneratedForJobOfferModal,
    ]);

    const handleAddNewTimeSlot = useCallback(async(
        start: Date, end: Date, 
        startTimeSlot: string, endTimeSlot: string,
    )=> {
        await onCreateNewAppointmentRequest({
            _organisationID: recruiter?._organisationID!,
            event: {
                title: `${startTimeSlot} - ${endTimeSlot}`,
                start,
                end,
                allDay: false,
                // type: EnumRdvCanalType,
                isEnabled: true,
                isAchived: false,
                isCompleted: false,
            }
        });
    }, [
        recruiter,
        onCreateNewAppointmentRequest,
    ]);

    const handleDeletedTimeSlot = useCallback((
        timeSlotStartDate: Date,
        timeSlotEndDate: Date,
    )=> {
        const findAppointment = recruiterAppointment.find(el=> (
            new Date(el.event.start).getTime() === timeSlotStartDate.getTime() 
            && new Date(el.event.end).getTime() === timeSlotEndDate.getTime()
        ));

        if(findAppointment){
            onDeletedAppointmentRequest(findAppointment._id!);
        }
    }, [
        recruiterAppointment,
        onDeletedAppointmentRequest,
    ]);

    useEffect(()=> {
        if(recruiter){
            onGetOrganisationPostsRequest(recruiter._organisationID!);
            onGetRecruiterAppointmentRequest(recruiter.token!);
        }
    }, [recruiter]);

    useEffect(()=> {
        if(organisationPosts.length > 0){
            const post = organisationPosts.find(el=> el.entitle === entitleSave);
            if(post){
                setSelectedPost(post);
            }
        }
    }, [entitleSave, organisationPosts]);

    return {
        postMatching,
        organisationPosts,
        itemsMenuNav,
        activeIndex,
        displayJobOfferPreview,
        displayTestGeneratedForJobOfferModal,
        selectedPost, setSelectedPost,
        entitle, setEntitle,
        sector, setSector,
        office, setOffice, onSaveJobOffer,
        department, setDepartment,
        postDescription, setPostDescription,
        profileSought, setProfileSought,
        conductOfInterviews, setConductOfInterviews,
        schoolLevel, setSchoolLevel,
        experienceLevel, setExperienceLevel,
        contratType, setContratType,
        startDate, setStartDate,
        teleworking, setTeleworking,
        workingTime, setWorkingTime,
        minSalary, setMinSalary,
        maxSalary, setMaxSalary,
        faq, setFaq, handleAddtest,
        generatedTestTmp,
        testGeneratedForJobOfferModalIndex, 
        recruiterAppointment,
        handleAddNewTimeSlot,
        handleDeletedTimeSlot,
        setTestGeneratedForJobOfferModalIndex,
        handlePreviewJob, handleEditJob,
        onToggleDisplayJobOfferPreview,
        handleCloseForm, addFaq,
        deletedFaq, handleUpdatedFaqQuestion,
        handleUpdatedFaqResponse,
        setActiveIndex, onGeneratedTest,
        handleSelectedCandidature, handleSaveJobTest,
        onToggleDisplayTestGeneratedForJobOfferModal,
    }
};
