import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const SectionContiner = styled.section`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
    margin-top: 50px;

    @media (min-width: 760px){
        flex-direction: row;
        margin-top: 70px;
    }
`;

export const LeftSectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 35px;
    margin-top: 20px;

    @media (min-width: 760px){
        width: 350px;
        margin-top: 40px;
    }
`;

export const FiltersGroup = styled.div`
    display: flex;
    gap: 20px;
`;

export const RightSectionContainer = styled.div`
    flex: 1;
    margin-top: 10px;

    @media (min-width: 760px){
        margin-top: 50px;
    }
`;

export const TalentList = styled.div`
    width: 100%;
    max-height: 300px;
    overflow: hidden scroll;
    border-radius: 12px;
    /* border: solid 1px #B4B4B4; */
    background-color: #FFFFFF;

    @media (min-width: 760px){
        max-height: 750px;
        overflow-y: scroll;
    }
`;

export const ListItem = styled.div`
    flex: 1;
    height: 75px;
    /* border-bottom: solid 1px #B4B4B4; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
`;

export const TalentItemName = styled.p`
    margin: 0%;
    font-size: 16px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
`;

export const TxtGroup = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-top: 5px;
`;

export const TalentItemtxt = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_MEDIUM};
`;

export const CardPageIASection = styled.div`
    margin-top: 40px;
`;

export const CardPageIASubSection = styled.div`
    margin-top: 30px;
`;

export const CardPageIATitle = styled.p`
    margin: 0%;
    font-size: 16px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
`;

export const CardPageIAText = styled.p`
    margin: 0%;
    margin-top: 20px;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;

export const NavStepsContainer = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    gap: 25px;
    margin: 0px 0px 20px 0px;
    top: 220px;

    &::-webkit-scrollbar {
        display: none;
    }

    & > * {
        scroll-snap-align: start;
        flex-shrink: 0;
        margin-right: 10px;
    }

    @media (min-width: 760px){
        justify-content: center;
        margin: 20px 0px 50px 0px;
    }
`;

export const NavStepItem = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
`;

export const NavStepItemTxt = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    cursor: pointer;
`;

export const NavStepBadge = styled.div`
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #F9F9F9;
    font-size: 12px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-color: #F9F9F9 !important;
`;

export const ButtonGroup = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 760px){
        flex-direction: row;
        align-items: center;
        gap: 0px;
    }
`;

export const ButtonsGroupSection = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 760px){
        align-items: center;
    }
`;

export const CardPageSection = styled.div`
    margin-bottom: 40px;
`;

export const ProfileContainer = styled.div`
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const ProfileSubContainer = styled.div`

`;

export const Avatar = styled.img`
    width: 70px;
    height: 74px;
    border-radius: 10px;
`;

export const TalentName = styled.p`
    margin: 0%;
    font-size: 22px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;

export const TalentCvTitle = styled.p`
    margin: 0%;
    font-size: 16px;
    margin-top: 5px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

export const TalentCvDescription = styled.p`
    margin: 0%;
    font-size: 14px;
    margin-top: 30px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;
