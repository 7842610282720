import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Title = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};

    @media (min-width: 760px){
        font-size: 22px;
    }
`;

export const SubTitle = styled.p`
    margin: 0%;
    font-size: 12px;
    color: #101214;
    margin-top: 12px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px){
        font-size: 16px;
    }
`;

export const ProposalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
`;

export const OrganisationProposal = styled.div`
    position: relative;
    border: solid 2px #101214;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const Image = styled.img`
    width: 45px;
    height: 45px;
    border-radius: 10px;
    object-fit: contain;
`;

export const OrganisationDetails = styled.div`
    display: flex;
    flex-direction: column;
`;

export const OrganisationName = styled.p`
    margin: 0%;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px){
        font-size: 18px;
    }
`;

export const OrganistionMessage = styled.p`
    margin: 0%;
    font-size: 10px;
    color: #101214;
    margin-top: 8px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};

    @media (min-width: 760px){
        font-size: 12px;
    }
`;

export const ModalSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;

    @media (min-width: 760px){
        flex-direction: row;
        align-items: flex-start;
        gap: 100px;
        margin-bottom: 50px;
    }
`;

export const ModalSubSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;
