import React, { useCallback } from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { Page } from "@components";
import { StackEnum } from "@utilities/enums";
import { 
    CreateJobOffer,
    JobCadidatureDetails,
    JobOfferList, JobOfferPreviewModal,
    TestGeneratedForJobOfferModal,
} from "./components";

export const JobOffersManagement:React.FC = ()=> {

    useAnalyticsPageTracking('JobOffersManagement');
    const translate = useTranslation();
    const { 
        postMatching,
        organisationPosts,
        itemsMenuNav, faq,
        displayJobOfferPreview,
        generatedTestTmp,
        displayTestGeneratedForJobOfferModal,
        selectedPost, setSelectedPost,
        entitle, setEntitle,
        sector, setSector,
        office, setOffice,
        activeIndex, setActiveIndex,
        department, setDepartment,
        postDescription, setPostDescription,
        profileSought, setProfileSought,
        conductOfInterviews, setConductOfInterviews,
        schoolLevel, setSchoolLevel,
        experienceLevel, setExperienceLevel,
        contratType, setContratType,
        startDate, setStartDate,
        teleworking, setTeleworking,
        workingTime, setWorkingTime,
        minSalary, setMinSalary,
        maxSalary, setMaxSalary,
        handlePreviewJob, handleEditJob,
        handleAddtest, onSaveJobOffer,
        onToggleDisplayJobOfferPreview,
        handleCloseForm, addFaq,
        deletedFaq, handleUpdatedFaqQuestion,
        handleUpdatedFaqResponse, onGeneratedTest,
        onToggleDisplayTestGeneratedForJobOfferModal,
        testGeneratedForJobOfferModalIndex, 
        setTestGeneratedForJobOfferModalIndex,
        handleSelectedCandidature, handleSaveJobTest,
    } = useController();

    const renderJobOfferList = useCallback(()=> (
        <JobOfferList 
            jobs={organisationPosts}
            onPreview={handlePreviewJob}
            onEdit={handleEditJob}
            onDisplayCreateJobOfferForm={()=> setActiveIndex(1)}
            onDisplayCandidature={handleSelectedCandidature}
        />
    ), [
        organisationPosts, setActiveIndex,
        handleEditJob, handlePreviewJob, 
        handleSelectedCandidature,
    ]);

    const renderCreateJobOffer = useCallback(()=> (
        <CreateJobOffer 
            entitle={entitle}
            setEntitle={setEntitle}
            sector={sector}
            setSector={setSector}
            office={office}
            setOffice={setOffice}
            department={department}
            setDepartment={setDepartment}
            postDescription={postDescription}
            setPostDescription={setPostDescription}
            profileSought={profileSought}
            setProfileSought={setProfileSought}
            conductOfInterviews={conductOfInterviews}
            setConductOfInterviews={setConductOfInterviews}
            schoolLevel={schoolLevel}
            setSchoolLevel={setSchoolLevel}
            experienceLevel={experienceLevel}
            setExperienceLevel={setExperienceLevel}
            contratType={contratType}
            setContratType={setContratType}
            startDate={startDate} 
            setStartDate={setStartDate}
            teleworking={teleworking}
            setTeleworking={setTeleworking}
            workingTime={workingTime}
            setWorkingTime={setWorkingTime}
            minSalary={minSalary}
            setMinSalary={setMinSalary}
            maxSalary={maxSalary}
            setMaxSalary={setMaxSalary}
            faq={faq}
            deletedFaq={deletedFaq}
            addFaq={addFaq}
            onSaveJobOffer={onSaveJobOffer}
            handleCloseForm={handleCloseForm}
            handleUpdatedFaqQuestion={handleUpdatedFaqQuestion}
            handleUpdatedFaqResponse={handleUpdatedFaqResponse}
        />
    ), [
        startDate, teleworking, workingTime, conductOfInterviews, 
        contratType,  department, entitle, experienceLevel, faq,
        postDescription, profileSought, schoolLevel, sector,
        maxSalary, minSalary, office, 
        onSaveJobOffer, handleCloseForm, 
        setWorkingTime, deletedFaq, addFaq,
        handleUpdatedFaqQuestion, handleUpdatedFaqResponse, 
        setConductOfInterviews, setContratType, setDepartment, 
        setEntitle, setExperienceLevel, setMaxSalary, setMinSalary, 
        setOffice, setPostDescription, setProfileSought, 
        setSchoolLevel, setSector, setStartDate, setTeleworking, 
    ]);

    const renderJobCadidatureDetails = useCallback(()=> (
        <JobCadidatureDetails 
            job={selectedPost}
            postMatching={postMatching}
            handleClose={()=> {
                setActiveIndex(0);
                setSelectedPost(null);
            }} 
        />
    ), [
        selectedPost, postMatching,
        setActiveIndex, setSelectedPost
    ]);

    const renderContent = useCallback(()=> {
        switch (activeIndex) {
            case 0:
                return renderJobOfferList();
            case 1:
                return renderCreateJobOffer();
            case 2:
                return renderJobCadidatureDetails();
        
            default:
                return renderJobOfferList();
        }
    }, [
        activeIndex,
        renderCreateJobOffer, 
        renderJobOfferList,
        renderJobCadidatureDetails,
    ]);

    return (
        <TabNavigation 
            customBG="#EFECE6"
            activeStack={StackEnum.JobOfferManagement}
        >
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[0].label}
                />

                {renderContent()}

                <JobOfferPreviewModal   
                    job={selectedPost}
                    displayJobOfferPreview={displayJobOfferPreview}
                    onAddTest={handleAddtest}
                    onToggleDisplayJobOfferPreview={()=> {
                        onToggleDisplayJobOfferPreview();
                        handleCloseForm();
                    }}
                    onEdit={onToggleDisplayJobOfferPreview}
                />

                <TestGeneratedForJobOfferModal 
                    onGeneratedTest={onGeneratedTest}
                    generatedTestTmp={generatedTestTmp}
                    testGeneratedForJobOfferModalIndex={testGeneratedForJobOfferModalIndex}
                    setTestGeneratedForJobOfferModalIndex={setTestGeneratedForJobOfferModalIndex}
                    displayTestGeneratedForJobOfferModal={displayTestGeneratedForJobOfferModal}
                    onToggleDisplayTestGeneratedForJobOfferModal={onToggleDisplayTestGeneratedForJobOfferModal}
                    handleSaveJobTest={handleSaveJobTest}
                />
            </Page>
        </TabNavigation>
    )
}
