import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Section = styled.section`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const SubTitleUnderline = styled.p`
    text-align: center;
    text-decoration: underline;
    margin: 0%;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;

export const Title = styled.p`
    text-align: center;
    margin: 0%;
    font-size: 18px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;
export const SubTitle = styled.p`
    text-align: center;
    margin: 0%;
    margin-bottom: 20px;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
`;
