// FR: Définit une énumération pour les chemins de navigation dans l'application.
// EN: Defines an enumeration for navigation paths in the application.

export enum NavigationPathsEnum {
    NOT_FOUND = '*',
    HOME = '/',
    FIND_TALENT = '/rechercher-des-talents',
    FIND_JOB_OFFER = '/rechercher-des-jobs',
    Find_ORGANISATION = '/rechercher-une-entreprise',
    ORGANISATION_PAGE = '/entreprise',
    
    TALENT_ACCOUNT = '/talent/mon-compte',
    TALENT_ACCOUNT_SETTINGS = '/talent/settings',
    TALENT_ACCOUNT_TESTS = '/talent/mes-tests',
    TALENT_ACCOUNT_RECRUITERS_PROPOSALS = '/talent/propositions-de-recruteurs',
    TALENT_ACCOUNT_APPLICATIONS_TRACKING = '/talent/suivi-des-candidatures',
    VALIDATION_TALENT_ACCOUNT = '/validation-de-compte',
    CREATE_NEW_PASSWORD = '/creer-nouveau-de-passe',

    EMPLOYER_BRAND_GENERAL = '/marque-employeur/generals',
    EMPLOYER_BRAND_DEPARTMENT = '/marque-employeur/departements',
    EMPLOYER_BRAND_OFFICES = '/marque-employeur/bureaux',

    JOB_OFFER_MANAGEMENT = '/recrutement/gestions-des-offres',
    JOB_OFFER_MANAGEMENT_TEST = '/recrutement/gestions-des-offres/tests',
    JOB_OFFER_MANAGEMENT_APPOINTMENT = '/recrutement/rdv',

    RECRUITER_ACCOUNT = '/recruteur/mon-compte',
    RECRUITER_PERMISSIONS = '/recruteur/mon-compte/permissions',
    RECRUITER_SECURITY = '/recruteur/mon-compte/securite',
    RECRUITER_NITIFICATION = '/recruteur/mon-compte/notification',
}
