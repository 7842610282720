import { CVstateEnum, EnumContratType, EnumJobCategory } from "@utilities/enums"
import { ErrorType } from "./error.type"
import { EnumUserLevel } from "./qcm.type"

export interface CV {
    _id?: string
    _userID?: string
    title: string
    shortDescription: string
    longDescription: string
    languages: string[]
    salary: number
    skills: string[]
    formations: Formation[]
    professionalExperiences: ProfessionalExperience[]
    linkedin: string
    website: string
    otherLink: string
    criteria: TalentCriteriaType
    state: CVstateEnum
    level?: EnumUserLevel
    lastModificationDate?: Date
    qcmIsLoading?: boolean
    jobCategories?: EnumJobCategory[]
}

export interface TalentCriteriaType {
    jobTypes?: string[]
    localisation?: string
    contratTypes?: string[]
    teleWoring?: string[]
    level?: string
    minSalary?: number
    details?: string
}

export interface TalentCVInitialStateType {
    talentCV: CV | null
    saveTalentCVsuccess: boolean
    saveTalentCVfailure: ErrorType | null
    getTalentCVfailure: ErrorType | null
}

export interface FindTalentInitialStateType {
    talentCVs: CV[]
    searchTalentSuccess: boolean
    searchTalentFailure: ErrorType | null
    getTalentCVsfailure: ErrorType | null
}

export interface SearchTalentType {
    searchStr?: string
    competences?: string[]
    minSalary?: string
    contratType?: string
    workTime?: string
    localisation?: string
}

export interface QCM {
    _id: string
    _userID: string
    level: string
    qcm_debutant: qcmType[] | null
    qcm_intermediaire: qcmType[] | null
    qcm_confirme: qcmType[] | null
}

export type qcmType = {
    question: string
    reponse_a: string
    reponse_b: string
    reponse_c: string
    reponse_d: string
    solution: string
    user_response?: string
    explication: string
}

export interface Formation {
    isStillInFormation: boolean
    startMonth: number
    startYear: number
    endMonth: number | null
    endYear: number | null
    name: string
    level: EnumFormationLevel | string
    organization: string
    description: string
    skills: string[]
}

export interface ProfessionalExperience {
    fullTeleWork: boolean
    isStillInPost: boolean
    startMonth: number
    startYear: number
    endMonth: number | null
    endYear: number | null
    name: string
    description: string
    skills: string[]
    contratType: EnumContratType
    organization: string
    place: string
    // sizeOfCompany: EnumSizeOfCompany | string
    // activityArea: string
}

export enum EnumFormationLevel {
    secondaire = 'secondaire',
    bac = 'BAC',
    bac_1 = 'BAC +1',
    bac_2 = 'BAC +2',
    bac_3 = 'BAC +3',
    bac_4 = 'BAC +4',
    bac_5 = 'BAC +5',
    bac_5_plus = '> BAC +5'
}

export enum EnumNatureOfOrganization {
    ecole_commerce_gestion = 'Ecole de commerce et/ou Gestion',
    ecole_ingenieur = 'Ecole Ingénieur',
    ingenieur_universite = 'Ingénieur des Universités',
    autres_ecoles = 'Autre ecole',
    diplome_universitaire = 'Diplome universitaire',
    autre = 'Autre',
}

export enum EnumSizeOfCompany {
    tpe = 'TPE',
    pme = 'PME',
    eti = 'ETI',
    grand_groupe = 'Grand groupe',
}