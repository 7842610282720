import { CSSProperties, FC, useCallback, useState } from "react";
import { useAnalyticsPageTracking, useScreenSize, useTranslation } from "@utilities/hooks";
import { TabNavigation, TalentCVModal } from '@connectedComponents';

import { IconNameEnum, StackEnum } from "@utilities/enums";
import { useControllers } from "./useControllers";
import { 
    AnimatedGradianButton, DropdownSelect, 
    Icon, Input, Page, SimpleButton, 
    TalentCard, FilterTextElement,
    TodoComponent,
    BadgeWrapper,
    BadgeWithIcon,
    InputWrapper,
    InputLabel,
    LocalisationAutoComplete,
} from "@components";
import { CardWrapper, Location, PageTitle, SearchSectionContainer, SearchSection } from "./styles";
import { CV, UserTalent } from "@utilities/types";
import { activitiesSectors, jobTypes, telework } from "@utilities/static";

type talentCVType = { cv: CV, talent: UserTalent };

export const FindTalent:FC= ()=> {

    useAnalyticsPageTracking('FindTalent');
    const translate = useTranslation();
    const {isMobile} = useScreenSize();

    const {
        talentCVList,
        onSearchTalent,
        onCreateNewAppointmentRequest,
    } = useControllers();

    const [selectedTalentCV, setSelectedTalentCV] = useState<talentCVType | null>(null);

    const [search, setSearch] = useState<string>('');
    const [cityOrCountry, setCityOrCountry] = useState<string>('');
    const [minSalary, setMinSalary] = useState<string>('');
    const [contratType, setContratType] = useState<string>('');
    const [workTime, setWorkTime] = useState<string>('');
    const [enableMoreFilter, setEnableMoreFilter] = useState<boolean>(false);
    const [competence, setCompetence] = useState<string>('');
    const [competences, setCompetences] = useState<string[]>([]);

    // const [search, setSearch] = useState<string>('');
    // const [minSalary, setMinSalary] = useState<string>('');
    // const [contratType, setContratType] = useState<string>('');
    // const [workTime, setWorkTime] = useState<string>('');

    // const [competence, setCompetence] = useState<string>('');
    // const [competences, setCompetences] = useState<string[]>([]);

    // const contratTypeList = useMemo(()=> {
    //     return enumToStringArray(EnumContratType);
    // }, []);

    // const workTimeList = useMemo(()=> {
    //     return enumToStringArray(EnumWorkTime);
    // }, []);

    const handleAddCompetence = useCallback(()=> {
        if(competence){
            setCompetences(e=> [...e, competence]);
            setCompetence('');
        }
    }, [competence]);

    const handleDeleteCompetence = useCallback((deletedCompetence: string)=> {
        setCompetences(e=> (
            e.filter(el=> el !== deletedCompetence)
        ))
    }, []);

    const handleSearch = useCallback(()=> {
        if(!(search || minSalary || contratType || workTime || competences.length > 0)){
            return alert('Il faut au moins un criter de recherche !');
        }

        onSearchTalent({
            ...(search ? {searchStr: search} : {}),
            ...(competences? {competences} : {}),
            ...(minSalary ? {minSalary} : {}),
            ...(contratType ? {contratType} : {}),
            ...(workTime ? {workTime} : {}),
            ...(cityOrCountry ? {localisation: cityOrCountry} : {})
        });
    }, [
        competences, contratType, 
        minSalary, search, 
        cityOrCountry, workTime, 
        onSearchTalent
    ]);

    const renderSearchSection = useCallback(()=> {

        return (
            <SearchSectionContainer>
                <SearchSection>
                    <Input
                        inputType='search'
                        placeholder="Recherchez par job, mot-clé..."
                        value={search}
                        onChange={setSearch}
                        onClearInput={()=> setSearch('')}
                        noRadius={!isMobile}
                    />
                    <DropdownSelect
                        placeholder="Secteur"
                        leftIconText="🏢"
                        elements={activitiesSectors}
                        onSelectedElement={e=> {}}
                        width={!isMobile ? 450 : undefined}
                        noRadius={!isMobile}
                    />

                    <DropdownSelect 
                        placeholder="Type de job"
                        leftIconText="💼"
                        elements={jobTypes}
                        onSelectedElement={e=> {}}
                        width={!isMobile ? 450 : undefined}
                        noRadius={!isMobile}
                    />
                    <DropdownSelect 
                        placeholder="Télétravail"
                        leftIconText="🏠"
                        elements={telework}
                        onSelectedElement={e=> {}}
                        width={!isMobile ? 450 : undefined}
                        noRadius={!isMobile}
                    />
                </SearchSection>

                <InputWrapper style={{marginBottom: cityOrCountry ? 20 : 0}}>
                    <InputLabel>
                        📍 Localisation 
                    </InputLabel>
                    <LocalisationAutoComplete
                        placeholder="Ville, région ou pays"
                        onSelectedLocalisation={e=> setCityOrCountry(e)}
                    />
                    {
                        cityOrCountry && (
                            <Location>
                                📍 {cityOrCountry}
                            </Location>
                        )
                    }
                </InputWrapper>

                <TodoComponent 
                    placeholder={"ex: Figma"} 
                    value={competence} 
                    onChange={setCompetence} 
                    onAdd={handleAddCompetence}                    
                />
                <BadgeWrapper>
                    {
                        competences.map((el, i)=> (
                            <BadgeWithIcon 
                                key={i}
                                onIconPress={()=> handleDeleteCompetence(el)}
                            >{el}</BadgeWithIcon>
                        ))
                    }
                </BadgeWrapper>

                <AnimatedGradianButton onClick={handleSearch}>
                    <Icon iconName={IconNameEnum.search} color="#fff" />
                    Rechercher
                </AnimatedGradianButton>
            </SearchSectionContainer>
        )
    }, [
        search, isMobile, cityOrCountry, 
        competence, competences, 
        handleAddCompetence, handleSearch, 
        handleDeleteCompetence,
    ]);

    return (
        <TabNavigation activeStack={StackEnum.FindTalent}>
            <Page>

                {renderSearchSection()}

                <PageTitle>Explorer les talents</PageTitle> 

                <CardWrapper>
                    {
                        talentCVList.map((el, i)=> (
                            <TalentCard 
                                key={i} talentCV={el}
                                onPress={setSelectedTalentCV}
                            />
                        ))
                    }
                </CardWrapper>

                {
                    selectedTalentCV && (
                        <TalentCVModal 
                            talent={selectedTalentCV?.talent}
                            talentCV={selectedTalentCV.cv}
                            isVisible={!!selectedTalentCV}
                            onClose={()=> setSelectedTalentCV(null)}
                        />
                    )
                }
            </Page>
        </TabNavigation>
    )
}
