import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { 
    FloatButtonGroup, IconWrapper, 
    Location,
    ProfileSectionBody, ProfileSectionHead, 
} from "../styles";
import { 
    InfoLabel, 
    InfosWrapper,
    BadgeWithIcon,
    DescriptionBadge, 
    Icon, SimpleBadge,
    Input, Modal,
    SimpleButton, 
    SimpleCheckbox,
    SimpleSelect,
    TodoComponent,
    CardPage,
    CardPageTitle,
    CardPageSubTitle,
    ModalTitle,
    ModalSubTitle,
    InputWrapper,
    InputLabel,
    BadgeWrapper,
    ModalContainer,
    FixedModalFooter,
    InputWrapperGroup,
    LocalisationAutoComplete,
} from "@components";
import { useScreenSize } from "@utilities/hooks";
import { EnumContratType, IconNameEnum } from "@utilities/enums";
import { enumToStringArray, getMonth, getMonthName, getYear } from "@utilities/functions";
import { CV, ProfessionalExperience } from "@utilities/types";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    talentCV: CV | null
    onSaveTalentCVRequest: (cv: CV)=> void
}

const descriptionPlceholder = `ex : 	•	Développement d’une application e-commerce :
•	Conception UX/UI.
•	Intégration paiement sécurisé.
•	Gestion des stocks en temps réel.
•	Optimisation mobile.
`;

export const Experiences:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
    talentCV,
    onSaveTalentCVRequest,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [title, setTitle] = useState<string>('');
    const [organisationName, setOrganisationName] = useState<string>('');
    const [localisation, setLocalisation] = useState<string>('');
    const [fullTeleWork, setFullTeleWork] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [stillInOffice, setStillInOffice] = useState<boolean>(false);
    const [description, setDescription] = useState<string>('');
    const [contratType, setContratType] = useState<string>('');
    const [skill, setSkill] = useState<string>('');
    const [skills, setSkills] = useState<string[]>([]);

    const contratTypeList = useMemo(()=> {
        let result:{label: string, value: string}[] = [];
        let tmp = enumToStringArray(EnumContratType);
        tmp.map(el=> {
            result.push({
                label: el,
                value: el,
            })
        });

        return result;
    }, []);

    const handleAddSkill = useCallback(()=> {
        setSkills(e => {
            if (!e.includes(skill)) {
                return [...e, skill];
            }
            return e;
        });
        setSkill('');
    }, [skill]);

    const handleRemoveSkill = useCallback((competenceEL: string)=> {
        setSkills(e => e.filter(element=> element !== competenceEL));
    }, []);

    const handleClose = useCallback(()=> {
        setTitle('');
        setOrganisationName('');
        setLocalisation('');
        setFullTeleWork(false);
        setStartDate('');
        setEndDate('');
        setStillInOffice(false);
        setDescription('');
        setContratType('');
        setSkills([]);
        setActiveIndex(null);
        setIsVisible(false);
    }, []);

    const handleEditExp = useCallback((exp: ProfessionalExperience, index: number)=> {
        const startDateTmp = `${exp.startYear}-${exp.startMonth <= 9 ? 0 : ''}${exp.startMonth}`;
        const endDateTmp = exp.isStillInPost ? '' : `${exp.endYear}-${exp.endMonth! <= 9 ? 0 : ''}${exp.endMonth}`;

        setTitle(exp.name || '');
        setOrganisationName(exp.organization || '');
        setLocalisation(exp.place || '');
        setFullTeleWork(exp.fullTeleWork || false);
        setStartDate(startDateTmp);
        setEndDate(endDateTmp);
        setStillInOffice(exp.isStillInPost);
        setDescription(exp.description || '');
        setContratType(exp.contratType || '');
        setSkills(exp.skills || []);
        setActiveIndex(index);
        setIsVisible(true);
    }, []);

    const handleAddExperience = useCallback(()=> {
        if(
            talentCV && title && organisationName && localisation && (fullTeleWork === true || fullTeleWork === false)
            && startDate && description && contratType && (endDate || stillInOffice === true)
        ){
            const startMonth = getMonth(new Date(startDate));
            const startYears = getYear(new Date(startDate));

            const endMonth = stillInOffice ? null : getMonth(new Date(endDate));
            const endYear = stillInOffice ? null : getYear(new Date(endDate));

            let professionalExperiences:ProfessionalExperience[] = [...(talentCV.professionalExperiences || [])];
            const newExp:ProfessionalExperience = {
                fullTeleWork,
                isStillInPost: stillInOffice,
                startMonth: startMonth,
                startYear: startYears,
                endMonth: endMonth,
                endYear: endYear,
                name: title,
                description: description,
                skills: skills || [],
                contratType: contratType as EnumContratType,
                organization: organisationName,
                place: localisation,
                // sizeOfCompany: string
                // activityArea: string
            };

            professionalExperiences.push(newExp);

            onSaveTalentCVRequest({
                ...talentCV,
                professionalExperiences,
            })
            handleClose();
        }
    }, [
        endDate, fullTeleWork,
        talentCV, title, organisationName, 
        localisation, startDate, description, 
        contratType, stillInOffice, skills,
        onSaveTalentCVRequest, handleClose
    ]);

    const handleUpdatedProfExp = useCallback(()=> {
        const expsTemp = [...talentCV?.professionalExperiences || []];
        
        const updatedExps = expsTemp.map((el, i) => {
            if (
                i === activeIndex &&
                title && organisationName && localisation &&
                (fullTeleWork === true || fullTeleWork === false) &&
                startDate && description && contratType &&
                (endDate || stillInOffice === true)
            ) {
                const startMonth = getMonth(new Date(startDate));
                const startYear = getYear(new Date(startDate));

                const endMonth = stillInOffice ? null : getMonth(new Date(endDate));
                const endYear = stillInOffice ? null : getYear(new Date(endDate));
    
                return {
                    ...el,
                    fullTeleWork,
                    isStillInPost: stillInOffice,
                    startMonth: startMonth,
                    startYear: startYear,
                    endMonth: endMonth,
                    endYear: endYear,
                    name: title,
                    description: description,
                    skills: skills || [],
                    contratType: contratType as EnumContratType,
                    organization: organisationName,
                    place: localisation,
                };
            }

            return el;
        });

        onSaveTalentCVRequest({
            ...talentCV!,
            professionalExperiences: updatedExps,
        });
        handleClose();
    }, [
        activeIndex, contratType, description, 
        endDate, fullTeleWork, localisation, 
        organisationName, skills, startDate, 
        stillInOffice, talentCV, title,
        handleClose, onSaveTalentCVRequest,
    ]);

    const handleDeleteExp = useCallback((index: number)=> {
        const expsTemp = [...talentCV?.professionalExperiences || []];

        const updatedExps = expsTemp.filter((el, i)=> i !== index);
        onSaveTalentCVRequest({
            ...talentCV!,
            professionalExperiences: updatedExps,
        });
    }, [talentCV, onSaveTalentCVRequest]);

    const handleUpdatedLocalisation = useCallback((selectedLocalisation: string)=> {
        setLocalisation(selectedLocalisation);
    }, []);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Expériences 
                </CardPageTitle>
                <CardPageSubTitle>
                    Parlez-nous de vos expériences passées et actuelles, de vos projets.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={simpleButtonStyle}
                >
                    Ajouter
                </SimpleButton>

                {
                    talentCV?.professionalExperiences && talentCV.professionalExperiences.length > 0 && (
                        <ProfileSectionBody>
                            {
                                talentCV.professionalExperiences.map((exp, i)=> {
                                    return (
                                        <ProfileSectionHead
                                            key={i}
                                            style={{paddingBottom: 30, marginTop: 30, gap: 0}}
                                        >
                                            <FloatButtonGroup>
                                                <IconWrapper onClick={()=> handleEditExp(exp, i)}>
                                                    <Icon 
                                                        iconName={IconNameEnum.edit}
                                                        size='xs'
                                                    />
                                                </IconWrapper>

                                                <IconWrapper 
                                                    onClick={()=> handleDeleteExp(i)}
                                                    style={{
                                                        backgroundColor: '#B00000',
                                                        borderColor: '#B00000',
                                                    }}
                                                >
                                                    <Icon 
                                                        iconName={IconNameEnum.trash}
                                                        size='xs'
                                                        color="#fff"
                                                    />
                                                </IconWrapper>
                                            </FloatButtonGroup>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Intitulé de poste
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        {exp.name}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Entreprise ou client
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        {exp.organization}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Localisation
                                                </InfoLabel>

                                                <Location style={{marginTop: 10}}>
                                                    📍 {exp.place} {exp.fullTeleWork && `(Télétravail total)`}
                                                </Location>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Type de contrat
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        {exp.contratType}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Date
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        📅  De {`${getMonthName(exp.startMonth).toLowerCase()} ${exp.startYear}`} à {exp.isStillInPost ? "aujourd'hui" : `${getMonthName(exp.endMonth!).toLowerCase()} ${exp.endYear}`}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Description, missions
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <DescriptionBadge>
                                                        {exp.description}
                                                    </DescriptionBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            {
                                                exp?.skills.length > 0 && (
                                                    <InfosWrapper>
                                                        <InfoLabel>
                                                            Compétences
                                                        </InfoLabel>
                                                        <BadgeWrapper>
                                                            {
                                                                exp.skills.map((el, j)=> (
                                                                    <SimpleBadge key={j} color="#fff" bgColor="#0C1421" >
                                                                        {el}
                                                                    </SimpleBadge>
                                                                ))
                                                            }
                                                        </BadgeWrapper>
                                                    </InfosWrapper>
                                                )
                                            }
                                        </ProfileSectionHead>
                                    )
                                })
                            }
                        </ProfileSectionBody>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Expériences
                        </ModalTitle>
                        <ModalSubTitle>
                            Parlez-nous de vos expériences passées et actuelles, de vos projets.
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                ✨ Titre, rôle,ou nom du projet 
                            </InputLabel>
                            <Input
                                placeholder="ex : Product Manager"
                                value={title}
                                onChange={setTitle}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🏢 Entreprise ou client
                            </InputLabel>
                            <Input 
                                placeholder="ex : Apple"
                                value={organisationName}
                                onChange={setOrganisationName}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                📍 Localisation 
                            </InputLabel>
                            <LocalisationAutoComplete
                                placeholder="Ville, région ou pays"
                                onSelectedLocalisation={handleUpdatedLocalisation}
                            />
                            {
                                localisation && (
                                    <Location>
                                        📍 {localisation}
                                    </Location>
                                )
                            }
                        </InputWrapper>

                        <SimpleCheckbox 
                            label="Télétravail total"
                            checked={fullTeleWork}
                            setChecked={()=> setFullTeleWork(!fullTeleWork)}
                            styles={{marginTop: 10}}
                        />

                        <InputWrapper>
                            <InputLabel>
                                💼 Type de contrat
                            </InputLabel>
                            <SimpleSelect 
                                placeholder="Sélectionnez"
                                options={contratTypeList}
                                value={contratType}
                                onChange={setContratType}
                            />
                        </InputWrapper>

                        <InputWrapperGroup>
                            <InputWrapper>
                                <InputLabel>
                                    📅 Du*
                                </InputLabel>
                                <Input 
                                    isMonth
                                    inputType='date'
                                    placeholder="MM/AA"
                                    value={startDate}
                                    onChange={setStartDate}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <InputLabel>
                                    📅 Au*
                                </InputLabel>
                                <Input 
                                    isMonth
                                    inputType='date'
                                    placeholder="MM/AA"
                                    value={endDate}
                                    onChange={setEndDate}
                                    disabled={stillInOffice}
                                />
                            </InputWrapper>
                        </InputWrapperGroup>

                        <SimpleCheckbox 
                            label="J’y travaille toujours"
                            checked={stillInOffice}
                            setChecked={()=> setStillInOffice(!stillInOffice)}
                            styles={{marginTop: 10}}
                        />

                        <InputWrapper>
                            <InputLabel>
                                🕊️ Description, missions
                            </InputLabel>
                            <Input 
                                inputType='textarea'
                                placeholder={descriptionPlceholder}
                                value={description}
                                onChange={setDescription}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                💪 Compétences acquises
                            </InputLabel>
                            <TodoComponent
                                placeholder="ex : Figma"
                                value={skill}
                                onChange={setSkill}
                                onAdd={handleAddSkill}
                            />
                            <BadgeWrapper>
                                {
                                    skills.map((el, i)=> {
                                        return (
                                            <BadgeWithIcon
                                                key={i}
                                                onIconPress={()=> handleRemoveSkill(el)}
                                            >
                                                {el}
                                            </BadgeWithIcon>
                                        )
                                    })
                                }
                            </BadgeWrapper>
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={typeof activeIndex === "number" ? handleUpdatedProfExp : handleAddExperience}>
                            {typeof activeIndex === "number" ? 'Enregistrer' : 'Ajouter'}
                        </SimpleButton>

                        <SimpleButton onClick={handleClose} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}