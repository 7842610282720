// FR: Définit une fonction pour formater une date dans un format lisible, en utilisant les paramètres de localisation.
// EN: Defines a function to format a date into a readable format, using locale settings.
export const formatDate = (
    date: any, 
    locale: string = 'default' // Utilisation de 'fr-FR' pour formater la date en français
): string => {

    date = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };

    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const formatDateInput = (
    date: any
): string => {

    date = new Date(date);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const getMonthName = (monthNumber: number): string => {
    const months = [
        "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
        "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];

    if (monthNumber < 1 || monthNumber > 12) {
        throw new Error("Le nombre doit être compris entre 1 et 12.");
    }

    return months[monthNumber - 1];
}

// FR: Définit une fonction pour formater l'heure dans un format lisible, en utilisant les paramètres de localisation.
// EN: Defines a function to format time into a readable format, using locale settings.
export const formatTime = (
    date: any, 
    locale: string = 'default'
): string => {

    date = new Date(date);

    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
        hour12: false,
    };

    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getMonth = (date: Date)=> {
    const formatteur = new Intl.DateTimeFormat('fr', { month: 'numeric' });
    return Number(formatteur.format(date));
}

export const getYear = (date: Date)=> {
    const formatteur = new Intl.DateTimeFormat('fr', { year: 'numeric' });
    return Number(formatteur.format(date));
}  

export const differenceInDays = (date1: Date, date2: any): number => {
    const oneDay = 1000 * 60 * 60 * 24; // Nombre de millisecondes dans un jour
    const diffInTime = Math.abs(date1.getTime() - date2._seconds * 1000); // Différence en millisecondes
    return Math.ceil(diffInTime / oneDay); // Conversion en jours
};

export const timeSince = (date: any): string => {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - (date._seconds * 1000)) / 1000);

    const intervals = [
        { label: 'an', seconds: 60 * 60 * 24 * 365 },   // 1 année
        { label: 'mois', seconds: 60 * 60 * 24 * 30 },   // 1 mois
        { label: 'jour', seconds: 60 * 60 * 24 },        // 1 jour
        { label: 'heure', seconds: 60 * 60 },            // 1 heure
        { label: 'minute', seconds: 60 }                 // 1 minute
    ];

    for (const interval of intervals) {
        const timePassed = Math.floor(seconds / interval.seconds);
        if (timePassed >= 1) {
            return `${timePassed} ${interval.label}${timePassed > 1 ? 's' : ''}`;
        }
    }

    return 'quelques secondes'; // Si le temps écoulé est inférieur à une minute
};
