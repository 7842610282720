import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { 
    FetchRequestType, 
    FetchResponseType, 
    Organisation, 
    SearchTalentType, 
    SignInRecruiterActionPayloadRequest,
    TEST_QCM,
    UserRecruiter, 
} from "@utilities/types";

export const fetchSigninRecruiterRequest = async(
    payload: SignInRecruiterActionPayloadRequest
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.LOGIN_USER_RECRUITER,
        method: 'POST',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetOrganisationRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ORGANISATION}?organisationID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchCreateOrganisationRequest = async(
    payload: Organisation
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_ORGANISATION,
        method: 'POST',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedOrganisationRequest = async(
    payload: Organisation
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_ORGANISATION,
        method: 'PUT',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSignupRecruiterRequest = async(
    payload: UserRecruiter
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_USER_RECRUITER,
        method: 'POST',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetAllTalentCVsRequest = async():Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.GET_ALL_TALENT_CV,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchOnSearchTalentCvsRequest = async(
    payload: SearchTalentType
):Promise<FetchResponseType>=> {

    const searchCriterias = {
        minSalaryMonth: Number(payload.minSalary),
        contratType: payload.contratType,
        workTime: [payload.workTime],
        // partialTeleworking,
        // teleworking,
        // noTeleworking,
    }

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.FIND_TALENTS_BY_CV}?strSearch=${payload.searchStr || ''}&skills=${JSON.stringify(payload.competences)}&languages=[]&localisation=${payload.localisation || ''}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetPostQcmRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig:FetchRequestType = {
        route: `${EnumPaths.GET_ORGANISATION_POST_QCM}?qcmID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetOrganisationTestListRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig:FetchRequestType = {
        route: `${EnumPaths.GET_ORGANISATION_QCM_LIST}?organisationID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSaveOrganisationTestRequest = async(
    payload: {organisationQcm: TEST_QCM, postID?: string}
):Promise<FetchResponseType>=> {

    const requestConfig:FetchRequestType = {
        route: EnumPaths.SAVE_ORGANISATION_QCM,
        method: 'POST',
        data: payload
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGenerateTestByIARequest = async(
    payload: {jobOfferID: string, testLevel:string}
):Promise<FetchResponseType>=> {

    const requestConfig:FetchRequestType = {
        route: EnumPaths.GENERATE_ORGANISATION_QCM,
        method: 'POST',
        data: {jobOfferID: payload.jobOfferID, qcmLevel: payload.testLevel}
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};