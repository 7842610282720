import { FC, useCallback } from "react";
import { Countdown, ModalContainer } from "@components";
import { DisclaimerComponent, QuestionListComponent, QuizzComponent } from "./components";
import { ModalStepsEnum } from "./interfaces";
import { useControllers } from "./useController";
import { TEST_QCM } from "@utilities/types";

interface Props {
    qcmElement: TEST_QCM | null
    onValidateQCM: (el: TEST_QCM)=> void
}

export const TestTools:FC<Props> = ({
    qcmElement,
    onValidateQCM
})=> {

    const {
        modalSteps,
        isRespondedAllQuestion,
        startCountdown,
        selectedQCM,
        currentQuestion,
        currentQuestionIndex,
        handleValidateQcm,
        handleNextQuestion,
        handleSelectedResponse,
        setCountdownValue,
        setModalSteps,
        setCurrentQuestion,
        setCurrentQuestionIndex,
        setStartCountdown,
    } = useControllers(qcmElement, onValidateQCM);

    const renderContent = useCallback(()=> {
        const countDown = ()=> (
            <Countdown
                minutes={selectedQCM?.time || 10}
                isStart={startCountdown}
                handleCountdownChange={setCountdownValue}
            />
        );

        switch (modalSteps) {
            case ModalStepsEnum.DISCLAIMER:
                return (
                    <DisclaimerComponent
                        selectedQCM={selectedQCM}
                        setModalSteps={setModalSteps}
                        setCurrentQuestion={setCurrentQuestion}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setStartCountdown={setStartCountdown}
                    />
                );

            case ModalStepsEnum.QUIZZ:
                const responses = currentQuestion ? [
                    currentQuestion.reponse_a,
                    currentQuestion.reponse_b,
                    currentQuestion.reponse_c,
                    currentQuestion.reponse_d,
                ] : [];

                return (
                    <QuizzComponent
                        responses={responses}
                        currentQuestion={currentQuestion}
                        currentQuestionIndex={currentQuestionIndex}
                        isRespondedAllQuestion={isRespondedAllQuestion}
                        countDown={countDown}
                        handleSelectedResponse={handleSelectedResponse}
                        handleNextQuestion={handleNextQuestion}
                        handleValidateQcm={handleValidateQcm}
                        setModalSteps={setModalSteps}
                    />
                );
            
            case ModalStepsEnum.QUESTION_LIST:
                return (
                    <QuestionListComponent
                        selectedQCM={selectedQCM}
                        countDown={countDown}
                        setCurrentQuestion={setCurrentQuestion}
                        setCurrentQuestionIndex={setCurrentQuestionIndex}
                        setModalSteps={setModalSteps}
                    />
                )
        
            default:
                return (
                    <ModalContainer>
        
                    </ModalContainer>
                );
        }
    }, [
        modalSteps, startCountdown, 
        selectedQCM, currentQuestion,
        currentQuestionIndex, isRespondedAllQuestion,
        handleNextQuestion, setCountdownValue, 
        handleValidateQcm, setModalSteps, 
        setCurrentQuestion, setCurrentQuestionIndex, 
        setStartCountdown, handleSelectedResponse,
    ]);

    return renderContent();
}