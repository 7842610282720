import { FC } from "react";
import { FiltersGroup, LeftSectionContainer } from "../styles";
import { InfoLabel, InfosWrapper, SimpleSelect } from "@components";
import { TalentListComponent } from "./talentList";
import { matchingType } from "@utilities/types";

interface Props {
    activeMatching: matchingType[]
    activeIndex: number
    setActiveIndex: (e: number)=> void
    setSeletedTalent: (e: matchingType | null)=> void
}

export const LeftSection:FC<Props> = ({
    activeMatching, 
    activeIndex, 
    setActiveIndex, 
    setSeletedTalent,
})=> {

    return (
        <LeftSectionContainer>
            <FiltersGroup>
                <InfosWrapper style={{flex: 1}}>
                    <InfoLabel>
                        📍 Localisation
                    </InfoLabel>
                    <SimpleSelect 
                        placeholder="Tous"
                        value=""
                        onChange={()=> {}}
                        options={[]}
                    />
                </InfosWrapper>

                <InfosWrapper style={{flex: 1}}>
                    <InfoLabel>
                        👋 Temporalité
                    </InfoLabel>
                    <SimpleSelect 
                        placeholder="Plus récent"
                        value=""
                        onChange={()=> {}}
                        options={[]}
                    />
                </InfosWrapper>
            </FiltersGroup>

            <TalentListComponent
                activeMatching={activeMatching} 
                activeIndex={activeIndex} 
                setActiveIndex={setActiveIndex} 
                setSeletedTalent={setSeletedTalent}
            />
        </LeftSectionContainer>
    )
}