import { CSSProperties, FC } from "react";
import { NavStepBadge, NavStepItem, NavStepItemTxt, NavStepsContainer } from "../styles";
import { ApplicationStatusEnum, FontFamilyEnum } from "@utilities/enums";
import { navStepType } from "../useControllers";

interface Props {
    navsSteps: navStepType[]
    activeSection: ApplicationStatusEnum
    handleNavStepSelected: (navStep: navStepType) => void
}

export const NavSteps:FC<Props> = ({
    navsSteps, 
    activeSection,
    handleNavStepSelected,
})=> {
    return (
        <NavStepsContainer>
            {
                navsSteps.map((el, i)=> {

                    const isActiveStyles:CSSProperties = {
                        textDecoration: 'underline',
                        textUnderlineOffset: 5,
                        fontFamily: FontFamilyEnum.PlusJakartSans_Bold
                    };

                    return (
                        <NavStepItem
                            key={i}
                            onClick={()=> handleNavStepSelected(el)}
                        >
                            <NavStepItemTxt  
                                style={activeSection === el.label ? isActiveStyles : {}}
                            >
                                {el.label}
                            </NavStepItemTxt>

                            <NavStepBadge>{el.count}</NavStepBadge>
                        </NavStepItem>
                    )
                })
            }
        </NavStepsContainer>
    )
}