import { FC, useCallback, useMemo } from "react";
import { useAnalyticsPageTracking, useNavigation, useTranslation } from "@utilities/hooks";
import { Modal, SimpleButton } from "@components";
import { NavigationPathsEnum } from "@utilities/enums";
import { useControlers } from "./useControlers";
import { Section, SubTitle, Title } from "./styles";

export const TalentAccountValidation:FC = ()=> {

    useAnalyticsPageTracking('TalentAccountValidation');
    const translate = useTranslation();
    const { navigateTo } = useNavigation();
    const { talent, onSendNewMail } = useControlers();

    const pageTxts = useMemo(()=> ({
        title: (talent && talent.confirmedMail) ? 'Adresse email validée ! 👏' : 'Lien de confirmation invalide 🤔',
        subTitle: (talent && talent.confirmedMail) ? 'Vous pouvez vous identifier avec votre nouvelle adresse email.' : 'Le lien de confirmation sur lequel vous avez cliqué a expiré.',
        buttonLabel: (talent && talent.confirmedMail) ? 'Découvrir Ohasis' : 'Envoyer un nouveau lien de confirmation',
    }), [talent]);

    const handleGoToHome = useCallback(()=> {
        navigateTo(NavigationPathsEnum.HOME);
    }, [navigateTo]);

    const handlePress = useCallback(()=> {
        if((talent && talent.confirmedMail)){
            navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
        } else {
            onSendNewMail();
        }
    }, [talent, navigateTo, onSendNewMail]);

    return (
        <>
            <Modal
                isVisible
                onClose={handleGoToHome}
            >
                <Section>
                    <Title>
                        {pageTxts.title}
                    </Title>
                    <SubTitle>
                        {pageTxts.subTitle}
                    </SubTitle>

                    <SimpleButton onClick={handlePress}>
                        {pageTxts.buttonLabel}
                    </SimpleButton>
                </Section>
            </Modal>
        </>
    )
}
