import { useCallback, useEffect, useMemo, useState } from "react";
import { TEST_QCM, qcmTestType } from "@utilities/types";
import { ModalStepsEnum } from "@connectedComponents";

export const useControllers = (
    qcmElement: TEST_QCM | null, 
    onValidateQCM: (e: TEST_QCM)=> void,
)=> {

    const [modalSteps, setModalSteps] = useState<ModalStepsEnum>(ModalStepsEnum.DISCLAIMER);
    const [selectedQCM, setSelectedQCM] = useState<TEST_QCM | null>(qcmElement);
    const [currentQuestion, setCurrentQuestion] = useState<qcmTestType | null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [startCountdown, setStartCountdown] = useState<boolean>(false);
    const [countdownValue, setCountdownValue] = useState<number>(-1);

    const isRespondedAllQuestion = useMemo(()=> {
        let status = true;
        selectedQCM?.qcm.map(el=> {
            if(!el.selected_response){
                status = false;
            }
        });

        return status;
    }, [selectedQCM]);

    const handleSelectedResponse = useCallback((response: string) => {
        if (!currentQuestion) return;
    
        setSelectedQCM((prevQCM) => {
            if (!prevQCM) return prevQCM;
    
            return {
                ...prevQCM,
                qcm: prevQCM.qcm.map((el) => {
                    if (el.question === currentQuestion?.question) {
                        return {
                            ...el,
                            selected_response: response,
                        };
                    }
                    return el;
                }),
            };
        });
    
        setCurrentQuestion({
            ...currentQuestion,
            selected_response: response,
        });
    }, [currentQuestion]);

    const handleNextQuestion = useCallback(()=> {
        if(currentQuestionIndex === selectedQCM?.qcm.length) return;

        const questionIndex = currentQuestionIndex! + 1;
        setCurrentQuestionIndex(questionIndex);
        setCurrentQuestion(selectedQCM?.qcm[questionIndex - 1]!);
    }, [currentQuestionIndex, selectedQCM]);

    const handleValidateQcm = useCallback(()=> {
        if(selectedQCM){
            console.log('============SEND==>');
            onValidateQCM(selectedQCM);
            
            setCountdownValue(-1);
            setCurrentQuestionIndex(0);
            setStartCountdown(false);
            setSelectedQCM(null);
            setCurrentQuestion(null);
            setModalSteps(ModalStepsEnum.DISCLAIMER);
        }
    }, [onValidateQCM, selectedQCM]);
    
    // useEffect(()=> {
    //     return ()=> {
    //         if(startCountdown){
    //             handleValidateQcm();
    //         }
    //     }
    // }, [startCountdown]);

    useEffect(()=> {
        if(countdownValue === 0 && startCountdown){
            handleValidateQcm();
        }
    }, [countdownValue, startCountdown]);

    useEffect(() => {
        if(startCountdown){
            window.addEventListener('beforeunload', handleValidateQcm);
        }
    
        return () => {
          if(startCountdown){
            window.removeEventListener('beforeunload', handleValidateQcm);
          }
        };
    }, [startCountdown]);

    return {
        modalSteps,
        isRespondedAllQuestion,
        startCountdown,
        selectedQCM,
        currentQuestion,
        currentQuestionIndex,
        handleValidateQcm,
        handleNextQuestion,
        handleSelectedResponse,
        setCountdownValue,
        setModalSteps,
        setCurrentQuestion,
        setCurrentQuestionIndex,
        setStartCountdown,
    }
}