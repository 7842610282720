import { 
    BadgeWithIcon, BadgeWrapper, 
    CardPage, CardPageTitle, FixedModalFooter, 
    ImagePicker, InfoLabel, InfosWrapper, 
    Input, InputLabel, InputWrapper, 
    Modal, ModalContainer, ModalSubTitle, 
    ModalTitle, SimpleBadge, 
    SimpleButton, SimpleSelect,
} from "@components";
import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { Logo } from "../styles";
import { Organisation } from "@utilities/types";

interface Props {
    organisation: Organisation | null
    simpleButtonStyles: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    onUpdatedOrganisation: (organisation: Organisation)=> void
}

export const OrganisationInfos:FC<Props> = ({
    organisation,
    simpleButtonStyles,
    simpleButtonOutlineStyle,
    onUpdatedOrganisation,
})=> {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [organisationName, setOrganisationName] = useState<string>(
        organisation?.organisationName || ''
    );
    const [organisationCreateYear, setOrganisationCreateYear] = useState<string>(
        organisation?.yearOfcreation 
        && String(organisation?.yearOfcreation) || ''
    );
    const [organisationCA, setOrganisationCA] = useState<string>(
        organisation?.caForTheLastFullYear 
        && String(organisation?.caForTheLastFullYear[0]) || ''
    );
    const [organisationCollabNumber, setOrganisationCollabNumber] = useState<string>(
        organisation?.numberOfemployees 
        && String(organisation.numberOfemployees) || ''
    );
    const [organisationMailName, setOrganisationMailName] = useState<string>(
        organisation?.senderNameForAutomaticEmails || ''
    );
    const [langue, setLangue] = useState<string>('');
    const [languages, setLanguages] = useState<string[]>(
        organisation?.languageInWhichYouExpressYourself 
        && organisation.languageInWhichYouExpressYourself || []
    );

    const languagesOption = useMemo(()=> [
        {
            label: '🇫🇷 Français',
            value: '🇫🇷 Français',
        },
        {
            label: '🇺🇸 Anglais',
            value: '🇺🇸 Anglais',
        },
    ], []);

    const toggleIsOpen = useCallback(()=> {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleAddLanguages = useCallback((language: string)=> {
        setLanguages(e => {
            if (!e.includes(language)) {
                return [...e, language];
            }
            return e;
        });
        setLangue('');
    }, []);

    const handleRemoveLanguage = useCallback((language: string)=> {
        setLanguages(e => e.filter(lang=> lang !== language));
    }, []);

    const handleSave = useCallback(()=> {
        if(organisationName && organisation){
            onUpdatedOrganisation({
                ...organisation,
                organisationName,
                ...(organisationCreateYear ? { yearOfcreation: Number(organisationCreateYear) } : {}),
                ...(organisationCA ? { caForTheLastFullYear: [Number(organisationCA)] } : {}),
                ...(organisationCollabNumber ? { numberOfemployees: Number(organisationCollabNumber) } : {}),
                ...(organisationMailName ? { senderNameForAutomaticEmails: organisationMailName } : {}),
                ...(languages ? { languageInWhichYouExpressYourself: languages } : {}),
            });

            toggleIsOpen();
        }
    }, [
        organisation, organisationName, 
        organisationCreateYear, 
        organisationCA, organisationCollabNumber, 
        organisationMailName, languages, 
        onUpdatedOrganisation,
        toggleIsOpen,
    ]);

    return (
        <>
            <CardPage>
                <CardPageTitle>Entreprise</CardPageTitle>

                <SimpleButton style={simpleButtonStyles} onClick={toggleIsOpen}>
                    Modifier
                </SimpleButton>

                <InfosWrapper>
                    <Logo src="https://seeklogo.com/images/O/oasis-logo-D635CBE789-seeklogo.com.png" />

                    <InfoLabel>
                        Nom de l'entreprise
                    </InfoLabel>

                    <SimpleBadge>{organisation?.organisationName}</SimpleBadge>
                </InfosWrapper>

                {
                    organisation?.yearOfcreation && (
                        <InfosWrapper>
                            <InfoLabel>
                                Année de création
                            </InfoLabel>

                            <SimpleBadge>{organisation.yearOfcreation}</SimpleBadge>
                        </InfosWrapper>
                    )
                }
                
                {
                    organisation?.caForTheLastFullYear && (
                        <InfosWrapper>
                            <InfoLabel>
                                CA de la dernière année complète
                            </InfoLabel>

                            <SimpleBadge>{organisation?.caForTheLastFullYear} €</SimpleBadge>
                        </InfosWrapper>
                    )
                }

                {
                    organisation?.numberOfemployees && (
                        <InfosWrapper>
                            <InfoLabel>
                                Nb de collaborateurs
                            </InfoLabel>

                            <SimpleBadge>{organisation?.numberOfemployees}</SimpleBadge>
                        </InfosWrapper>
                    )
                }

                {
                    organisation?.senderNameForAutomaticEmails && (
                        <InfosWrapper>
                            <InfoLabel>
                                Nom de l’expéditeur pour les mails automatique
                            </InfoLabel>

                            <SimpleBadge>{organisation?.senderNameForAutomaticEmails}</SimpleBadge>
                        </InfosWrapper>
                    )
                }

                {
                    organisation?.languageInWhichYouExpressYourself && 
                    organisation.languageInWhichYouExpressYourself.length > 0 && (
                        <InfosWrapper>
                            <InfoLabel>
                                Langue dans laquelle vous vous exprimez
                            </InfoLabel>

                            <BadgeWrapper>
                                {
                                    organisation.languageInWhichYouExpressYourself.map((el, i)=> (
                                        <SimpleBadge key={i}>{el}</SimpleBadge>
                                    ))
                                }
                            </BadgeWrapper>
                        </InfosWrapper>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isOpen}
                onClose={toggleIsOpen}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Entreprise
                        </ModalTitle>

                        <ModalSubTitle>
                            Modification des informations de l'entrepise
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                📷 Logo de l'entreprise
                            </InputLabel>
                            <ImagePicker
                                defaultUri={''}
                                onImageChange={(e: File | null)=> {}}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Nom de l'entreprise*
                            </InputLabel>
                            <Input
                                placeholder="Nom de l'entreprise"
                                value={organisationName}
                                onChange={setOrganisationName}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Année de création
                            </InputLabel>
                            <Input
                                placeholder="ex: 2020"
                                value={organisationCreateYear}
                                onChange={setOrganisationCreateYear}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                CA de la dernière année complète
                            </InputLabel>
                            <Input
                                placeholder="ex: 100000"
                                value={organisationCA}
                                onChange={setOrganisationCA}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Nb de collaborateurs
                            </InputLabel>
                            <Input
                                placeholder="ex: 5"
                                value={organisationCollabNumber}
                                onChange={setOrganisationCollabNumber}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Nom de l’expéditeur pour les mails automatique
                            </InputLabel>
                            <Input
                                placeholder="ex: Ohasis"
                                value={organisationMailName}
                                onChange={setOrganisationMailName}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🗣️ Langue dans laquelle vous vous exprimez
                            </InputLabel>
                            <SimpleSelect
                                placeholder="Sélectionnez une langue"
                                options={languagesOption}
                                value={langue}
                                onChange={e=> handleAddLanguages(e)}
                            />

                            <BadgeWrapper>
                                {
                                    languages.map((el, i)=> {
                                        return (
                                            <BadgeWithIcon
                                                key={i}
                                                onIconPress={()=> handleRemoveLanguage(el)}
                                            >
                                                {el}
                                            </BadgeWithIcon>
                                        )
                                    })
                                }
                            </BadgeWrapper>
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={toggleIsOpen} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}