import { NavigationPathsEnum } from "@utilities/enums";
import { useNavigation, useTalentDispatch } from "@utilities/hooks";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useControlers = ()=> {

    const { navigateTo } = useNavigation();
    const { 
        onVerifyForgotPasswordOTP, 
        onChangePassword,
        onTalentLogout,
    } = useTalentDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const otp = queryParams.get('otp');

    const [index, setIndex] = useState<number>(0);
    const [token, setToken] = useState<string>('');

    const handleGoToHome = useCallback(()=> {
        navigateTo(NavigationPathsEnum.HOME);
    }, [navigateTo]);

    const handleVerifyForgotPasswordOTP = useCallback(async()=> {
        if(otp){
            const result = await onVerifyForgotPasswordOTP(otp);
            if(result !== null){
                setToken(result);
            } else {
                handleGoToHome();
            }
        }
    }, [otp, onVerifyForgotPasswordOTP, handleGoToHome]);

    const handleChangePassword = useCallback(async(newPassword: string)=> {
        if(token){
            const result = await onChangePassword(newPassword, token);
            if(typeof result === 'string'){
                onTalentLogout();
                setIndex(1);
            }
        }
    }, [token, onChangePassword, onTalentLogout]);

    useEffect(()=> {
        if(!otp){
            handleGoToHome();
        } else {
            handleVerifyForgotPasswordOTP()
        }
    }, [otp, handleGoToHome, handleVerifyForgotPasswordOTP]);

    return {
        index,
        handleGoToHome,
        handleChangePassword
    }
}
