import { CSSProperties, FC } from "react";
import { ModalContainer, ModalTxt, QuestionListWrapper, RoundedQuestionNumber } from "../styles";
import { ModalStepsEnum } from "../interfaces";
import { qcmTestType, TEST_QCM } from "@utilities/types";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    selectedQCM: TEST_QCM | null
    countDown: ()=> JSX.Element
    setCurrentQuestion: (e: qcmTestType | null)=> void
    setCurrentQuestionIndex: (e: number)=> void
    setModalSteps: (e: ModalStepsEnum)=> void
}

export const QuestionListComponent:FC<Props> = ({
    selectedQCM,
    countDown,
    setCurrentQuestion,
    setCurrentQuestionIndex,
    setModalSteps,
})=> {

    const { isMobile } = useScreenSize();

    const modalContainerStyles:CSSProperties = {
        ...(isMobile ? {} : {paddingLeft: '10%'}),
        ...(isMobile ? {} : {paddingRight: '10%'})
    };

    const selectedRoundedQuestionNumber:CSSProperties = {
        border: 'solid 2px #6F6D69',
        backgroundColor: "#EFECE6",
        color: '#6F6D69',
    };

    return (
        <ModalContainer style={modalContainerStyles}>
            {countDown()}

            <ModalTxt>
                Choississez l’ordre dans lequel vous souhaitez répondre aux questions :
            </ModalTxt>

            <QuestionListWrapper>
                {
                    selectedQCM?.qcm.map((el, i)=> {
                        const isResponded = el.selected_response;
                        return (
                            <RoundedQuestionNumber 
                                key={i}
                                style={isResponded ? selectedRoundedQuestionNumber : {}}
                                onClick={()=> {
                                    setCurrentQuestion(selectedQCM?.qcm[i]!);
                                    setCurrentQuestionIndex(i + 1);
                                    setModalSteps(ModalStepsEnum.QUIZZ);
                                }}
                            >
                                {i + 1}
                            </RoundedQuestionNumber>
                        )
                    })
                }
            </QuestionListWrapper>
        </ModalContainer>
    )
}