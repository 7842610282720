import { 
    AnimatedGradianButton,
    ButtonGroup,
    Icon,
    InfoLabel,
    InfosWrapper,
    Modal, ModalContainer, ModalSubTitle, 
    ModalTitle,
    SimpleButton,
    SimpleSelect,
} from "@components";
import { IconNameEnum } from "@utilities/enums";
import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { ModalButtonGroups, Noticecontainer, NoticeTitle, TextNotice } from "../styles";
import { enumToObjectsArray } from "@utilities/functions";
import { EnumUserLevel, qcmTestType } from "@utilities/types";
import { TestGeneratorModal } from "@connectedComponents";

interface Props {
    generatedTestTmp: any
    displayTestGeneratedForJobOfferModal: boolean
    testGeneratedForJobOfferModalIndex: number
    setTestGeneratedForJobOfferModalIndex: (e: number)=> void
    onToggleDisplayTestGeneratedForJobOfferModal: ()=> void
    onGeneratedTest: (e: string)=> void
    handleSaveJobTest: (
        testTitle: string,
        testLevel: string,
        selectedGoodResponseNumber: number,
        qcm: qcmTestType[]
    )=> void
}

export const TestGeneratedForJobOfferModal:FC<Props> = ({
    generatedTestTmp,
    displayTestGeneratedForJobOfferModal,
    testGeneratedForJobOfferModalIndex,
    setTestGeneratedForJobOfferModalIndex,
    onToggleDisplayTestGeneratedForJobOfferModal,
    onGeneratedTest, handleSaveJobTest,
})=> {

    const [isGeneratedTestbyIA, setIsGeneratedTestbyIA] = useState<boolean>(false);
    const [selectedTestLevel, setSelectedTestLevel] = useState<string>('');

    const testLevelOptions = useMemo(()=> enumToObjectsArray(EnumUserLevel), []);

    const modalBodyStyles:CSSProperties = useMemo(()=> (
        {
            height: '100vh'
        }
    ), []);

    const simpleButtonStyles:CSSProperties = useMemo(()=> (
        {
            justifyContent: 'space-between',
            borderRadius: 10,
        }
    ), []);

    const notices = useMemo(()=> (
        [
            "Ce test sera directement lié à l’offre d’emploi que vous publiez. Assurez-vous qu’il évalue les compétences essentielles pour le poste.",
            "Optez pour des questions qui reflètent les compétences clés du poste pour un meilleur filtrage des candidats. Variez les types de questions pour une évaluation plus complète des candidats.",
            "Vous pouvez labeliser le niveau de difficulté des questions pour correspondre au poste : débutant, intermédiaire ou expert.",
            "Notre IA peut générer automatiquement un test basé sur la description de votre offre. Gagnez du temps tout en gardant une précision optimale.",
            "Vous recevrez des statistiques détaillées sur les performances des candidats, incluant leur score, leur temps de réponse et les compétences validées.",
            "Les tests créés peuvent être réutilisés pour d’autres offres d’emploi similaires.",
        ]
    ), []);

    const onToggeGenerateTestByIa = useCallback(()=> {
        setIsGeneratedTestbyIA(!isGeneratedTestbyIA);
    }, [isGeneratedTestbyIA]);

    const handleCancel = useCallback(()=> {
        setSelectedTestLevel('');
        onToggeGenerateTestByIa();
    }, [onToggeGenerateTestByIa]);

    const handleGeneratedTest = useCallback(()=> {
        if(selectedTestLevel){
            onGeneratedTest(selectedTestLevel);
        }
    }, [selectedTestLevel, onGeneratedTest]);

    const renderTestGeneratedForJobOfferModal = useCallback(()=> (
        <Modal
            isVisible={displayTestGeneratedForJobOfferModal}
            onClose={onToggleDisplayTestGeneratedForJobOfferModal}
        >
            <>
                <ModalContainer style={modalBodyStyles}>
                    <ModalTitle>Créez un test de compétence</ModalTitle>
                    <ModalSubTitle>Les candidats devront réussir ce test avec au moins 15 bonnes réponses sur 20 pour postuler à votre offre.</ModalSubTitle>

                    <ModalButtonGroups>
                        {
                            isGeneratedTestbyIA ? (
                                <>
                                    <InfosWrapper>
                                        <InfoLabel>
                                            ✨ Niveau de test
                                        </InfoLabel>
                                        <SimpleSelect 
                                            placeholder="Sélectionnez le niveau du test"
                                            value={selectedTestLevel}
                                            onChange={setSelectedTestLevel}
                                            options={testLevelOptions}
                                        />
                                    </InfosWrapper>

                                    <ButtonGroup>
                                        <SimpleButton onClick={handleCancel}>
                                            Annuler
                                        </SimpleButton>

                                        <AnimatedGradianButton onClick={handleGeneratedTest}>
                                            Générer le test
                                        </AnimatedGradianButton>
                                    </ButtonGroup>
                                </>
                            ) : (
                                <>
                                    <AnimatedGradianButton 
                                        style={simpleButtonStyles}
                                        onClick={onToggeGenerateTestByIa}
                                    >
                                        Créer un test généré par IA basé sur votre offre 
                                        <Icon iconName={IconNameEnum.arrowRight} color="#fff" />
                                    </AnimatedGradianButton>

                                    <SimpleButton 
                                        onClick={()=> setTestGeneratedForJobOfferModalIndex(1)}
                                        style={simpleButtonStyles}
                                    >
                                        Créer un test personnalisé
                                        <Icon iconName={IconNameEnum.arrowRight} color="#fff" />
                                    </SimpleButton>
                                </>
                            )
                        }
                    </ModalButtonGroups>

                    <Noticecontainer>
                        <NoticeTitle>
                            <Icon iconName={IconNameEnum.info} color="#AC6900" size='lg' /> Notice d’utilisation
                        </NoticeTitle>

                        {
                            notices.map((el, i)=> (
                                <TextNotice key={i}>
                                    {i + 1}. {el}
                                </TextNotice>
                            ))
                        }
                    </Noticecontainer>
                </ModalContainer>
            </>
        </Modal>
    ), [
        modalBodyStyles, notices, simpleButtonStyles,
        displayTestGeneratedForJobOfferModal, 
        isGeneratedTestbyIA, testLevelOptions, selectedTestLevel,
        onToggleDisplayTestGeneratedForJobOfferModal,
        onToggeGenerateTestByIa, handleCancel, handleGeneratedTest,
        setTestGeneratedForJobOfferModalIndex,
    ]);

    const renderContent = useCallback(()=> {
        switch (testGeneratedForJobOfferModalIndex) {
            case 0:
                return renderTestGeneratedForJobOfferModal();
            case 1:
                return (
                    <TestGeneratorModal
                        isVisible
                        generatedTestTmp={generatedTestTmp}
                        isDisableSelectedTest={!!generatedTestTmp}
                        onClose={()=> setTestGeneratedForJobOfferModalIndex(0)}
                        onSaveTest={handleSaveJobTest}
                    />
                );
        
            default:
                return renderTestGeneratedForJobOfferModal();
        }
    }, [
        generatedTestTmp,
        testGeneratedForJobOfferModalIndex,
        renderTestGeneratedForJobOfferModal,
        setTestGeneratedForJobOfferModalIndex,
        handleSaveJobTest,
    ]);

    return renderContent();
}