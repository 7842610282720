import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const TimePicker = styled.input.attrs({ type: 'time' })`
    height: 38px;
    width: 100px;
    font-size: 12px;
    outline: none;
    color: #101214;
    border: solid 1px lightgrey;
    border-radius: 8px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px){
        height: 46px;
        font-size: 15px;
        border-radius: 12px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;