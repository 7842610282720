import { CSSProperties, FC, useCallback, useEffect, useMemo, useState } from "react"
import { 
    Divider,
    FixedModalFooter, InfoLabel, InfosWrapper, Input, Modal, 
    ModalContainer, ModalSubTitle,
    ModalTitle,
    SimpleButton,
    SimpleSelect,
} from "@components"
import { EnumUserLevel, qcmTestType } from "@utilities/types"
import styled from "styled-components"
import { FontFamilyEnum } from "@utilities/enums"
import { enumToObjectsArray } from "@utilities/functions"

interface Props {
    isVisible: boolean
    generatedTestTmp?: any
    isDisableSelectedTest?: boolean
    onClose: ()=> void
    onSaveTest: (
        testTitle: string,
        testLevel: string,
        selectedGoodResponseNumber: number,
        qcm: qcmTestType[]
    )=> void
}

export const QuestionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
`;

export const OuTxt = styled.p`
    margin: 0%;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 16px;
    color: #101214;
    text-align: center;
    font-family: ${FontFamilyEnum.PlusJakartSans_Bold};
`;

export const TestGeneratorModal:FC<Props> = ({
    isVisible,
    generatedTestTmp,
    onClose,
    onSaveTest,
    isDisableSelectedTest = false,
})=> {

    const [testLevel, setTestLevel] = useState<string>('');
    const [selectedGoodResponseNumber, setSelectedGoodResponseNumber] = useState<string>(''); 
    const [testTitle, setTestTitle] = useState<string>(''); 
    const [qcm, setQcm] = useState<qcmTestType[]>([]); 

    const testLevelOptions = useMemo(()=> enumToObjectsArray(EnumUserLevel), []);

    const goodResponseOptions = useMemo(()=> (
        Array.from({ length: 21 }, (_, i) => ({
            label: `${i.toString()}`,
            value: i.toString()
        }))
    ), []);

    const outlineButtonStyle:CSSProperties = useMemo(()=> ({
        backgroundColor: '#fff',
        color: '#101214',
        border: 'solid 1px #101214',
    }), []);

    const goodResponseStyle:CSSProperties = useMemo(()=> ({
        border: 'solid 1px #6CA54A',
        backgroundColor: '#EAFFDD',
    }), []);

    const emojis = useMemo(()=> [
        '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣', '1️⃣0️⃣',
        '1️⃣1️⃣', '1️⃣2️⃣', '1️⃣3️⃣', '1️⃣4️⃣', '1️⃣5️⃣', '1️⃣6️⃣', '1️⃣7️⃣', '1️⃣8️⃣', '1️⃣9️⃣', '2️⃣0️⃣',
    ], []);

    const initQcm = useCallback(() => {
        const qcmEl = {
            question: '',
            reponse_a: '',
            reponse_b: '',
            reponse_c: '',
            reponse_d: '',
            solution: '',
            explication: '',
        };
    
        setQcm(Array.from({ length: 20 }, () => ({ ...qcmEl })));
    }, []);

    const handleUpdateField = useCallback((index: number, field: string, value: string) => {
        setQcm((prevQcm) =>
            prevQcm.map((el, i) => {
                if (i === index) {
                    return { ...el, [field]: value };
                }
                return el;
            })
        );
    }, []);

    const handleSaveQCM = useCallback(()=> {
        let isQcmIsCompleted = true;

        for (let index = 0; index < qcm.length; index++) {
            const element = qcm[index];
            
            if(!element.solution){
                isQcmIsCompleted = false;
            }
        }

        if(testLevel && testTitle && selectedGoodResponseNumber && isQcmIsCompleted){
            onSaveTest(
                testTitle,
                testLevel,
                Number(selectedGoodResponseNumber),
                qcm,
            );
        }
    }, [
        selectedGoodResponseNumber, 
        testLevel, testTitle, qcm,
        onSaveTest,
    ]);

    useEffect(()=> {
        if(generatedTestTmp){
            setQcm(generatedTestTmp.qcm);
            setTestLevel(generatedTestTmp.level);
            setTestTitle(generatedTestTmp.title);
        } else {
            initQcm();
        }
    }, [generatedTestTmp]);

    return (
        <Modal
            isVisible={isVisible}
            onClose={onClose}
        >
            <>
                <ModalContainer>
                    <ModalTitle>
                        Créez un test de compétence
                    </ModalTitle>

                    <ModalSubTitle>
                        Les candidats devront le remplir et obtenir un minimum de 15 bonnes réponses sur 20 pour pouvoir postuler à votre offre.
                    </ModalSubTitle>

                    {
                        !isDisableSelectedTest && (
                            <>
                                <InfosWrapper>
                                    <InfoLabel>
                                        ✨ Séléctionner un test déjà existant
                                    </InfoLabel>
                                    <SimpleSelect 
                                        placeholder="Séléctionner un test déjà existant"
                                        value=""
                                        onChange={()=> {}}
                                        options={[]}
                                    />
                                </InfosWrapper>

                                <OuTxt>
                                    OU
                                </OuTxt>
                            </>
                        )
                    }

                    <InfosWrapper>
                        <InfoLabel>
                            ✨ Titre du test de compétence
                        </InfoLabel>
                        <Input 
                            placeholder="Titre du test de compétence"
                            value={testTitle}
                            onChange={setTestTitle}
                        />
                    </InfosWrapper>

                    <InfosWrapper>
                        <InfoLabel>
                            ✨ Niveau de test
                        </InfoLabel>
                        <SimpleSelect 
                            placeholder="Sélectionnez le niveau du test"
                            value={testLevel}
                            onChange={setTestLevel}
                            options={testLevelOptions}
                            disabled={!!generatedTestTmp}
                        />
                    </InfosWrapper>

                    <InfosWrapper>
                        <InfoLabel>
                            ✨ Nombre minimum de bonnes réponses nécessaires
                        </InfoLabel>
                        <SimpleSelect 
                            placeholder="Sélectionnez le nombre"
                            value={selectedGoodResponseNumber}
                            onChange={setSelectedGoodResponseNumber}
                            options={goodResponseOptions}
                        />
                    </InfosWrapper>

                    <Divider width={70} style={{marginTop: 80}} />

                    <QuestionContainer>
                        {
                            qcm.map((el, i)=> (
                                <InfosWrapper key={i}>
                                    <InfoLabel>
                                        {emojis[i]} Question / Réponse
                                    </InfoLabel>
                                    <Input 
                                        disabled={!!generatedTestTmp}
                                        placeholder="Tapez votre question ici"
                                        value={el.question}
                                        onChange={(e)=> handleUpdateField(i, 'question', e)}
                                    />
                                    <Input 
                                        disabled={!!generatedTestTmp}
                                        placeholder="Réponse a"
                                        value={el.reponse_a}
                                        onChange={(e)=> handleUpdateField(i, 'reponse_a', e)}
                                        styles={el.reponse_a === el.solution ? goodResponseStyle : {}}
                                    />
                                    <Input
                                        disabled={!!generatedTestTmp} 
                                        placeholder="Réponse b"
                                        value={el.reponse_b}
                                        onChange={(e)=> handleUpdateField(i, 'reponse_b', e)}
                                        styles={el.reponse_b === el.solution ? goodResponseStyle : {}}
                                    />
                                    <Input
                                        disabled={!!generatedTestTmp} 
                                        placeholder="Réponse c"
                                        value={el.reponse_c}
                                        onChange={(e)=> handleUpdateField(i, 'reponse_c', e)}
                                        styles={el.reponse_c === el.solution ? goodResponseStyle : {}}
                                    />
                                    <Input
                                        disabled={!!generatedTestTmp} 
                                        placeholder="Réponse d"
                                        value={el.reponse_d}
                                        onChange={(e)=> handleUpdateField(i, 'reponse_d', e)}
                                        styles={el.reponse_d === el.solution ? goodResponseStyle : {}}
                                    />
                                    <Input
                                        disabled={!!generatedTestTmp} 
                                        inputType='textarea'
                                        placeholder="Explication"
                                        value={el.explication!}
                                        onChange={(e)=> handleUpdateField(i, 'explication', e)}
                                    />
                                </InfosWrapper>
                            ))
                        }
                    </QuestionContainer>
                </ModalContainer>

                <FixedModalFooter>
                    <SimpleButton onClick={handleSaveQCM}>
                        Publier
                    </SimpleButton>

                    <SimpleButton style={outlineButtonStyle}>
                        Retour
                    </SimpleButton>
                </FixedModalFooter>
            </>
        </Modal>
    )
}