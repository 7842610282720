import { CardPage, CardPageTitle, Icon, InfosWrapper, Input, SimpleButton } from "@components";
import { CSSProperties, FC } from "react";
import {DeletedText, DeletedTextContainer, Form } from "../../../styles";
import { IconNameEnum } from "@utilities/enums";

interface Props {
    cardPageStyles: CSSProperties
    faq: {question: string, response: string}[]
    addFaq: ()=> void
    handleUpdatedFaqQuestion: (str: string, index: number)=> void
    handleUpdatedFaqResponse: (str: string, index: number)=> void
    deletedFaq: (index: number)=> void
}

export const JobOfferFAQ:FC<Props> = ({
    cardPageStyles,
    faq,
    addFaq,
    handleUpdatedFaqQuestion,
    handleUpdatedFaqResponse,
    deletedFaq,
})=> {

    return (
        <CardPage style={cardPageStyles}>
            <CardPageTitle>Ajouter une FAQ</CardPageTitle>

            <Form>
                <InfosWrapper style={{alignItems: 'center'}}>
                    <SimpleButton onClick={addFaq}>
                        + Ajouter un question/réponse
                    </SimpleButton>
                </InfosWrapper>

                {
                    faq.map((el, i)=> (
                        <InfosWrapper key={i}>
                            <Input 
                                placeholder="Question"
                                value={el.question}
                                onChange={e=> handleUpdatedFaqQuestion(e, i)}
                            />
                            <Input 
                                placeholder="Réponse"
                                value={el.response}
                                onChange={e=> handleUpdatedFaqResponse(e, i)}
                            />

                            <DeletedTextContainer>
                                <Icon onPress={()=> deletedFaq(i)} iconName={IconNameEnum.close} color="#f44343" />
                                <DeletedText onClick={()=> deletedFaq(i)}>
                                    Supprimer cet élément
                                </DeletedText>
                            </DeletedTextContainer>
                        </InfosWrapper>
                    ))
                }
            </Form>
        </CardPage>
    )
}