import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { 
    Appointment,
    FetchRequestType, 
    FetchResponseType,
} from "@utilities/types";

export const fetchGetTalentAppointmentRequest = async(
    payload: string
):Promise<FetchResponseType>=> {
    try {
        const requestConfig: FetchRequestType = {
            route: `${EnumPaths.GET_ALL_TALENT_APPOINTMENTS}?userToken=${payload}`,
            method: 'GET',
        };

        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetRecruiterAppointmentRequest = async(
    payload: string
):Promise<FetchResponseType>=> {
    try {
        const requestConfig: FetchRequestType = {
            route: `${EnumPaths.GET_ALL_ORGANISATION_APPOINTMENTS}?recruiterToken=${payload}`,
            method: 'GET',
        };

        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchCreateNewAppointmentRequest = async(
    payload: {appointment: Appointment, recruiterToken: string}
):Promise<FetchResponseType>=> {
    try {
        const requestConfig: FetchRequestType = {
            route: EnumPaths.CREATE_APPOINTMENT,
            method: 'POST',
            data: {appointmentData: payload.appointment, recruiterToken: payload.recruiterToken}
        };

        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchTalentSelectedAppointmentRequest = async(
    payload: {appointmentID: string, talentToken: string}
):Promise<FetchResponseType>=> {
    try {
        const requestConfig: FetchRequestType = {
            route: EnumPaths.TALENT_SELECTED_APPOINTMENT,
            method: 'PUT',
            data: payload
        };

        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedAppointmentRequest = async(
    payload: Appointment
):Promise<FetchResponseType>=> {
    try {
        const requestConfig: FetchRequestType = {
            route: EnumPaths.UPDATED_APPOINTMENT,
            method: 'PUT',
            data: payload
        };

        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchDeletedAppointmentRequest = async(
    payload: string
):Promise<FetchResponseType>=> {
    try {
        const requestConfig: FetchRequestType = {
            route: EnumPaths.DELETE_APPOINTMENT,
            method: 'DELETE',
            data: {appointmentID: payload}
        };

        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

