import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { SimpleBadge } from "./simpleBadge";
import { AnimatedGradianButton } from "./animatedGradianButton";
import { SimpleButton } from "./simpleButton";
import { Icon } from "./icon";
import { useScreenSize } from "@utilities/hooks";
import { CV, UserTalent } from "@utilities/types";

import ProfilImg from '@assets/images/defaultImg.png';
import { formatTextMaxChars } from "@utilities/functions";

type talentCVType = { cv: CV, talent: UserTalent };

interface Props {
    isHighlighted?: boolean
    talentCV: talentCVType
    onPress: (talentCV: talentCVType)=> void
}

const Card = styled.div<{isHighlighted?: boolean}>`
    width: 100%;
    position: relative;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    background: ${props=> props.isHighlighted ? 'rgb(253,186,102)' : '#fff'};
    background: ${props=> props.isHighlighted ? 'linear-gradient(90deg, rgba(253,186,102,1) 0%, rgba(253,109,113,1) 100%)' : '#fff'};
    
    border: solid 1px #ECECEC;
    -webkit-box-shadow: 0px 0px 15px -3px #ECECEC; 
    box-shadow: 0px 0px 15px -3px #ECECEC;

    @media (min-width: 760px) {
        padding: 30px;
        padding-left: 35px;
        padding-right: 35px;
    }
`;

const CardPreview = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const LogoAndBody = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
const OrganisationImg = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;

    @media (min-width: 760px) {
        width: 90px;
        height: 90px;
    }
`;

const CardPreviewBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const CardTitle = styled.h3`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 13px;

    @media (min-width: 760px) {
        font-size: 22px;
    }
`;
const OrganisationName = styled.h4`
    margin: 0%;
    margin-top: 5px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    font-size: 12px;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;
const CardPreviewBodyBadges = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;

    @media (min-width: 760px) {
        width: 50%;
        gap: 7px;
    }
`;

const Days = styled.p<{isHighlighted: boolean}>`
    margin: 0%;
    font-size: 13px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    color: ${props=> props.isHighlighted ? '#F9F9F9' : '#101214'};

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

const Description = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 1px #ECECEC;
    border-bottom: solid 1px #ECECEC;
`;

const DescriptionSection = styled.div`
    margin-bottom: 20px;
`;

const DetailTitle = styled.h4`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 13px;
    color: #101214;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

const DetailDescription = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    font-size: 13px;
    color: #101214;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

const ActionsButtons = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    @media (min-width: 760px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;

    @media (min-width: 760px) {
        margin-bottom: 30px;
    }
`;

const IconWrapper = styled.div<{isHighlighted: boolean}>`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: ${props=> props.isHighlighted ? '#DB4343' : '#101214'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (min-width: 760px) {
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
`;

const HighlightedBadge = styled.label`
    position: absolute;
    top: -9px;
    font-size: 8px;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 20px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    color: #fff;
    background: rgb(253,186,102);
    background: linear-gradient(90deg, rgba(253,186,102,1) 0%, rgba(253,109,113,1) 100%);
    -webkit-box-shadow: 0px 0px 13px -5px #fff; 
    box-shadow: 0px 0px 13px -5px #fff;

    @media (min-width: 760px) {
        top: -15px;
        font-size: 14px;
        padding: 5px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 40px;
    }
`;

export const TalentCard:FC<Props> = ({
    isHighlighted = false,
    talentCV,
    onPress,
})=> {

    const { isMobile } = useScreenSize();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const buttonStyles:CSSProperties = useMemo(()=> (
        {
            backgroundColor: "#F7F7F7",
            color: "#101214",
        }
    ), []);

    const badgeCustomStyle:CSSProperties = useMemo(()=> {
        if(!isHighlighted) return {};

        return {
            backgroundColor: "#F9F9F9",
        }
    }, [isHighlighted]);

    const animatedButtonCustomStyle:CSSProperties = useMemo(()=> {
        if(!isMobile) return {};

        return {
            borderRadius: 8,
        }
    }, [isMobile]);

    // const handleTogleShowDetails = useCallback(()=> {
    //     setShowDetails(!showDetails);
    // }, [showDetails]);

    const renderBadges = useCallback(()=> (
        <CardPreviewBodyBadges>
            {
                talentCV.cv.criteria?.jobTypes && talentCV.cv.criteria.jobTypes.map((el, i)=> {
                    return (
                        <SimpleBadge style={badgeCustomStyle} key={i} >
                            {el}
                        </SimpleBadge>
                    )
                })
            }
            {
                talentCV.cv.criteria?.contratTypes && talentCV.cv.criteria.contratTypes.map((el, i)=> {
                    return (
                        <SimpleBadge style={badgeCustomStyle} key={i} >
                            {el}
                        </SimpleBadge>
                    )
                })
            }
            {
                talentCV.cv.criteria?.teleWoring && talentCV.cv.criteria.teleWoring.map((el, i)=> {
                    return (
                        <SimpleBadge style={badgeCustomStyle} key={i} >
                            {el}
                        </SimpleBadge>
                    )
                })
            }
            {
                talentCV.cv.criteria?.level && (
                    <SimpleBadge style={badgeCustomStyle}>{talentCV.cv.criteria.level}</SimpleBadge>
                )
            }
        </CardPreviewBodyBadges>
    ), [badgeCustomStyle, talentCV]);

    return (
        <Card isHighlighted={isHighlighted}>
            {
                isHighlighted && (
                    <HighlightedBadge>
                        Mis en avant 🔥
                    </HighlightedBadge>
                )
            }

            <CardPreview 
                // onClick={handleTogleShowDetails}
            >
                <LogoAndBody>
                    <OrganisationImg 
                        src={ProfilImg}
                        alt="organisation logo"
                    />

                    <CardPreviewBody>
                        <CardTitle>{talentCV.cv.title && formatTextMaxChars(talentCV.cv.title, 25)}</CardTitle>
                        <OrganisationName>{talentCV.talent.firstname} {talentCV.talent.lastname}</OrganisationName>

                        {
                            isMobile ? (
                                <CardPreviewBodyBadges>
                                    <SimpleBadge style={badgeCustomStyle}>📍 {talentCV.cv.criteria.localisation && formatTextMaxChars(talentCV.cv.criteria.localisation, 20)}</SimpleBadge>
                                    {/* <SimpleBadge style={badgeCustomStyle}>💰 $60k-$100k</SimpleBadge> */}
                                </CardPreviewBodyBadges>
                            ) : (
                                <SimpleBadge style={{...badgeCustomStyle, marginTop: 5}}>📍 {talentCV.cv?.criteria?.localisation && formatTextMaxChars(talentCV.cv.criteria.localisation, 20)}</SimpleBadge>
                            )
                        }
                    </CardPreviewBody>
                </LogoAndBody>

                {!isMobile && renderBadges()}

                {/* <Days isHighlighted={isHighlighted}>5j</Days> */}

                <SimpleButton onClick={()=> onPress(talentCV)}>
                    Voir le CV
                </SimpleButton>
                {/* <IconWrapper 
                    isHighlighted={isHighlighted} 
                    // onClick={handleTogleShowDetails}
                    onClick={()=> onPress(talentCV)}
                >
                    <Icon 
                        size={isMobile ? "1x" : "2x"}
                        color="#fff"
                        iconName={showDetails ? IconNameEnum.minus : IconNameEnum.plus}
                    />
                </IconWrapper> */}
            </CardPreview>
            {
                showDetails && (
                    <>
                        {isMobile && renderBadges()}

                        <Description>
                            <DescriptionSection>
                                <DetailTitle>Descriptif du poste</DetailTitle>
                                <DetailDescription>
                                    As a freak UX/UI designer you will dedicate your time to find nice and friendly ways to fulfill the wants and needs of our users.
                                </DetailDescription>
                            </DescriptionSection>

                            <DescriptionSection>
                                <DetailTitle>Profil recherché</DetailTitle>
                                <DetailDescription>
                                    Work with stakeholders to identify requirements
                                    Research customers, competitors, and products
                                    Develop personas, scenarios, and user stories
                                    Create wireframes, prototypes, and high-fidelity mock-ups
                                    Work with developers to ensure product quality
                                    Work closely with product owners to maximize customer success
                                </DetailDescription>
                            </DescriptionSection>

                            <DescriptionSection>
                                <DetailTitle>Déroulement des entretiens</DetailTitle>
                                <DetailDescription>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis. Ut commodo efficitur neque. Ut diam quam, semper iaculis condimentum ac, vestibulum eu nisl.
                                </DetailDescription>
                            </DescriptionSection>
                        </Description>

                        <ActionsButtons>
                            <ButtonGroup>
                                <SimpleButton style={buttonStyles}>
                                    <Icon 
                                        iconName={IconNameEnum.warning}
                                        size='lg'
                                    />
                                    En savoir plus
                                </SimpleButton>

                                <SimpleButton style={buttonStyles}>
                                    <Icon 
                                        iconName={IconNameEnum.share}
                                        size='lg'
                                    />
                                    Partager
                                </SimpleButton>

                                <SimpleButton style={buttonStyles}>
                                    <Icon 
                                        iconName={IconNameEnum.save}
                                        size='lg'
                                    />
                                    Sauvegarder
                                </SimpleButton>
                            </ButtonGroup>

                            <AnimatedGradianButton style={animatedButtonCustomStyle}>
                                Postuler
                            </AnimatedGradianButton>
                        </ActionsButtons>
                    </>
                )
            }
        </Card>
    )
}