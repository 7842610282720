import { 
    AnimatedGradianButton,
    FixedModalFooter, JobOfferCard, 
    Modal, ModalContainer, ModalSubTitle, 
    ModalTitle,
    SimpleButton,
} from "@components";
import { useScreenSize } from "@utilities/hooks";
import { CSSProperties, FC, useMemo } from "react";
import { ButtonsGroup } from "../styles";
import { Post } from "@utilities/types";

interface Props {
    displayJobOfferPreview: boolean
    job: Post | null
    onAddTest: ()=> void
    onEdit: ()=> void
    onToggleDisplayJobOfferPreview: ()=> void
}

export const JobOfferPreviewModal:FC<Props> = ({
    displayJobOfferPreview,
    job,
    onAddTest,
    onEdit,
    onToggleDisplayJobOfferPreview,
})=> {

    const { isMobile } = useScreenSize();

    const modalContainerStyles:CSSProperties = useMemo(()=> (
        {
            width: isMobile ? '100%' : '85%',
        }
    ), [isMobile]);

    const modalBodyStyles:CSSProperties = useMemo(()=> (
        {
            ...(isMobile ? {height: '75vh'} : {}),
        }
    ), [isMobile]);

    const modalFooterStyles:CSSProperties = useMemo(()=> (
        {
            justifyContent: isMobile ? 'flex-start' : 'space-between',
            ...(isMobile ? {height: '25vh'} : {}),
        }
    ), [isMobile]);

    const editButtonStyles:CSSProperties = useMemo(()=> (
        {
            flex: 1,
            backgroundColor: '#fff',
            border: 'solid 2px #101214',
            color: '#101214',
        }
    ), []);

    return (
        <Modal
            isVisible={displayJobOfferPreview}
            modalContainerStyles={modalContainerStyles}
            onClose={onToggleDisplayJobOfferPreview}
        >
            <>
                <ModalContainer style={modalBodyStyles}>
                    <ModalTitle>Votre offre d’emploi</ModalTitle>
                    <ModalSubTitle>Un aperçu complet de votre offre avant sa publication.</ModalSubTitle>

                    {
                        job && <JobOfferCard isOpen isOnlyReadMode job={job} />
                    }
                </ModalContainer>

                <FixedModalFooter style={modalFooterStyles}>
                    <ButtonsGroup>
                        <SimpleButton style={{flex: 1}}>
                            Publier
                        </SimpleButton>

                        <SimpleButton style={editButtonStyles} onClick={onEdit}>
                            Modifier
                        </SimpleButton>
                    </ButtonsGroup>

                    {
                        !job?._qcmID && (
                            <AnimatedGradianButton onClick={onAddTest} style={{borderRadius: 10}}>
                                + Ajouter un test
                            </AnimatedGradianButton>
                        )
                    }
                </FixedModalFooter>
            </>
        </Modal>
    )
}