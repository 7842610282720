import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { CSSProperties, FC, useCallback, useState } from "react";
import styled from "styled-components";
import { Icon } from "./icon";

interface Props {
    placeholder: string
    value: string
    inputType?: keyof inputType
    leftTextIcon?: string
    noRadius?: boolean
    disabled?: boolean
    isMonth?: boolean
    styles?: CSSProperties
    onChange: (e: string)=> void
    onClearInput?: ()=> void
}

interface inputType {
    text: 'text'
    number: 'number'
    textarea: 'textarea'
    password: 'password'
    search: 'search'
    date: 'date'
}

const InputWrapper = styled.div<{noRadius: boolean, disabled?: boolean}>`
    width: 100%;
    min-height: 40px;
    border-radius: ${props=> props.noRadius ? '0px' : '8px'};
    border: solid 1px #F5F4F2;
    background-color: ${props=> props.disabled ? '#eeeeee' : '#ffffff'};
    display: flex;
    align-items: center;

    @media (min-width: 760px){
        min-height: 48px;
        border-radius: ${props=> props.noRadius ? '0px' : '12px'};
    }
`;
const InputElement = styled.input`
    flex: 1;
    height: 38px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #101214;
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    &::placeholder {
        color: #b4b4b4;
    }

    @media (min-width: 760px){
        height: 46px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

const InputTextareaElement = styled.textarea`
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    color: #101214;
    font-size: 12px;
    padding: 10px 14px 10px 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    &::placeholder {
        color: #b4b4b4;
    }

    @media (min-width: 760px){
        font-size: 15px;
        padding: 15px 20px 15px 20px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

const IconWrapper = styled.div`
    margin-left: 10px;
    margin-right: 10px;
`

const SearchIcon = styled.label`
    font-size: 15px;
    margin: 0%;
    margin-left: 14px;
`;

export const Input:FC<Props> = ({ 
    placeholder,
    value,
    inputType='text',
    isMonth = false,
    leftTextIcon,
    noRadius = false,
    disabled = false,
    styles,
    onChange,
    onClearInput,
 })=> {

    const [isVisible, setIsvisible] = useState<boolean>(false);

    const renderInput = useCallback(()=> {
        switch (inputType) {
            case 'text':
                return (
                    <InputElement 
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        onChange={e=> onChange(e.target.value)}
                    />
                );
            case 'password':
                return (
                    <InputElement 
                        disabled={disabled}
                        type={isVisible ? 'text' : 'password'}
                        placeholder={placeholder}
                        value={value}
                        onChange={e=> onChange(e.target.value)}
                    />
                );
            case 'search':
                return (
                    <InputElement 
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        onChange={e=> onChange(e.target.value)}
                    />
                );
            case 'textarea':
                return (
                    <InputTextareaElement 
                        disabled={disabled}
                        rows={10}
                        placeholder={placeholder}
                        value={value}
                        onChange={e=> onChange(e.target.value)}
                    />
                );
            case 'date':
                return (
                    <InputElement 
                        disabled={disabled}
                        type={isMonth ? 'month' : 'Date'}
                        placeholder={placeholder}
                        value={value}
                        onChange={e=> onChange(e.target.value)}
                    />
                );
        
            default:
                return (
                    <InputElement 
                        disabled={disabled}
                        placeholder={placeholder}
                        value={value}
                        onChange={e=> onChange(e.target.value)}
                    />
                );
        }
    }, [inputType, isVisible, onChange, placeholder, value, disabled, isMonth])

    return (
        <InputWrapper noRadius={noRadius} disabled={disabled} style={styles}>
            {
                inputType === 'search' && (
                    <SearchIcon>🔍</SearchIcon>
                )
            }
            {
                leftTextIcon && (
                    <SearchIcon>{leftTextIcon}</SearchIcon>
                )
            }
            {renderInput()}
            {
                inputType === 'password' && (
                    <IconWrapper>
                        <Icon 
                            iconName={isVisible ? IconNameEnum.eyeSlash : IconNameEnum.eye}
                            onPress={()=> setIsvisible(!isVisible)}
                        />
                    </IconWrapper>
                )
            }
            {
                (inputType === 'search' && value) && (
                    <IconWrapper>
                        <Icon 
                            iconName={IconNameEnum.close}
                            onPress={onClearInput}
                        />
                    </IconWrapper>
                )
            }
        </InputWrapper>
    )
}