import { FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { SimpleBadge } from "./simpleBadge";
import { AnimatedGradianButton } from "./animatedGradianButton";
import { SimpleButton } from "./simpleButton";
import { Icon } from "./icon";
import { useScreenSize } from "@utilities/hooks";
import { Post } from "@utilities/types";
import { differenceInDays, formatDate } from "@utilities/functions";

interface Props {
    isHighlighted?: boolean
    isOpen?: boolean
    isOnlyReadMode?: boolean
    job: Post
    onSendCandidat?: (post: Post)=> void
}

export const Card = styled.div<{isHighlighted?: boolean}>`
    flex: 1;
    position: relative;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 8px;
    background: ${props=> props.isHighlighted ? 'rgb(253,186,102)' : '#fff'};
    background: ${props=> props.isHighlighted ? 'linear-gradient(90deg, rgba(253,186,102,1) 0%, rgba(253,109,113,1) 100%)' : '#fff'};
    
    border: solid 1px #ECECEC;
    -webkit-box-shadow: 0px 0px 15px -3px #ECECEC; 
    box-shadow: 0px 0px 15px -3px #ECECEC;

    @media (min-width: 760px) {
        padding: 30px;
        padding-left: 35px;
        padding-right: 35px;
    }
`;

export const CardPreview = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const LogoAndBody = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;
export const OrganisationImg = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;

    @media (min-width: 760px) {
        width: 90px;
        height: 90px;
    }
`;

export const CardPreviewBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
export const CardTitle = styled.h3`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 13px;

    @media (min-width: 760px) {
        font-size: 22px;
    }
`;
export const OrganisationName = styled.h4`
    margin: 0%;
    margin-top: 5px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    font-size: 12px;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;
export const CardPreviewBodyBadges = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 5px;

    @media (min-width: 760px) {
        width: 50%;
        gap: 7px;
    }
`;

export const Days = styled.p<{isHighlighted: boolean}>`
    margin: 0%;
    font-size: 13px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    color: ${props=> props.isHighlighted ? '#F9F9F9' : '#101214'};

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const Description = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    border-top: solid 1px #ECECEC;
`;

export const DescriptionSection = styled.div`
    margin-bottom: 20px;
`;

export const DetailTitle = styled.h4`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 13px;
    color: #101214;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const DetailDescription = styled.p`
    margin: 0%;
    margin-top: 10px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Regular};
    font-size: 13px;
    color: #101214;

    @media (min-width: 760px) {
        font-size: 18px;
    }
`;

export const ActionsButtons = styled.div`
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    border-top: solid 1px #ECECEC;

    @media (min-width: 760px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;

    @media (min-width: 760px) {
        margin-bottom: 30px;
    }
`;

export const IconWrapper = styled.div<{isHighlighted?: boolean}>`
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: ${props=> props.isHighlighted ? '#DB4343' : '#101214'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media (min-width: 760px) {
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
`;

export const HighlightedBadge = styled.label`
    position: absolute;
    top: -9px;
    font-size: 8px;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 20px;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};
    color: #fff;
    background: rgb(253,186,102);
    background: linear-gradient(90deg, rgba(253,186,102,1) 0%, rgba(253,109,113,1) 100%);
    -webkit-box-shadow: 0px 0px 13px -5px #fff; 
    box-shadow: 0px 0px 13px -5px #fff;

    @media (min-width: 760px) {
        top: -15px;
        font-size: 14px;
        padding: 5px;
        padding-left: 18px;
        padding-right: 18px;
        border-radius: 40px;
    }
`;

export const JobOfferCard:FC<Props> = ({
    isHighlighted = false,
    isOpen = false,
    isOnlyReadMode = false,
    job,
    onSendCandidat,
})=> {

    const { isMobile } = useScreenSize();
    const [showDetails, setShowDetails] = useState<boolean>(isOpen);

    const buttonStyles:CSSProperties = useMemo(()=> (
        {
            backgroundColor: "#F7F7F7",
            color: "#101214",
        }
    ), []);

    const badgeCustomStyle:CSSProperties = useMemo(()=> {
        if(!isHighlighted) return {};

        return {
            backgroundColor: "#F9F9F9",
        }
    }, [isHighlighted]);

    const animatedButtonCustomStyle:CSSProperties = useMemo(()=> {
        if(!isMobile) return {};

        return {
            borderRadius: 8,
        }
    }, [isMobile]);

    const handleTogleShowDetails = useCallback(()=> {
        setShowDetails(!showDetails);
    }, [showDetails]);

    const renderBadges = useCallback(()=> (
        <CardPreviewBodyBadges>
            <SimpleBadge style={badgeCustomStyle}>{job.sector}</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>⌛️ {job.criteria.workTime}</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>⏰ Début : {formatDate(job.startAt)}</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>{job.criteria.contratType}</SimpleBadge>
            <SimpleBadge style={badgeCustomStyle}>{job.criteria.teleworking}</SimpleBadge>
        </CardPreviewBodyBadges>
    ), [badgeCustomStyle]);

    return (
        <Card isHighlighted={isHighlighted}>
            {
                isHighlighted && (
                    <HighlightedBadge>
                        Mis en avant 🔥
                    </HighlightedBadge>
                )
            }

            <CardPreview onClick={handleTogleShowDetails}>
                <LogoAndBody>
                    <OrganisationImg 
                        src="https://static.vecteezy.com/system/resources/thumbnails/015/645/187/small_2x/letter-o-and-desert-illustration-with-tall-palm-tree-logo-design-free-vector.jpg"
                        alt="organisation logo"
                    />

                    <CardPreviewBody>
                        <CardTitle>{job.entitle}</CardTitle>
                        <OrganisationName>Ohasis</OrganisationName>

                        {
                            isMobile ? (
                                <CardPreviewBodyBadges>
                                    {
                                        job.location && (
                                            <SimpleBadge style={badgeCustomStyle}>{job.location}</SimpleBadge>
                                        )
                                    }
                                    <SimpleBadge style={badgeCustomStyle}>💰 {job.criteria.minSalaryMonth}-{job.criteria.maxSalaryMonth}</SimpleBadge>
                                </CardPreviewBodyBadges>
                            ) : (
                                <>
                                    {
                                        job.location && (
                                            <SimpleBadge style={{...badgeCustomStyle, marginTop: 5}}>{job.location}</SimpleBadge>
                                        )
                                    }
                                </>
                            )
                        }
                    </CardPreviewBody>
                </LogoAndBody>

                {!isMobile && renderBadges()}

                <Days isHighlighted={isHighlighted}>{differenceInDays(new Date(), job.createAt!)}j</Days>

                <IconWrapper isHighlighted={isHighlighted} onClick={handleTogleShowDetails}>
                    <Icon 
                        size={isMobile ? "1x" : "2x"}
                        color="#fff"
                        iconName={showDetails ? IconNameEnum.minus : IconNameEnum.plus}
                    />
                </IconWrapper>
            </CardPreview>
            {
                showDetails && (
                    <>
                        {isMobile && renderBadges()}

                        <Description>
                            <DescriptionSection>
                                <DetailTitle>Descriptif du poste</DetailTitle>
                                <DetailDescription>
                                    {job.description}
                                </DetailDescription>
                            </DescriptionSection>

                            <DescriptionSection>
                                <DetailTitle>Profil recherché</DetailTitle>
                                <DetailDescription>
                                    {job.profileSought}
                                </DetailDescription>
                            </DescriptionSection>

                            {
                                job.conductOfInterviews && (
                                    <DescriptionSection>
                                        <DetailTitle>Déroulement des entretiens</DetailTitle>
                                        <DetailDescription>
                                            {job.conductOfInterviews}
                                        </DetailDescription>
                                    </DescriptionSection>
                                )
                            }
                        </Description>

                        {
                            !isOnlyReadMode && (
                                <ActionsButtons>
                                    <ButtonGroup>
                                        <SimpleButton style={buttonStyles}>
                                            <Icon 
                                                iconName={IconNameEnum.warning}
                                                size='lg'
                                            />
                                            En savoir plus
                                        </SimpleButton>

                                        <SimpleButton style={buttonStyles}>
                                            <Icon 
                                                iconName={IconNameEnum.share}
                                                size='lg'
                                            />
                                            Partager
                                        </SimpleButton>

                                        <SimpleButton style={buttonStyles}>
                                            <Icon 
                                                iconName={IconNameEnum.save}
                                                size='lg'
                                            />
                                            Sauvegarder
                                        </SimpleButton>
                                    </ButtonGroup>

                                    <AnimatedGradianButton 
                                        style={animatedButtonCustomStyle}
                                        onClick={()=> onSendCandidat && onSendCandidat(job)}
                                    >
                                        Postuler
                                    </AnimatedGradianButton>
                                </ActionsButtons>
                            )
                        }
                    </>
                )
            }
        </Card>
    )
}