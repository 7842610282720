import React, { CSSProperties, useCallback, useMemo } from "react";
import { ITabNavigation } from "./interfaces";

import { 
    AnimatedLogo, 
    Icon, Modal,  
    Sidebar,
    NavButton,
    PageWrapper,
    AvatarAcount,
    AnimatedGradianButton,
    Loader,
} from "@components";
import { IconNameEnum } from "@utilities/enums";

import { useControllers } from "./useControllers";
import { 
    MenuItems, 
    MenuItemsSection,  
    TabNavHeaderSection,
    TabNavHeaderSubContainer,
} from "./styles";
import { LoginComponent } from "../loginComponent";
import { Footer } from "../footer";
import { useFetchInterceptor } from "@utilities/hooks";

export const TabNavigation:React.FC<ITabNavigation> = ({
    children,
    activeStack,
    enableFaq = false,
    customBG,
})=> {

    const { isLoading } = useFetchInterceptor();
    const { 
        tabMenuItems,
        showMenuSidebar,
        isMobile,
        showLoginModal,
        talent,
        recruiter,
        toggleShowLoginModalModal,
        toggleShowMenuSidebar,
        handleLogout,
        handleGoToAccount,
        handleLogoPress,
    } = useControllers();

    const customLoginButtonStyles:CSSProperties = {
        borderRadius: 50
    };

    const avatarUri = useMemo(()=> (
        `${process.env.REACT_APP_BASE_PUBLIC_URL}/${talent?.avatar?.fileDir}/${talent?.avatar?.filename}`
    ), [talent]);
    
    const renderMenuItem = useCallback(()=> {
        return (
            <MenuItemsSection>
                <MenuItems>
                {
                    tabMenuItems.map((tabItem, i)=> {
                        if(!tabItem.isVisible) return;

                        return (
                            <NavButton
                                key={i}
                                isActive={activeStack === tabItem.stack}
                                onClick={()=> tabItem.link()}
                            >{tabItem.label}</NavButton>
                        )
                    })
                }
                </MenuItems>

                {
                    (talent || recruiter) ? (
                        <AvatarAcount 
                            avatar={avatarUri || ''}
                            onLogout={handleLogout}
                            onGotoAccount={handleGoToAccount}
                        />
                    ) : (
                        <AnimatedGradianButton 
                            onClick={toggleShowLoginModalModal}
                            style={customLoginButtonStyles}
                        >
                            Se connecter
                        </AnimatedGradianButton>
                    )
                }
            </MenuItemsSection>
        )
    }, [
        avatarUri,
        customLoginButtonStyles,
        talent, recruiter,
        tabMenuItems, activeStack, 
        toggleShowLoginModalModal,
        handleLogout, handleGoToAccount
    ]);

    return (
        <>
            <PageWrapper customBG={customBG}>
                <TabNavHeaderSection>
                    <TabNavHeaderSubContainer>
                        <AnimatedLogo onPress={handleLogoPress} />

                        {
                            isMobile ? (
                                <Icon 
                                    iconName={IconNameEnum.menu}
                                    onPress={toggleShowMenuSidebar}
                                    size='xl'
                                />
                            ) : renderMenuItem()
                        }
                    </TabNavHeaderSubContainer>
                </TabNavHeaderSection>
                
                {children}
                
                {isLoading && <Loader />}
            </PageWrapper>

            <Footer enableFaq={enableFaq} />

            {
                isMobile && (
                    <Sidebar 
                        isSidebarOpen={showMenuSidebar} 
                        onClose={toggleShowMenuSidebar}
                        items={tabMenuItems}
                    />
                )
            }

            <Modal
                isVisible={showLoginModal}
                onClose={toggleShowLoginModalModal}
            >
                <LoginComponent onCloseModal={toggleShowLoginModalModal} />
            </Modal>
        </>
    )
}

