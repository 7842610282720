import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  OrganisationInitialStateType,
  Organisation,
} from '@utilities/types';
import { SLICES_NAMES } from '../slicesNames';

const initialState: OrganisationInitialStateType = {
  selectedOrganisation: null,
};

const organisationSlice = createSlice({
  name: SLICES_NAMES.organisation,
  initialState, 
  reducers: {
    saveSelectedOrganisation: (state, action: PayloadAction<Organisation | null>) => {
      state.selectedOrganisation = action.payload
    },
  },
});

export const { 
  saveSelectedOrganisation
} = organisationSlice.actions;

export const organisationReducer = organisationSlice.reducer;
