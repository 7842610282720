import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    
    margin-top: 135px;
    background-color: #EFECE6;
    padding: 10px;
    padding-bottom: 100px;

    @media (min-width: 760px){
        flex-direction: row;
        gap: 50px;
        padding-left: 50px;
        padding-right: 50px;
    }
`;

export const GroupContainer = styled.section`
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const StatusSection = styled.div`
    background-color: #ffffff;
    border-radius: 20px;
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 20px;

    @media (min-width: 760px){
        width: 350px;
    }
`;

export const Statuslabel = styled.p`
    margin: 0%;
    margin-bottom: 10px;
    color: #101214;
    font-size: 16px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};

    @media (min-width: 760px){
        font-size: 22px;
        font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    }
`;

export const Text = styled.p`
    margin: 0%;
    font-size: 10px;
    color: #101214;
    margin-top: 5px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;

export const Text2 = styled.p`
    margin: 0%;
    font-size: 12px;
    text-align: center;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;

export const AlignItemCenter = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

export const ProfileSection = styled.section`
    background-color: #ffffff;
    border-radius: 20px;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: 760px){
        padding: 20px;
    }
`;

export const ProfileSectionHead = styled.div`
    flex: 1;
    position: relative;
    border-bottom: solid 1px #ECECEC;

    @media (min-width: 760px){
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
`;

export const ProfileSectionSubHead = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Avatar = styled.img`
    width: 56px;
    height: 60px;
    border-radius: 10px;
    object-fit: cover;

    @media (min-width: 760px){
        width: 76px;
        height: 80px;
    }
`;

export const UserName = styled.h3`
    margin: 0%;
    color: #101214;
    font-size: 18px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};

    @media (min-width: 760px){
        font-size: 22px;
    }
`;

export const ProfileTitle = styled.p`
    margin: 0%;
    margin-top: 5px;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_SemiBold};

    @media (min-width: 760px){
        font-size: 16px;
    }
`;

export const ProfileSectionBody = styled.div`
    width: 100%;
    margin-top: 10px;
    
    @media (min-width: 760px){
        
    }
`;

export const ProfileDescription = styled.p`
    margin: 0%;
    font-size: 12px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px){
        font-size: 14px;
    }
`;

export const BottomText1 = styled.p`
    margin: 0%;
    margin-top: 40px;
    font-size: 13px;
    color: #101214;
    text-align: center;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    @media (min-width: 760px){
        font-size: 16px;
    }
`;

export const BottomText2 = styled.p`
    margin: 0%;
    font-size: 13px;
    color: #101214;
    text-align: center;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px){
        font-size: 16px;
    }
`;

export const CompetencesTitle = styled.p`
    margin: 0%;
    margin-top: 30px;
    font-size: 13px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
`;

export const FloatButtonGroup = styled.div`
    position: absolute;
    z-index: 90;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: -10px;
    width: 100%;
    gap: 5px;
`;

export const IconWrapper = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: solid 1px #101214;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Location = styled.p`
    margin: 0%;
    margin-left: 10px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    font-size: 12px;

    @media (min-width: 760px){
        font-size: 14px;
    }
`;
