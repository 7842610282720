import { IconNameEnum, NavigationPathsEnum, StackEnum } from "@utilities/enums";
import {
    useAppointmentDispatch,
    useNavigation,
    usePostsDispatch,
    useRecruiterDispatch,
    useRecruiterSelectors,
    useScreenSize,
    useSpontaneousDemandDispatch,
    useTalentDispatch,
    useTalentSelectors,
} from "@utilities/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";

export const useControllers = ()=> {

    const { talent } = useTalentSelectors();
    const { recruiter } = useRecruiterSelectors();
    const { isMobile } = useScreenSize();
    const { navigateTo } = useNavigation();
    const { onTalentLogout } = useTalentDispatch();
    const { onLogoutRecruiter } = useRecruiterDispatch();
    const { onResetAppointmentRequest } = useAppointmentDispatch();
    const { onResetSpontaneousDemandRequest } = useSpontaneousDemandDispatch();
    const { onResetPostsRequest } = usePostsDispatch();

    // useIsAuthorized();

    const [showMenuSidebar, setShowMenuSidebar] = useState<boolean>(false);
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

    const toggleShowMenuSidebar = useCallback(()=> {
        setShowMenuSidebar(!showMenuSidebar);
    }, [showMenuSidebar]);

    const handleNavigateTo = useCallback((link: NavigationPathsEnum)=> {
        toggleShowMenuSidebar()
        navigateTo(link);
    }, [navigateTo, toggleShowMenuSidebar]);

    const toggleShowLoginModalModal = useCallback(()=> {
        toggleShowMenuSidebar();
        setShowLoginModal(!showLoginModal);
    }, [showLoginModal, toggleShowMenuSidebar]);

    const handleLogout = useCallback(()=> {
        onTalentLogout();
        onLogoutRecruiter();
        onResetAppointmentRequest();
        onResetSpontaneousDemandRequest();
        onResetPostsRequest();
        navigateTo(NavigationPathsEnum.HOME);
    }, [
        navigateTo, onTalentLogout,
        onLogoutRecruiter, 
        onResetAppointmentRequest, 
        onResetPostsRequest,
        onResetSpontaneousDemandRequest,
    ]);

    const handleGoToAccount = useCallback(()=> {
        if(talent){
            navigateTo(NavigationPathsEnum.TALENT_ACCOUNT);
        }
        if(recruiter){
            navigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT);
        }
    }, [navigateTo, recruiter, talent]);

    const handleLogoPress = useCallback(()=> {
        navigateTo(NavigationPathsEnum.HOME);
    }, [navigateTo]);

    const tabMenuItems = useMemo(()=> {
        return [
            {
                label: "Accueil",
                stack: StackEnum.Home,
                link: ()=> handleNavigateTo(NavigationPathsEnum.HOME),
                isVisible: !recruiter,
            },
            {
                label: "Trouver un job",
                stack: StackEnum.FindJob,
                // link: ()=> handleNavigateTo(NavigationPathsEnum.FIND_JOB_OFFER),
                link: ()=> alert('Bientôt disponible !'),
                isVisible: !recruiter,
            },
            {
                label: "Trouver une entreprise",
                stack: StackEnum.FindOrganisation,
                // link: ()=> handleNavigateTo(NavigationPathsEnum.Find_ORGANISATION),
                link: ()=> alert('Bientôt disponible !'),
                isVisible: !recruiter,
            },
            {
                label: "Marque employeur",
                stack: StackEnum.OrganisationBranding,
                link: ()=> handleNavigateTo(NavigationPathsEnum.EMPLOYER_BRAND_GENERAL),
                isVisible: !!recruiter,
            },
            {
                label: "Recrutement",
                stack: StackEnum.JobOfferManagement,
                link: ()=> handleNavigateTo(NavigationPathsEnum.JOB_OFFER_MANAGEMENT),
                isVisible: !!recruiter,
            },
            {
                label: "Explorer les talents",
                stack: StackEnum.FindTalent,
                link: ()=> handleNavigateTo(NavigationPathsEnum.FIND_TALENT),
                isVisible: !!recruiter,
            },
            {
                label: "Mon espace",
                link: ()=> handleNavigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
                isVisible: (!!talent && isMobile),
            },
            {
                label: "Mon espace",
                link: ()=> handleNavigateTo(NavigationPathsEnum.RECRUITER_ACCOUNT),
                isVisible: (!!recruiter && isMobile),
            },
            {
                label: "Se connecter",
                link: ()=> toggleShowLoginModalModal(),
                icon: IconNameEnum.connect,
                isVisible: (!(talent || recruiter) && isMobile),
            },
            {
                label: "Se déconnecter",
                link: handleLogout,
                icon: IconNameEnum.disconect,
                isVisible: (!!(talent || recruiter) && isMobile),
            }
        ];
    }, [
        talent, recruiter, isMobile, 
        handleLogout, handleNavigateTo, 
        toggleShowLoginModalModal,
    ]);

    return {
        tabMenuItems,
        showMenuSidebar,
        isMobile,
        recruiter,
        showLoginModal,
        talent,
        handleLogout,
        handleGoToAccount,
        toggleShowLoginModalModal,
        toggleShowMenuSidebar,
        handleLogoPress,
    }
}