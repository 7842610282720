import styled from "styled-components";

export const ModalContainer = styled.div`
    position: relative;
    overflow-y: scroll;
    scrollbar-width: none;
    height: 78vh;
    padding-bottom: 50px;

    &::-webkit-scrollbar {
        display: none;
    }

    @media (min-width: 760px) {
        height: 85vh;
        padding: 20px;
        padding-top: 10px;
    }
`;
