import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const ProfileSection = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;

export const Avatar = styled.img`
    width: 70px;
    height: 70px;
    border-radius: 10px;
    background-color: #c3c3c3;
`;

export const Column = styled.div`

`;

export const UserName = styled.p`
    margin: 0%;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 18px;
`;

export const Job = styled.p`
    margin: 0%;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_MEDIUM};
    font-size: 14px;
`;