import { FC } from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { Container, JobOfferCard, Page } from "@components";
import { useController } from "../useControllers";
import { JobsContainer } from "./styles";

export const TalentAccount_ApplicationTracking:FC = ()=> {

    useAnalyticsPageTracking('TalentAccount_ApplicationTracking');
    const translate = useTranslation();
    const { itemsMenuNav, talentMatchings } = useController();

    return (
        <TabNavigation customBG="#EFECE6">
            <Page>
                <SubMenu
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[3].label}
                />

                <Container>
                    { 
                        talentMatchings.length > 0 && (
                            <JobsContainer>
                                {
                                    talentMatchings.map((matching, i)=> (
                                        <JobOfferCard 
                                            key={i}
                                            job={matching.postDetails!}  
                                            isOnlyReadMode                              
                                        />
                                    ))
                                }
                            </JobsContainer>
                        )
                    }
                </Container>
            </Page>
        </TabNavigation>
    )
}
