import { 
    saveTalentAppointment,
    saveRecruiterAppointment,
} from "@data/reducers";
import { 
    fetchCreateNewAppointmentRequest,
    fetchDeletedAppointmentRequest,
    fetchGetRecruiterAppointmentRequest, 
    fetchGetTalentAppointmentRequest,
    fetchTalentSelectedAppointmentRequest,
    fetchUpdatedAppointmentRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { Appointment, FetchResponseType } from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useRecruiterSelectors, useTalentSelectors } from "../selectors";
import { usePostsDispatch } from "./usePostsDispatch";

export const useAppointmentDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { recruiter } = useRecruiterSelectors();
    const { talent, talentCV } = useTalentSelectors();
    const { onGetTalentApplicationTrackingRequest } = usePostsDispatch();

    const onGetTalentAppointmentRequest = useCallback(async(userToken: string) => {
        const req:FetchResponseType = await fetchGetTalentAppointmentRequest(userToken);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentAppointment:Appointment[] = req.data;
        dispatch(saveTalentAppointment(talentAppointment));
    }, [dispatch]);

    const onGetRecruiterAppointmentRequest = useCallback(async(recruiterToken: string) => {
        const req:FetchResponseType = await fetchGetRecruiterAppointmentRequest(recruiterToken);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const recruiterAppointment:Appointment[] = req.data;
        dispatch(saveRecruiterAppointment(recruiterAppointment));
    }, [dispatch]);

    const onCreateNewAppointmentRequest = useCallback(async(newAppointment: Appointment) => {
        const req:FetchResponseType = await fetchCreateNewAppointmentRequest({
            appointment: newAppointment,
            recruiterToken: recruiter?.token!,
        });
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        
        await onGetRecruiterAppointmentRequest(recruiter?.token!);
    }, [onGetRecruiterAppointmentRequest, recruiter]);

    const onTalentSelectedAppointmentRequest = useCallback(async(
        appointmentID: string, talentToken: string
    ) => {
        const req:FetchResponseType = await fetchTalentSelectedAppointmentRequest({
            appointmentID, talentToken
        });
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        
        if(talentCV){
            onGetTalentApplicationTrackingRequest(talentCV._id!);
        }
    }, [talentCV, onGetTalentApplicationTrackingRequest]);

    const onUpdatedAppointmentRequest = useCallback(async(updatedAppointment: Appointment) => {
        const req:FetchResponseType = await fetchUpdatedAppointmentRequest(updatedAppointment);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        
        if(recruiter){
            await onGetRecruiterAppointmentRequest(recruiter?.token!);
        }
        if(talent){
            await onGetTalentAppointmentRequest(talent?.token!);
        }
    }, [
        recruiter, talent,
        onGetRecruiterAppointmentRequest, 
        onGetTalentAppointmentRequest,
    ]);

    const onDeletedAppointmentRequest = useCallback(async(appointmentID: string) => {
        const req:FetchResponseType = await fetchDeletedAppointmentRequest(appointmentID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        
        await onGetRecruiterAppointmentRequest(recruiter?.token!);
    }, [onGetRecruiterAppointmentRequest, recruiter]);

    const onResetAppointmentRequest = useCallback(()=> {
        dispatch(saveTalentAppointment([]));
        dispatch(saveRecruiterAppointment([]));
    }, [dispatch]);

    return {
        onGetTalentAppointmentRequest,
        onGetRecruiterAppointmentRequest,
        onCreateNewAppointmentRequest,
        onTalentSelectedAppointmentRequest,
        onUpdatedAppointmentRequest,
        onDeletedAppointmentRequest,
        onResetAppointmentRequest,
    };
};
