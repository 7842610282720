import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const ExplicationTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    font-style: italic;
    font-size: 14px;
    color: #101214;
`;

export const LevelResult = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};
    font-size: 16px;
    color: #101214;
    text-align: center;
`;

export const ModalContainer = styled.section`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const QuestionSection = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 70px;
`;

export const ResponseSection = styled.div`
    padding: 10px 5px 10px 5px;
    margin-top: 10px;
`;

export const ResponseTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    color: #101214;
    font-size: 14px;
    padding: 10px 10px 10px 10px;
    margin-bottom: 6px;
    border-radius: 8px;
`;

export const ResultCard = styled.div`
    width: 100%;
    background-color: #fff;
    border: solid 2px #F1F1EF;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
`;

export const ResultCardHead = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ResultCardBody = styled.div`
    
`;

export const ResultCardHeadQuestionLabel = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;

export const ResultCardHeadQuestionValue = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;

export const ScoreTxt = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};
    font-size: 22px;
    color: #101214;
    text-align: center;
`;

export const TestCardSubTitle = styled.p`
    margin: 0%;
    font-size: 12px;
    margin-top: 4px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};

    @media (min-width: 760px){
        font-size: 18px;
        margin-top: 10px;
    }
`;