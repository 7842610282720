import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { 
    FloatButtonGroup, 
    IconWrapper,   
    ProfileSectionBody, 
    ProfileSectionHead, 
} from "../styles";
import { 
    InfoLabel, 
    InfosWrapper,
    BadgeWithIcon,
    BadgeWrapper,
    CardPage,
    CardPageSubTitle,
    CardPageTitle,
    DescriptionBadge, FixedModalFooter, Icon, Input, 
    InputLabel, 
    InputWrapper, 
    InputWrapperGroup, 
    Modal, ModalContainer, ModalSubTitle, ModalTitle, SimpleBadge, SimpleButton, 
    SimpleCheckbox,
    SimpleSelect,
    TodoComponent,
} from "@components";
import { useScreenSize } from "@utilities/hooks";
import { IconNameEnum } from "@utilities/enums";
import { CV, EnumFormationLevel, Formation } from "@utilities/types";
import { enumToStringArray, getMonth, getMonthName, getYear } from "@utilities/functions";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    talentCV: CV | null
    onSaveTalentCVRequest: (cv: CV)=> void
}

const descriptionPlaceholder = `ex :    
    •	Projet de recherche en informatique :
    •	Analyse et interprétation de données.
    •	Développement d’algorithmes pour un projet de recherche.
    •	Collaboration avec des ingénieurs pour l’amélioration du produit.
`;

export const Formations:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
    talentCV,
    onSaveTalentCVRequest,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const [formationName, setFormationName] = useState<string>('');
    const [formationLevel, setFormationLevel] = useState<string>('');
    const [organisme, setOrganisme] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [isStillInFormation, setIsStillInFormation] = useState<boolean>(false);
    const [skill, setSkill] = useState<string>('');
    const [skills, setSkills] = useState<string[]>([]);

    const formationLevelList = useMemo(()=> {
        let result:{label: string, value: string}[] = [];
        let tmp = enumToStringArray(EnumFormationLevel);
        tmp.map(el=> {
            result.push({
                label: el,
                value: el,
            })
        });

        return result;
    }, []);

    const handleAddSkill = useCallback(()=> {
        setSkills(e => {
            if (!e.includes(skill)) {
                return [...e, skill];
            }
            return e;
        });
        setSkill('');
    }, [skill]);

    const handleRemoveSkill = useCallback((competenceEL: string)=> {
        setSkills(e => e.filter(element=> element !== competenceEL));
    }, []);

    const handleClose = useCallback(()=> {
        setFormationName('');
        setFormationLevel('');
        setOrganisme('');
        setStartDate('');
        setEndDate('');
        setDescription('');
        setSkills([]);
        setIsStillInFormation(false);
        setIsVisible(false);
        setActiveIndex(null);
    }, []);

    const handleEditFormation = useCallback((formation: Formation, index: number)=> {
        const startDateTmp = `${formation.startYear}-${formation.startMonth <= 9 ? 0 : ''}${formation.startMonth}`;
        const endDateTmp = formation.isStillInFormation ? '' : `${formation.endYear}-${formation.endMonth! <= 9 ? 0 : ''}${formation.endMonth}`;

        setFormationName(formation.name || '');
        setFormationLevel(formation.level || '');
        setOrganisme(formation.organization || '');
        setStartDate(startDateTmp);
        setEndDate(endDateTmp);
        setDescription(formation.description || '');
        setSkills(formation.skills || []);
        setIsStillInFormation(formation.isStillInFormation || false);
        setIsVisible(true);
        setActiveIndex(index);
    }, []);

    const handleAddFormation = useCallback(()=> {
        if(
            talentCV && formationName && organisme && formationLevel
            && startDate && description && (endDate || isStillInFormation === true)
        ){
            const startMonth = getMonth(new Date(startDate));
            const startYear = getYear(new Date(startDate));

            const endMonth = isStillInFormation ? null : getMonth(new Date(endDate));
            const endYear = isStillInFormation ? null : getYear(new Date(endDate));

            let formations:Formation[] = [...(talentCV.formations || [])];
            const formation:Formation = {
                isStillInFormation,
                startMonth,
                startYear,
                endMonth,
                endYear,
                name: formationName,
                level: formationLevel,
                organization: organisme,
                description,
                skills,
            };

            formations.push(formation);

            onSaveTalentCVRequest({
                ...talentCV,
                formations,
            })
            handleClose();
        }
    }, [
        talentCV, formationName, organisme, 
        formationLevel, startDate, description, 
        endDate, isStillInFormation, skills, 
        onSaveTalentCVRequest, handleClose,
    ]);

    const handleUpdatedFormation = useCallback(()=> {
        const formationsTmp = [...talentCV?.formations || []];
        
        const updatedFormations = formationsTmp.map((el, i) => {
            if (
                i === activeIndex &&
                talentCV && formationName && organisme && formationLevel
                && startDate && description && (endDate || isStillInFormation === true)
            ) {
                const startMonth = getMonth(new Date(startDate));
                const startYear = getYear(new Date(startDate));

                const endMonth = isStillInFormation ? null : getMonth(new Date(endDate));
                const endYear = isStillInFormation ? null : getYear(new Date(endDate));
    
                return {
                    ...el,
                    isStillInFormation,
                    startMonth,
                    startYear,
                    endMonth,
                    endYear,
                    name: formationName,
                    level: formationLevel,
                    organization: organisme,
                    description,
                    skills,
                };
            }

            return el;
        });

        onSaveTalentCVRequest({
            ...talentCV!,
            formations: updatedFormations,
        });
        handleClose();
    }, [
        talentCV, activeIndex, formationName, 
        organisme, formationLevel, startDate, 
        description, endDate, isStillInFormation, skills,
        onSaveTalentCVRequest, handleClose
    ]);

    const handleDeleteFormation = useCallback((index: number)=> {
        const formationTemp = [...talentCV?.formations || []];

        const updatedFormations = formationTemp.filter((el, i)=> i !== index);
        onSaveTalentCVRequest({
            ...talentCV!,
            formations: updatedFormations,
        });
    }, [talentCV, onSaveTalentCVRequest]);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Diplômes & formations
                </CardPageTitle>
                <CardPageSubTitle>
                    Listez vos diplômes, formations et certifications pertinents.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={()=> setIsVisible(true)}
                    style={simpleButtonStyle}
                >
                    Ajouter
                </SimpleButton>

                {
                    talentCV?.formations && talentCV.formations.length > 0 && (
                        <ProfileSectionBody>
                            {
                                talentCV.formations.map((formation, i)=> {
                                    return (
                                        <ProfileSectionHead 
                                            key={i}
                                            style={{paddingBottom: 30, marginTop: 30, gap: 0}}
                                        >
                                            <FloatButtonGroup>
                                                <IconWrapper onClick={()=> handleEditFormation(formation, i)}>
                                                    <Icon
                                                        iconName={IconNameEnum.edit}
                                                        size='xs'
                                                    />
                                                </IconWrapper>

                                                <IconWrapper 
                                                    onClick={()=> handleDeleteFormation(i)}
                                                    style={{
                                                        backgroundColor: '#B00000',
                                                        borderColor: '#B00000',
                                                    }}
                                                >
                                                    <Icon 
                                                        iconName={IconNameEnum.trash}
                                                        size='xs'
                                                        color="#fff"
                                                    />
                                                </IconWrapper>
                                            </FloatButtonGroup>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Nom
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        ✨ {formation.name}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Niveau
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        ⚡️ {formation.level}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    École ou organisme
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        🎓 {formation.organization}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Date
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <SimpleBadge>
                                                        📅  De {`${getMonthName(formation.startMonth).toLowerCase()} ${formation.startYear}`} à {formation.isStillInFormation ? "aujourd'hui" : `${getMonthName(formation.endMonth!).toLowerCase()} ${formation.endYear}`}
                                                    </SimpleBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            <InfosWrapper>
                                                <InfoLabel>
                                                    Description, missions
                                                </InfoLabel>

                                                <BadgeWrapper>
                                                    <DescriptionBadge>
                                                        {formation.description}
                                                    </DescriptionBadge>
                                                </BadgeWrapper>
                                            </InfosWrapper>

                                            {
                                                formation?.skills.length > 0 && (
                                                    <InfosWrapper>
                                                        <InfoLabel>
                                                            Compétences
                                                        </InfoLabel>
                                                        <BadgeWrapper>
                                                            {
                                                                formation.skills.map((el, j)=> (
                                                                    <SimpleBadge key={j} color="#fff" bgColor="#0C1421" >
                                                                        {el}
                                                                    </SimpleBadge>
                                                                ))
                                                            }
                                                        </BadgeWrapper>
                                                    </InfosWrapper>
                                                )
                                            }
                                        </ProfileSectionHead>
                                    )
                                })
                            }
                        </ProfileSectionBody>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Diplômes & formations
                        </ModalTitle>
                        <ModalSubTitle>
                            Listez vos diplômes, formations et certifications pertinents.
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                ✨ Nom*
                            </InputLabel>
                            <Input
                                placeholder="ex : Licence UI/UX Design"
                                value={formationName}
                                onChange={setFormationName}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                ⚡️ Niveau*
                            </InputLabel>
                            <SimpleSelect
                                placeholder="Sélectionnez"
                                options={formationLevelList}
                                value={formationLevel}
                                onChange={setFormationLevel}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🎓 École ou organisme
                            </InputLabel>
                            <Input
                                placeholder="ex : Université Paris II"
                                value={organisme}
                                onChange={setOrganisme}
                            />
                        </InputWrapper>

                        <InputWrapperGroup>
                            <InputWrapper>
                                <InputLabel>
                                    📅 Du*
                                </InputLabel>
                                <Input
                                    isMonth
                                    inputType='date'
                                    placeholder="MM/AA"
                                    value={startDate}
                                    onChange={setStartDate}
                                />
                            </InputWrapper>

                            <InputWrapper>
                                <InputLabel>
                                    📅 Au*
                                </InputLabel>
                                <Input
                                    isMonth
                                    inputType='date'
                                    placeholder="MM/AA"
                                    value={endDate}
                                    onChange={setEndDate}
                                />
                            </InputWrapper>
                        </InputWrapperGroup>

                        <SimpleCheckbox 
                            label="J’y étudie toujours"
                            checked={isStillInFormation}
                            setChecked={()=> setIsStillInFormation(!isStillInFormation)}
                            styles={{marginTop: 10}}
                        />

                        <InputWrapper>
                            <InputLabel>
                                🕊️ Description
                            </InputLabel>
                            <Input
                                inputType='textarea'
                                placeholder={descriptionPlaceholder}
                                value={description}
                                onChange={setDescription}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                💪 Compétences acquises
                            </InputLabel>
                            <TodoComponent
                                placeholder="Ex: figma"
                                value={skill}
                                onChange={setSkill}
                                onAdd={handleAddSkill}
                            />
                            <BadgeWrapper>
                                {
                                    skills.map((el, i)=> {
                                        return (
                                            <BadgeWithIcon
                                                key={i}
                                                onIconPress={()=> handleRemoveSkill(el)}
                                            >
                                                {el}
                                            </BadgeWithIcon>
                                        )
                                    })
                                }
                            </BadgeWrapper>
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={typeof activeIndex === "number" ? handleUpdatedFormation : handleAddFormation}>
                            {typeof activeIndex === "number" ? 'Enregistrer' : 'Ajouter'}
                        </SimpleButton>

                        <SimpleButton onClick={handleClose} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}