import { NavigationPathsEnum } from "@utilities/enums";
import { useNavigation, useRecruiterDispatch, useRecruiterSelectors } from "@utilities/hooks";
import { useEffect, useMemo } from "react";

export const useController = ()=> {

    const { navigateTo } = useNavigation();
    const { recruiter, organisation } = useRecruiterSelectors();
    const { 
        onGetOrganisationrequest,
        onUpdatedOrganisation,
    } = useRecruiterDispatch();

    const itemsMenuNav = useMemo(()=> [
        {
            label: 'Informations générales',
            goTo: ()=> navigateTo(NavigationPathsEnum.EMPLOYER_BRAND_GENERAL),
        },
        {
            label: 'Départements',
            goTo: ()=> navigateTo(NavigationPathsEnum.EMPLOYER_BRAND_DEPARTMENT),
        },
        {
            label: 'Bureaux',
            goTo: ()=> navigateTo(NavigationPathsEnum.EMPLOYER_BRAND_OFFICES),
        },
    ], [navigateTo]);

    useEffect(()=> {
        if(recruiter && recruiter._isOrganisation){
            onGetOrganisationrequest(recruiter._organisationID!);
        }
    }, [recruiter]);

    return {
        itemsMenuNav,
        organisation,
        onUpdatedOrganisation,
    }
};
