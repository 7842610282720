import { 
    CardPage, CardPageTitle, 
    FixedModalFooter, Input, InputLabel, 
    InputWrapper, Modal, ModalContainer, 
    ModalSubTitle, ModalTitle, SimpleButton,
} from "@components";
import { CSSProperties, FC, useCallback, useState } from "react";
import { Text } from "../styles";
import { Organisation } from "@utilities/types";

interface Props {
    organisation: Organisation | null
    simpleButtonStyles: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    onUpdatedOrganisation: (organisation: Organisation)=> void
}

export const GoodToKnow:FC<Props> = ({
    organisation,
    simpleButtonStyles,
    simpleButtonOutlineStyle,
    onUpdatedOrganisation,
})=> {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [description, setDescription] = useState<string>(organisation?.goodToKnow || '');

    const toggleIsOpen = useCallback(()=> {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleSave = useCallback(()=> {
        if(organisation){
            onUpdatedOrganisation({
                ...organisation,
                ...(description ? {goodToKnow: description} : {})
            });
    
            toggleIsOpen();
        }
    }, [
        organisation, description, 
        onUpdatedOrganisation, toggleIsOpen
    ]);

    return (
        <>
            <CardPage>
                <CardPageTitle>Bon à savoir</CardPageTitle>

                <SimpleButton style={simpleButtonStyles} onClick={toggleIsOpen}>
                    Modifier
                </SimpleButton>

                {
                    organisation?.goodToKnow && (
                        <Text>
                            {organisation.goodToKnow}
                        </Text>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isOpen}
                onClose={toggleIsOpen}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Bon à savoir
                        </ModalTitle>

                        <ModalSubTitle>
                            important
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                Description
                            </InputLabel>
                            <Input
                                inputType='textarea'
                                placeholder="Description"
                                value={description}
                                onChange={setDescription}
                            />
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={toggleIsOpen} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}