import { EnumPaths } from "@utilities/enums";
import { fetchRequest } from "@utilities/requests";
import { 
    FetchRequestType, 
    FetchResponseType,
    Post,
} from "@utilities/types";

export const fetchGetPostRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ALL_POSTS}?talentToken=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetOrganisationPostRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: `${EnumPaths.GET_ALL_ORGANISATION_POSTS}?organisationID=${payload}`,
        method: 'GET',
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchSaveOrganisationPostRequest = async(
    payload: {post: Post, token: string}
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.CREATE_POST,
        method: 'POST',
        data: {newPostData: payload.post, recruiterToken: payload.token},
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedOrganisationPostRequest = async(
    payload: Post
):Promise<FetchResponseType>=> {

    const requestConfig: FetchRequestType = {
        route: EnumPaths.UPDATED_POST,
        method: 'PUT',
        data: payload,
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentResponseToJobRequestRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    const requestConfig:FetchRequestType = {
        route: `${EnumPaths.GET_TALENT_RESPONSES_TO_JOB_OFFER}?talentID=${payload}`,
        method: 'GET'
    };

    try {
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchOnTalentResponseToJobOfferRequest = async(
    payload: {postID: string, talentToken: string, talentCVID: string}
):Promise<FetchResponseType>=> {

    try {
        const requestConfig:FetchRequestType = {
            route: EnumPaths.MATCHING_JOB_2_CV,
            method: 'POST',
            data: {
                postID: payload.postID, 
                talentToken: payload.talentToken, 
                talentCVID: payload.talentCVID,
            }
        };
        
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetTalentApplicationTrackingRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    try {
        const requestConfig:FetchRequestType = {
            route: `${EnumPaths.GET_TALENT_APPLICATION_TRACKING}?talentCvId=${payload}`,
            method: 'GET',
        };
        
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchGetResponseToJobOfferRequest = async(
    payload: string
):Promise<FetchResponseType>=> {

    try {
        const requestConfig:FetchRequestType = {
            route: `${EnumPaths.GET_JOB_2_CV_MATCHING}?postID=${payload}`,
            method: 'GET',
        };
        
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};

export const fetchUpdatedTalentApplicationStatusRequest = async(
    payload: {
        matchingID: string 
        talentToken: string 
        newTalentApplictionStatus: string
    }
):Promise<FetchResponseType>=> {

    try {
        const requestConfig:FetchRequestType = {
            route: EnumPaths.UPDATED_TALENT_APPLICATION_STATUS,
            method: 'PUT',
            data: payload,
        };
        
        const response:FetchResponseType = await fetchRequest(requestConfig);
        return response;
    } catch (error) {
        console.error('Error in fetchSigninRequest:', error);
        throw error;
    }
};
