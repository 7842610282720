import React, { CSSProperties, useMemo } from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { StackEnum } from "@utilities/enums";
import { Container, Page } from "@components";
import { 
    Presentation, 
    WhatWeAreLookingFor, 
    OrganisationInfos, 
    GoodToKnow, 
    SocialMedia,
} from "./components";

export const EmployerBrandGenerals:React.FC = ()=> {

    useAnalyticsPageTracking('EmployerBrandGenerals');
    const translate = useTranslation();
    const { 
        itemsMenuNav, 
        organisation,
        onUpdatedOrganisation,
    } = useController();

    const simpleButtonStyles:CSSProperties = useMemo(()=> (
        {
            position: 'absolute',
            top: 30,
            right: 30,
        }
    ), []);

    const simpleButtonOutlineStyle:CSSProperties = useMemo(()=> (
        {
            backgroundColor: 'transparent',
            color: '#101214',
            border: 'solid 1px #101214',
        }
    ), []);

    return (
        <TabNavigation 
            customBG="#EFECE6"
            activeStack={StackEnum.OrganisationBranding}
        >
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[0].label}
                />

                <Container>

                    <OrganisationInfos 
                        organisation={organisation}
                        simpleButtonStyles={simpleButtonStyles}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                        onUpdatedOrganisation={onUpdatedOrganisation}
                    />

                    <SocialMedia 
                        organisation={organisation}
                        simpleButtonStyles={simpleButtonStyles}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                        onUpdatedOrganisation={onUpdatedOrganisation}
                    />

                    <Presentation 
                        organisation={organisation}
                        simpleButtonStyles={simpleButtonStyles}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                        onUpdatedOrganisation={onUpdatedOrganisation}
                    />

                    <WhatWeAreLookingFor 
                        organisation={organisation}
                        simpleButtonStyles={simpleButtonStyles}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                        onUpdatedOrganisation={onUpdatedOrganisation}
                    />

                    <GoodToKnow
                        organisation={organisation}
                        simpleButtonStyles={simpleButtonStyles}
                        simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                        onUpdatedOrganisation={onUpdatedOrganisation}
                    />

                </Container>
            </Page>
        </TabNavigation>
    )
}
