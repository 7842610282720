import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { useAnalyticsPageTracking, useScreenSize, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation, TalentCVModal } from "@connectedComponents";
import { 
    AlignItemCenter,
    BottomText1, 
    BottomText2, 
    Container, GroupContainer,
    Statuslabel, 
    StatusSection, 
    Text, Text2,
} from './styles';
import { AnimatedGradianButton, Icon, SelectStatus } from "@components";
import { CVstateEnum, FontFamilyEnum, IconNameEnum } from "@utilities/enums";
import { 
    Competences, Experiences, 
    Formations, JobFind, 
    OtherRessources, ProfileSectionComponent,
} from "./components";
import { useController } from "../useControllers";
import { useControllers } from "./useControllers";

export const TalentAccount:React.FC = ()=> {

    useAnalyticsPageTracking('TalentAccount');
    const translate = useTranslation();
    const { isMobile } = useScreenSize();
    const { itemsMenuNav } = useController();
    const { 
        talent, talentCV, 
        onSaveTalentCVRequest,
        onUpdatedUserTalent,
        onUpdatedTalentAvatarData,
    } = useControllers();

    const [cvState, setCvState] = useState<CVstateEnum>(talentCV?.state || CVstateEnum.NotAvailable)
    const [displayTalentCV, setDisplayTalentCV] = useState<boolean>(false);

    const DesktopGroupContainerStyles:CSSProperties = {
        height: '80vh',
        overflowY: 'scroll',
    };

    const simpleButtonStyle:CSSProperties = useMemo(()=> {
        if(isMobile){
            return {
                marginTop: 15,
            }
        } else {
            return {
                position: 'absolute',
                right: 20,
                borderRadius: 50,
                fontSize: 14,
                height: 38
            }
        }
    }, [isMobile]);

    const simpleButtonOutlineStyle:CSSProperties = {
        backgroundColor: 'transparent',
        color: '#101214',
        border: 'solid 1px #101214',
    };

    const handleToggleDisplayTalentCV = useCallback(()=> {
        setDisplayTalentCV(!displayTalentCV);
    }, [displayTalentCV]);

    const handleChangeCVState = useCallback((state: CVstateEnum)=> {
        if(talentCV){
            onSaveTalentCVRequest({
                ...talentCV,
                state,
            });
        }
    }, [talentCV, onSaveTalentCVRequest]);

    return (
        <TabNavigation customBG="#EFECE6">
            <>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[0].label}
                />

                <Container>
                    <GroupContainer>
                        <StatusSection>
                            <Statuslabel>
                                Mon statut
                            </Statuslabel>

                            <SelectStatus 
                                cvState={cvState}
                                onChangeState={handleChangeCVState}
                            />

                            <Text style={{marginTop: 20}}>
                                Faites savoir aux recruteurs s'ils peuvent vous contacter.
                            </Text>
                            <Text>
                                <Icon 
                                    iconName={IconNameEnum.eyeSlash} size='lg' 
                                /> Votre employeur n'a pas accès à vos informations.
                            </Text>
                        </StatusSection>

                        <StatusSection>
                            <AlignItemCenter>
                                <AnimatedGradianButton onClick={handleToggleDisplayTalentCV}>
                                    Consulter mon CV
                                </AnimatedGradianButton>
                            </AlignItemCenter>
                            
                            <Text2>
                                Votre profil a été consulté 0 fois 
                            </Text2>
                            <Text2>
                                dans les 7 derniers jours.
                            </Text2>
                        </StatusSection>
                    </GroupContainer>

                    <GroupContainer style={isMobile ? {} : DesktopGroupContainerStyles}>
                        <ProfileSectionComponent 
                            simpleButtonStyle={simpleButtonStyle} 
                            simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                            talent={talent}
                            talentCV={talentCV}
                            onSaveTalentCVRequest={onSaveTalentCVRequest}
                            onUpdatedUserTalent={onUpdatedUserTalent}
                            onUpdatedTalentAvatarData={onUpdatedTalentAvatarData}
                        />

                        <JobFind 
                            simpleButtonStyle={simpleButtonStyle} 
                            simpleButtonOutlineStyle={simpleButtonOutlineStyle} 
                            talentCV={talentCV}
                            onSaveTalentCVRequest={onSaveTalentCVRequest}
                        />

                        <Experiences 
                            simpleButtonStyle={simpleButtonStyle}
                            simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                            talentCV={talentCV}
                            onSaveTalentCVRequest={onSaveTalentCVRequest}
                        />

                        <Competences 
                            simpleButtonStyle={simpleButtonStyle} 
                            simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                            talentCV={talentCV}
                            onSaveTalentCVRequest={onSaveTalentCVRequest}
                        />

                        <Formations 
                            simpleButtonStyle={simpleButtonStyle} 
                            simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                            talentCV={talentCV}
                            onSaveTalentCVRequest={onSaveTalentCVRequest}
                        />

                        <OtherRessources 
                            simpleButtonStyle={simpleButtonStyle} 
                            simpleButtonOutlineStyle={simpleButtonOutlineStyle}
                            talentCV={talentCV}
                            onSaveTalentCVRequest={onSaveTalentCVRequest}
                        />

                        {/* <BottomText1>
                            Vous avez des suggestions ?
                        </BottomText1>

                        <BottomText2>
                            N’hésitez pas à nous écrire via le chatbot.
                        </BottomText2> */}
                    </GroupContainer>
                </Container>

                {
                    (talent && talentCV) && (
                        <TalentCVModal 
                            talent={talent}
                            talentCV={talentCV}
                            isVisible={displayTalentCV}
                            onClose={handleToggleDisplayTalentCV}
                        />
                    )
                }
            </>
        </TabNavigation>
    )
}
