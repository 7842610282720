import { NavigationPathsEnum } from "@utilities/enums";
import { useNavigation, usePostsDispatch, usePostSelectors, useTalentSelectors } from "@utilities/hooks";
import { useEffect, useMemo } from "react";

export const useController = ()=> {

    const { navigateTo } = useNavigation();
    const { talentCV, talent } = useTalentSelectors();
    const { talentMatchings } = usePostSelectors();
    const { onGetTalentApplicationTrackingRequest } = usePostsDispatch();

    const itemsMenuNav = useMemo(()=> [
        {
            label: 'Profil',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
        },
        {
            label: 'Tests de compétences',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT_TESTS),
        },
        {
            label: 'Propositions  de recruteurs',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT_RECRUITERS_PROPOSALS),
        },
        {
            label: 'Suivi des candidatures',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT_APPLICATIONS_TRACKING),
        },
        {
            label: 'Rendez-vous',
            // goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT),
            goTo: ()=> {}
        },
        {
            label: 'Paramètres',
            goTo: ()=> navigateTo(NavigationPathsEnum.TALENT_ACCOUNT_SETTINGS),
        }
    ], [navigateTo]);

    useEffect(()=> {
        if(talentCV){
            onGetTalentApplicationTrackingRequest(talentCV._id!);
        }
    }, [talentCV]);

    return {
        talent,
        itemsMenuNav,
        talentMatchings,
    }
};
