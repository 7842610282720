import { useTalentDispatch, useTalentSelectors } from "@utilities/hooks";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useControlers = ()=> {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const otp = queryParams.get('otp');

    const { talent } = useTalentSelectors();
    const { onSendNewMail, onValidateTalentMailRequest, onSignWithOTP } = useTalentDispatch();

    useEffect(()=> {
        if(otp && talent){
            onValidateTalentMailRequest(otp);
        }

        if(otp && !talent){
            onSignWithOTP(otp);
        }
    }, [otp, talent]);

    return {
        talent,
        onSendNewMail,
    }
}
