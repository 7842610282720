import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Title = styled.p`
    margin: 0%;
    margin-top: 50px;
    text-align: center;
    color: #101214;
    font-size: 18px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px){
        margin-top: 30px;
    }
`;

export const SubTitle = styled.p`
    margin: 0%;
    margin-top: 10px;
    text-align: center;
    color: #101214;
    font-size: 14px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_MEDIUM};
`;

export const AddButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 760px){
        flex-direction: row;
    }
`;

export const DeletedTextContainer = styled.p`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
`;

export const DeletedText = styled.p`
    margin: 0%;
    color: #f44343;
    font-size: 14px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    cursor: pointer;
`;

export const ButtonsGroup = styled.div`
    display: flex;
    gap: 10px;
`;

export const ModalButtonGroups = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const Noticecontainer = styled.div`
    background-color: #FFF1DB;
    border: solid 3px #AC6900;
    border-radius: 12px;
    padding: 50px;
    margin-top: 80px;
`;

export const NoticeTitle = styled.p`
    margin: 0%;
    text-align: center;
    font-size: 22px;
    margin-bottom: 50px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
`;

export const TextNotice = styled.p`
    margin: 0%;
    font-size: 16px;
    margin-bottom: 20px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_MEDIUM};
`;

export const JobsCardContainer = styled.div`
    margin-top: 50px;
`;

export const Jobcard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 40px 20px 40px;
`;

export const JobcardBody = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const JobRightSection = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

export const JobSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const JobDivider = styled.div`
    height: 50px;
    width: 3px;
    border-radius: 3px;
    background-color: #101214;
`;

export const JobEntitle = styled.p`
    margin: 0%;
`;

export const Jobcardfooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
`;

export const PublishDate = styled.p`
    margin: 0%;
`;

export const TextLink = styled.p`
    margin: 0%;
    cursor: pointer;
`;

