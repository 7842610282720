import { useScreenSize } from "@utilities/hooks";
import { FC } from "react";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";

const Container = styled.div`
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    top: 0%;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00000050;
`;

export const Loader:FC = ()=> {

    const { isMobile } = useScreenSize();

    return (
        <Container>
            <RotatingLines
                visible={true}
                ariaLabel="rotating-lines-loading"
                strokeColor="#fff"
                width={isMobile ? "60" : "100"}
            />
        </Container>
    )
}