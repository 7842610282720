import { FC, useCallback, useMemo, useState } from "react";
import { useAnalyticsPageTracking, useScreenSize, useTranslation } from "@utilities/hooks";
import { ColorRing } from 'react-loader-spinner';

import { SubMenu, TabNavigation, TestCorrectionModal, TestTools } from "@connectedComponents";
import { 
    Modal, ModalSubTitle, 
    ModalTitle, PageHeadSection, 
    SimpleButton, SimpleSelect,
} from "@components";
import { UserQCMSolution } from "@utilities/types";
import { useController } from "../useControllers";
import { Container, Section, Text, WarningText, WarningTextLabel} from "./styles";
import { useControllers } from "./useControllers";
import { TestCardComponent, TestSolutionCard } from "./components";

export const TalentAccount_TESTS:FC = ()=> {

    useAnalyticsPageTracking('TalentAccount_TESTS');
    const translate = useTranslation();
    const { itemsMenuNav } = useController();
    const { isDesktop } = useScreenSize();

    const { 
        isLoading,
        generateQcmIsOk,
        talentQCMs,
        talentQCMsResult,
        selectedQCM,
        filteredQCMs,
        filteredQCMResults,
        filterOptions,
        selectedTestFilter, 
        setSelectedTestFilter,
        handleValidateQcm,
        handleCloseContentModal,
        handleStartTalentEvaluation,
        setSelectedQCM,
        getHighestLevelWithHighScore,
    } = useControllers();

    const [selectedQCMSolution, setSelectedQCMSolution] = useState<UserQCMSolution | null>(null);
    
    const userLevel = useMemo(()=> getHighestLevelWithHighScore(), [getHighestLevelWithHighScore]);

    const renderLoader = useCallback(()=> (
        <ColorRing
            visible={true}
            height="25"
            width="25"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#fff', '#fff', '#fff', '#fff', '#fff']}
        />
    ), [])

    return (
        <TabNavigation customBG="#EFECE6">
            <>
                <SubMenu
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[1].label}
                />

                <Container>
                {
                    (talentQCMs.length > 0 || talentQCMsResult.length > 0) ? (
                        <Section style={{justifyContent: 'flex-start'}}>
                            <PageHeadSection>
                                <ModalTitle style={
                                    isDesktop ? {
                                        textAlign: 'center',
                                        marginTop: 30
                                    } 
                                    : {}
                                    }>
                                    Tests de compétences
                                </ModalTitle>

                                <ModalSubTitle style={isDesktop ? {textAlign: 'center'} : {}}>
                                    Mesurez et validez vos compétences grâce à une variété de tests adaptés à votre profil.
                                </ModalSubTitle>

                                {/* {
                                    userLevel && (
                                        <LevelSection>
                                            <SubTitle>
                                                Vous avez validez le niveau : 
                                            </SubTitle>
                                            <SimpleBadge>{userLevel}</SimpleBadge>
                                        </LevelSection>
                                    )
                                } */}

                                <SimpleSelect 
                                    placeholder="Filtré par : "
                                    value={selectedTestFilter}
                                    onChange={setSelectedTestFilter} 
                                    options={filterOptions}
                                    styles={{
                                        height: 43, 
                                        width: isDesktop ? '30%' : '100%',
                                        marginLeft: isDesktop ? '35%' : 0,
                                    }}                                    
                                />
                            </PageHeadSection>
                            {
                                filteredQCMs.map((el, i)=> {
                                    return (
                                        <TestCardComponent 
                                            index={i}
                                            test={el}
                                            setSelectedQCM={setSelectedQCM}
                                        />
                                    );
                                })
                            }

                            {
                                filteredQCMResults.map((el, i)=> {
                                    return (
                                        <TestSolutionCard 
                                            index={i}
                                            solution={el}
                                            setSelectedQCMSolution={setSelectedQCMSolution}
                                        />
                                    );
                                })
                            }
                        </Section>
                    ) : (
                        <Section>
                            <Text>
                                {
                                    isLoading ? 'Patientez quelques instants, le temps que le ou les tests se créent (vous pouvez quitter la page) 📚'
                                    : 'Passez un test de compétence personnel basé sur votre CV 🧠'
                                }
                            </Text>

                            <SimpleButton style={{paddingLeft: 20, paddingRight: 20}}
                                onClick={handleStartTalentEvaluation}
                            >
                                {isLoading ? renderLoader() : 'Générer un test'}
                            </SimpleButton>

                            {
                                !generateQcmIsOk && (
                                    <WarningText>
                                        Pour générer votre test de compétence, veuillez d’abord remplir les sections <WarningTextLabel>‘Informations personnelles’</WarningTextLabel>, <WarningTextLabel>‘Job recherché’</WarningTextLabel> et ajouter au moins une expérience dans <WarningTextLabel>‘Expériences’</WarningTextLabel>.
                                    </WarningText>
                                )
                            }
                        </Section>
                    )
                }
                </Container>

                <Modal
                    isVisible={!!selectedQCM}
                    onClose={handleCloseContentModal}
                >
                    <TestTools 
                        qcmElement={selectedQCM}
                        onValidateQCM={handleValidateQcm}
                    />
                </Modal>

                <TestCorrectionModal 
                    isVisible={!!selectedQCMSolution}
                    qcmSolution={selectedQCMSolution}
                    onClose={()=> setSelectedQCMSolution(null)}
                />
            </>
        </TabNavigation>
    )
}
