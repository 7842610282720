import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import { useAnalyticsPageTracking, useAppointmentDispatch, useScreenSize, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { Container, DatePickerCalendar, Icon, IconWrapper, JobOfferCard, Modal, ModalContainer, ModalTitle, OrganisationName, Page, TimeSlots } from "@components";
import { useController } from "../useControllers";
import { 
    ProposalContainer, 
    SubTitle, Title, 
    OrganisationProposal,
    Image,
    OrganisationDetails,
    OrganistionMessage,
    ModalSection,
    ModalSubSection,
} from "./styles";
import { ApplicationStatusEnum, IconNameEnum } from "@utilities/enums";
import { Matching } from "@utilities/types";

import DefaultImg from '@assets/images/defaultImg.png';
import { formatDate, formatTime } from "@utilities/functions";

export const TalentAccount_RecruitersProposals:FC = ()=> {

    useAnalyticsPageTracking('TalentAccount_RecruitersProposals');
    const translate = useTranslation();
    const { isMobile } = useScreenSize();
    const { itemsMenuNav, talentMatchings, talent } = useController();
    const {
        onTalentSelectedAppointmentRequest
    } = useAppointmentDispatch();

    const [selectedProposal, setSelectedProposal] = useState<Matching | null>(null);
    const [selectedDate, setSelectedDate] = useState<Date | undefined>();

    const modalStyles:CSSProperties = useMemo(()=> (
        { width: isMobile ? '100%' : '80%' }
    ), [isMobile]);

    const modalContainerStyle:CSSProperties = useMemo(()=> (
        { height: '90vh' }
    ), []);

    const filteredTalentMatchings = useMemo(()=> 
        talentMatchings.filter(el=> el.matching[0].applicationStatus === ApplicationStatusEnum.Interview
    ), [talentMatchings]);

    const enableDays = useMemo(()=> (
        selectedProposal?.appointment?.map(el=> new Date(el.event.start)) || []
    ), [selectedProposal]);

    const dayTimeSlots = useMemo(()=> {
        if(!(selectedDate && selectedProposal)) return [];

        const findDays = selectedProposal.appointment?.filter(el=> formatDate(el.event.start) === formatDate(selectedDate))
        if(findDays){
            return findDays.map(el=> (
                `${formatTime(el.event.start)} - ${formatTime(el.event.end)}`
            ))
        }

        return [];
    }, [selectedDate, selectedProposal]);

    const handleSelectedSlot = useCallback(async (timeSlot: string)=> {
        if(selectedDate && selectedProposal){
            const [timeSlotStart, timeSlotEnd] = timeSlot.split('-').map(String);
            const [startTimeSlotHoure, startTimeSlotMinute] = timeSlotStart.split(':').map(Number);
            const [endTimeSlotHoure, endTimeSlotMinute] = timeSlotEnd.split(':').map(Number);

            const newTimeSlotStartDate = new Date(selectedDate.getTime());
            newTimeSlotStartDate.setHours(startTimeSlotHoure, startTimeSlotMinute, 0, 0);

            const newTimeSlotEndDate = new Date(selectedDate.getTime());
            newTimeSlotEndDate.setHours(endTimeSlotHoure, endTimeSlotMinute, 0, 0);

            const findEvent = selectedProposal.appointment?.find(el=> (
                new Date(el.event.start).getTime() === newTimeSlotStartDate.getTime()
                && new Date(el.event.end).getTime() === newTimeSlotEndDate.getTime()
            ));

            if(findEvent && talent){
                await onTalentSelectedAppointmentRequest(findEvent._id!, talent.token!);
                setSelectedDate(undefined);
                setSelectedProposal(null);
            }
        }
    }, [
        talent,
        selectedDate, selectedProposal,
        onTalentSelectedAppointmentRequest,
    ]);

    const handleClose = useCallback(()=> {
        setSelectedProposal(null);
        setSelectedDate(undefined);
    }, [])

    const renderOrganisationProposal = useCallback((proposal: Matching, index: number)=> {

        const iconPosition:CSSProperties = {
            position: 'absolute',
            bottom: 5, right: 5,
            width: isMobile ? 35 : 45,
            height: isMobile ? 35 : 45,
        };

        return (
            <OrganisationProposal key={index}>
                <Image src={DefaultImg} />
                <OrganisationDetails>
                    <OrganisationName>{proposal.organisation?.organisationName}</OrganisationName>
                    <OrganistionMessage>
                        Vous avez une nouvelle proposition de rendez-vous d’un recruteur 👋, pour un entretien.
                    </OrganistionMessage>
                </OrganisationDetails>

                <IconWrapper 
                    style={iconPosition}
                    onClick={()=> setSelectedProposal(proposal)}
                >
                    <Icon
                        iconName={IconNameEnum.arrowRight}
                        color="#fff"
                        size={isMobile ? '1x' : 'lg'}
                    />
                </IconWrapper>
            </OrganisationProposal>
        )
    }, [isMobile]);

    return (
        <TabNavigation customBG="#EFECE6">
            <Page>
                <SubMenu
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[2].label}
                />

                <Container>
                    <Title>Propositions des recruteurs</Title>
                    <SubTitle>Votre profil intéresse les recruteurs ! Acceptez ou déclinez leur proposition avant qu’elle n’expire.</SubTitle>
                
                    <ProposalContainer>
                        {
                            filteredTalentMatchings.map((el, i)=> renderOrganisationProposal(el, i))
                        }
                    </ProposalContainer>
                </Container>

                <Modal
                    isVisible={!!selectedProposal}
                    onClose={handleClose}
                    modalContainerStyles={modalStyles}
                >
                    <ModalContainer style={modalContainerStyle}>
                        <ModalSection>
                            <DatePickerCalendar 
                                enablesDays={enableDays} 
                                selectedDate={selectedDate}
                                setSelectedDate={setSelectedDate}
                            />

                            {
                                selectedDate && (
                                    <ModalSubSection>
                                        <ModalTitle>{formatDate(selectedDate)}</ModalTitle>
                                        <TimeSlots 
                                            timeSlots={dayTimeSlots} 
                                            onTimeSlotSelect={handleSelectedSlot}  
                                        />
                                    </ModalSubSection>
                                )
                            }
                        </ModalSection>

                        {
                            selectedProposal && (
                                <JobOfferCard 
                                    isOnlyReadMode
                                    job={selectedProposal.postDetails!}
                                />
                            )
                        }
                    </ModalContainer>
                </Modal>
            </Page>
        </TabNavigation>
    )
}
