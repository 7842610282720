import { combineReducers } from '@reduxjs/toolkit';
import {
  talentReducers,
  recruiterReducers,
  appointmentReducer,
  postReducers,
  spontaneousDemandReducers,
  organisationReducer,
} from './reducers';

// FR: Combine plusieurs réducteurs pour créer un réducteur racine unique.
// EN: Combine multiple reducers to create a single root reducer
const rootReducer = combineReducers({
  talent: talentReducers,
  recruiter: recruiterReducers,
  post: postReducers,
  spontaneousDemand: spontaneousDemandReducers,
  appointment: appointmentReducer,
  organisation: organisationReducer,
});

export default rootReducer;