import { FC, useCallback } from "react";
import { Details, IconWrapper, Image, TestCard, TestCardBadge, TestCardSubTitle, TestCardTitle, TestSubCard } from "../styles";
import { Icon } from "@components";
import { IconNameEnum } from "@utilities/enums";
import { TEST_QCM } from "@utilities/types";
import { decryptString, getLevelColors } from "@utilities/functions";

import DefaultImg from '@assets/images/defaultImg.png';
import { useControllers } from "../useControllers";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    index: number
    test: TEST_QCM
    setSelectedQCM: (e:TEST_QCM)=> void
}

export const TestCardComponent:FC<Props> = ({
    index,
    test,
    setSelectedQCM,
})=> {

    const { 
        talent,
    } = useControllers();

    const { isMobile } = useScreenSize();

    const { color, bgColor } = getLevelColors(test.level);
    
    const handleSelectedQcm = useCallback(()=> {
        if(test?.qcmHashed && talent?.token){
            const qcm = JSON.parse(decryptString(test?.qcmHashed, talent?.token));
            setSelectedQCM({
                ...test,
                qcm: qcm,
            })
        }
    }, [talent, test, setSelectedQCM]);

    return (
        <TestCard 
            key={index} color={color} bgColor={bgColor}
            onClick={handleSelectedQcm}
        >
            <TestCardBadge color={color}>{test.level}</TestCardBadge>
            <TestSubCard>
                <Image
                    src={DefaultImg}
                />
                <Details>
                    <TestCardTitle>
                        {
                            isMobile ? 'Évaluez-vous avec ce test'
                            : 'Évaluez-vous avec ce test personnalisé basé sur votre CV'
                        }
                    </TestCardTitle>
                    
                    <TestCardSubTitle>
                        📚 {test.entitle}
                    </TestCardSubTitle>
                </Details>

                <IconWrapper>
                    <Icon
                        iconName={IconNameEnum.arrowRight}
                        color="#fff"
                        size={isMobile ? '1x' : 'xl'}
                    />
                </IconWrapper>
            </TestSubCard>
        </TestCard>
    )
}