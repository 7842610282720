import React, { useCallback, useState } from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation, TestGeneratorModal } from "@connectedComponents";
import { useController } from "../useControllers";
import { Container, Page, SimpleButton } from "@components";
import { StackEnum } from "@utilities/enums";

export const JobOffersTests:React.FC = ()=> {

    useAnalyticsPageTracking('JobOffersTests');
    const translate = useTranslation();
    const { itemsMenuNav, handleSaveJobTest } = useController();

    const [isVisible, setIsVisible] = useState<boolean>(false);

    const onToggleIsvisible = useCallback(()=> {
        setIsVisible(!isVisible);
    }, [isVisible]);

    return (
        <TabNavigation 
            customBG="#EFECE6"
            activeStack={StackEnum.JobOfferManagement}
        >
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[1].label}
                />

                <Container>
                    <SimpleButton onClick={onToggleIsvisible}>
                        Creer un test
                    </SimpleButton>
                </Container>

                <TestGeneratorModal
                    isVisible={isVisible}
                    isDisableSelectedTest
                    onClose={onToggleIsvisible}
                    onSaveTest={handleSaveJobTest}
                />
            </Page>
        </TabNavigation>
    )
}
