import { CSSProperties, FC, useCallback, useMemo, useState } from "react";
import {   
    Location, 
    ProfileSectionBody,
} from "../styles";
import { 
    BadgeWithIcon,
    BadgeWrapper,
    CardPage,
    CardPageSubTitle,
    CardPageTitle,
    DescriptionBadge, 
    FixedModalFooter, 
    Input, InputLabel, 
    InputWrapper, 
    LocalisationAutoComplete, 
    Modal, 
    ModalContainer, 
    ModalSubTitle, 
    ModalTitle, 
    SearchBar, 
    SimpleBadge, SimpleButton,
    SimpleSelect,
    InfoLabel, 
    InfosWrapper,
} from "@components";
import { useScreenSize } from "@utilities/hooks";
import { enumToStringArray } from "@utilities/functions";
import { EnumContratType, ProfessionsEnum, TeleWorkingTypeEnum } from "@utilities/enums";

import { CV } from "@utilities/types";

interface Props {
    simpleButtonStyle: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    talentCV: CV | null
    onSaveTalentCVRequest: (cv: CV)=> void
}

export const JobFind:FC<Props> = ({
    simpleButtonStyle,
    simpleButtonOutlineStyle,
    talentCV,
    onSaveTalentCVRequest,
})=> {

    const { isMobile } = useScreenSize();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [postNames, setPostNames] = useState<string[]>([]);
    const [contratTypes, setContratTypes] = useState<string[]>([]);
    const [teleWoring, setTeleWoring] = useState<string[]>([]);
    const [localisation, setLocalisation] = useState<string>('');
    const [level, setLevel] = useState<string>('');
    const [minSalary, setMinSalary] = useState<string>('');
    const [details, setDetails] = useState<string>('');

    const postNameList = useMemo(()=> (enumToStringArray(ProfessionsEnum)), []);

    const formationLevelList = useMemo(()=> [
        {
            label: '0-1 an',
            value: '0-1 an',
        },
        {
            label: '1-3 ans',
            value: '1-3 ans',
        },
        {
            label: '3-5 ans',
            value: '3-5 ans',
        },
        {
            label: '5-10 ans',
            value: '5-10 ans',
        },
        {
            label: '+10 ans',
            value: '+10 ans',
        }
    ], []);

    const teleWorkingTypeEnumList = useMemo(()=> {
        let result:{label: string, value: string}[] = [];
        let tmp = enumToStringArray(TeleWorkingTypeEnum);
        tmp.map(el=> {
            result.push({
                label: el,
                value: el,
            })
        });

        return result;
    }, []);

    const contratTypeList = useMemo(()=> {
        let result:{label: string, value: string}[] = [];
        let tmp = enumToStringArray(EnumContratType);
        tmp.map(el=> {
            result.push({
                label: el,
                value: el,
            })
        });

        return result;
    }, []);

    const handleUpdatedLocalisation = useCallback((selectedLocalisation: string)=> {
        setLocalisation(selectedLocalisation);
    }, []);

    const handleAddPostName = useCallback((postname: string)=> {
        setPostNames(e => {
            if (!e.includes(postname)) {
                return [...e, postname];
            }
            return e;
        });
    }, []);

    const handleSearchPostName = useCallback((query: string) => {
        handleAddPostName(query);
    }, [handleAddPostName]);

    const handleRemovePostName= useCallback((postName: string)=> {
        setPostNames(e => e.filter(element=> element !== postName));
    }, []);

    const handleAddWorkType = useCallback((workType: string)=> {
        setTeleWoring(e => {
            if (!e.includes(workType)) {
                return [...e, workType];
            }
            return e;
        });
    }, []);
    const handleRemoveWorkType= useCallback((workType: string)=> {
        setTeleWoring(e => e.filter(element=> element !== workType));
    }, []);

    const handleAddContratType = useCallback((contratType: string)=> {
        setContratTypes(e => {
            if (!e.includes(contratType)) {
                return [...e, contratType];
            }
            return e;
        });
    }, []);
    const handleRemoveContratType= useCallback((contratType: string)=> {
        setContratTypes(e => e.filter(element=> element !== contratType));
    }, []);

    const handleVisible = useCallback(()=> {
        if(talentCV){
            setPostNames(talentCV.criteria?.jobTypes || []);
            setContratTypes(talentCV.criteria?.contratTypes || []);
            setTeleWoring(talentCV.criteria?.teleWoring || []);
            setLocalisation(talentCV.criteria?.localisation || '');
            setLevel(talentCV.criteria?.level || '');
            setMinSalary(String(talentCV.criteria?.minSalary) || '');
            setDetails(talentCV.criteria?.details || '');
            setIsVisible(true);
        }
    }, [talentCV]);

    const handleClose = useCallback(()=> {
        setPostNames([]);
        setContratTypes([]);
        setTeleWoring([]);
        setLocalisation('');
        setLevel('');
        setMinSalary('');
        setDetails('');
        setIsVisible(false);
    }, []);

    const handleSave = useCallback(()=> {
        onSaveTalentCVRequest({
            ...talentCV!,
            criteria: {
                ...(postNames.length > 0 ? { jobTypes: postNames } : {}),
                ...(localisation ? { localisation } : {}),
                ...(contratTypes.length > 0 ? { contratTypes } : {}),
                ...(teleWoring.length > 0 ? { teleWoring } : {}),
                ...(level ? { level } : {}),
                ...(minSalary ? { minSalary: Number(minSalary) } : {}),
                ...(details ? { details } : {}),
            }
        });
        handleClose();
    }, [
        contratTypes, details, 
        level, localisation, minSalary, 
        postNames, talentCV, teleWoring,
        onSaveTalentCVRequest,
        handleClose,
    ]);

    return (
        <>
            <CardPage>
                <CardPageTitle>
                    Job recherché
                </CardPageTitle>
                <CardPageSubTitle>
                    Dites-en plus aux recruteurs sur le job que vous recherchez.
                </CardPageSubTitle>

                <SimpleButton 
                    onClick={handleVisible}
                    style={simpleButtonStyle}
                >
                    Modifier
                </SimpleButton>

                {
                    talentCV?.criteria && (
                        <ProfileSectionBody>
                            {
                                talentCV.criteria.jobTypes &&
                                (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Intitulé de poste
                                        </InfoLabel>
                                        {
                                            talentCV.criteria.jobTypes.length > 0 && (
                                                <BadgeWrapper>
                                                    {
                                                        talentCV.criteria.jobTypes.map((el, i)=> {
                                                            return (
                                                                <SimpleBadge key={i} >
                                                                    {el}
                                                                </SimpleBadge>
                                                            )
                                                        })
                                                    }
                                                </BadgeWrapper>
                                            )
                                        }
                                    </InfosWrapper>
                                )
                            }

                            {
                                talentCV.criteria.localisation && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Localisation
                                        </InfoLabel>
                                        <Location style={{marginTop: 10}}>
                                            📍 {talentCV.criteria.localisation}
                                        </Location>
                                    </InfosWrapper>
                                )
                            }
                            
                            {
                                talentCV.criteria.contratTypes && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Type de contrat
                                        </InfoLabel>
                                        {
                                            talentCV.criteria.contratTypes.length > 0 && (
                                                <BadgeWrapper>
                                                    {
                                                        talentCV.criteria.contratTypes.map((el, i)=> {
                                                            return (
                                                                <SimpleBadge key={i} >
                                                                    {el}
                                                                </SimpleBadge>
                                                            )
                                                        })
                                                    }
                                                </BadgeWrapper>
                                            )
                                        }
                                    </InfosWrapper>
                                )
                            }
                            
                            {
                                talentCV.criteria.teleWoring && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Télétravail
                                        </InfoLabel>
                                        {
                                            talentCV.criteria.teleWoring.length > 0 && (
                                                <BadgeWrapper>
                                                    {
                                                        talentCV.criteria.teleWoring.map((el, i)=> {
                                                            return (
                                                                <SimpleBadge key={i} >
                                                                    {el}
                                                                </SimpleBadge>
                                                            )
                                                        })
                                                    }
                                                </BadgeWrapper>
                                            )
                                        }
                                    </InfosWrapper>
                                )   
                            }

                            {
                                talentCV.criteria.level && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Niveau d’expérience
                                        </InfoLabel>

                                        <BadgeWrapper>
                                            <SimpleBadge>
                                                ⚡️ {talentCV.criteria.level}
                                            </SimpleBadge>
                                        </BadgeWrapper>
                                    </InfosWrapper>       
                                )
                            }

                            {
                                talentCV.criteria.minSalary && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Salaire minimum
                                        </InfoLabel>

                                        <BadgeWrapper>
                                            <SimpleBadge>
                                                💰️ {talentCV.criteria.minSalary} F par an 
                                            </SimpleBadge>
                                        </BadgeWrapper>
                                    </InfosWrapper>       
                                )
                            }

                            {
                                talentCV.criteria.details && (
                                    <InfosWrapper>
                                        <InfoLabel>
                                            Exprimez librement ce que vous pensez 
                                        </InfoLabel>

                                        <BadgeWrapper>
                                            <DescriptionBadge>
                                                {talentCV.criteria.details}
                                            </DescriptionBadge>
                                        </BadgeWrapper>
                                    </InfosWrapper>       
                                )
                            }
                        </ProfileSectionBody>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isVisible}
                onClose={handleClose}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Job recherché
                        </ModalTitle>
                        <ModalSubTitle>
                            Dites-en plus aux recruteurs sur le job que vous recherchez
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                ✨ Intitulé de poste
                            </InputLabel>
                            <SearchBar 
                                placeholder="ex : Product Manager"
                                suggestions={postNameList} 
                                onSearch={handleSearchPostName}
                            />
                            {
                                postNames.length > 0 && (
                                    <BadgeWrapper>
                                        {
                                            postNames.map((el, i)=> {
                                                return (
                                                    <BadgeWithIcon
                                                        key={i}
                                                        onIconPress={()=> handleRemovePostName(el)}
                                                    >
                                                        {el}
                                                    </BadgeWithIcon>
                                                )
                                            })
                                        }
                                    </BadgeWrapper>
                                )
                            }
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                📍 Localisation 
                            </InputLabel>
                            <LocalisationAutoComplete 
                                onSelectedLocalisation={handleUpdatedLocalisation}
                            />
                            {
                                localisation && (
                                    <Location>
                                        📍 {localisation}
                                    </Location>
                                )
                            }
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                💼 Type de contrat
                            </InputLabel>
                            <SimpleSelect
                                placeholder="Sélectionnez"
                                options={contratTypeList}
                                value=''
                                onChange={e=> handleAddContratType(e)}
                            />
                            {
                                contratTypes.length > 0 && (
                                    <BadgeWrapper>
                                        {
                                            contratTypes.map((el, i)=> {
                                                return (
                                                    <BadgeWithIcon
                                                        key={i}
                                                        onIconPress={()=> handleRemoveContratType(el)}
                                                    >
                                                        {el}
                                                    </BadgeWithIcon>
                                                )
                                            })
                                        }
                                    </BadgeWrapper>
                                )
                            }
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                🏠 Télétravail
                            </InputLabel>
                            <SimpleSelect
                                placeholder="Sélectionnez"
                                options={teleWorkingTypeEnumList}
                                value=''
                                onChange={e=> handleAddWorkType(e)}
                            />
                            {
                                teleWoring.length > 0 && (
                                    <BadgeWrapper>
                                        {
                                            teleWoring.map((el, i)=> {
                                                return (
                                                    <BadgeWithIcon
                                                        key={i}
                                                        onIconPress={()=> handleRemoveWorkType(el)}
                                                    >
                                                        {el}
                                                    </BadgeWithIcon>
                                                )
                                            })
                                        }
                                    </BadgeWrapper>
                                )
                            }
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                ⚡️ Niveau d’expérience
                            </InputLabel>

                            <SimpleSelect
                                placeholder="Sélectionnez"
                                options={formationLevelList}
                                value={level}
                                onChange={setLevel}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                💰 Salaire brut minimum par an
                            </InputLabel>
                            <Input 
                                placeholder="ex : 60000"
                                value={minSalary}
                                onChange={setMinSalary}
                            />
                        </InputWrapper>

                        {/* <InputWrapper>
                            <InputLabel>
                                💸 Devise
                            </InputLabel>
                            <DropdownSelect 
                                placeholder="Sélectionnez"
                                elements={telework}
                                onSelectedElement={e=> {}}
                            />
                        </InputWrapper> */}

                        <InputWrapper>
                            <InputLabel>
                                🕊️ Exprimez librement ce que vous cherchez
                            </InputLabel>
                            <Input 
                                inputType='textarea'
                                placeholder="Ajoutez des précisions ici..."
                                value={details}
                                onChange={setDetails}
                            />
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={handleClose} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}