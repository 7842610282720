export function getColorByNumber(num: number) {
  if (typeof num !== 'number' || isNaN(num) || num < 0 || num > 100) {
    return {
      bg: '#ffffff',
      border: '#B4B4B4',
    };
  }

  const ranges = [
    { max: 25, bg: '#FFD1D1', border: '#FF6666' },
    { max: 50, bg: '#FFE5CC', border: '#FF9933' },
    { max: 75, bg: '#FFF8CC', border: '#FFCC33' },
    { max: 100, bg: '#EAFFDD', border: '#66CC66' },
  ];

  for (const range of ranges) {
    if (num <= range.max) {
      return {
        bg: range.bg,
        border: range.border,
      };
    }
  }

  // Cas par défaut (ne devrait pas être atteint)
  return {
    bg: '#ffffff',
    border: '#B4B4B4',
  };
}