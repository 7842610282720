import { EnumJobCategory } from "@utilities/enums"
import { ErrorType } from "./error.type"

export interface TEST_QCM {
    _id?: string
    _createdBy_ID: string
    _isOrganisationTest?: boolean
    _postID?: string
    entitle: string
    jobCategory?: EnumJobCategory[]
    time: number
    level: EnumUserLevel
    qcm: qcmTestType[]
    minimumOfCorrectAnswer?: number
    qcmHashed?: string
}

export interface UserQCMSolution {
    _id: string
    _qcmID: string
    _userID: string
    level: EnumUserLevel
    entitle: string
    score: number
    qcm: qcmTestType[]
}

export interface TEST_QCMInitialStateType {
    talentQCMs: TEST_QCM[]
    talentQCMsResult: UserQCMSolution[]
    getTalentQCMsFailure: ErrorType | null
    getTalentQCMsResultFailure: ErrorType | null
    generateTestSuccess: boolean
    generateTestFailure: ErrorType | null
    userTestFixSuccess: boolean
    userTestFixFailure: ErrorType | null
}

export enum EnumUserLevel {
    debutant = 'Débutant',
    intermediaire = 'Intermédiaire',
    confirme = 'Confirmé',
}

export type qcmTestType = {
    question: string
    reponse_a: string
    reponse_b: string
    reponse_c: string
    reponse_d: string
    solution?: string
    selected_response?: string
    explication?: string
}
