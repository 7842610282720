import { useEffect, useState } from 'react';

export const useFetchInterceptor = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeRequests, setActiveRequests] = useState<number>(0);

    useEffect(() => {
        const originalFetch = window.fetch;

        window.fetch = async (...args) => {
            // console.log('Requête envoyée avec fetch:', ...args);
            setActiveRequests((prev) => prev + 1);  // Incrémente le compteur de requêtes actives
            setIsLoading(true);

            try {
                const response = await originalFetch(...args);
                // console.log('Réponse reçue avec fetch:', response);
                return response;
            } finally {
                setActiveRequests((prev) => {
                    const newActiveRequests = prev - 1;
                    if (newActiveRequests === 0) {
                        setIsLoading(false); // Plus de requêtes en cours, on désactive le chargement
                    }
                    return newActiveRequests;
                });
            }
        };

        // Nettoyage lors du démontage
        return () => {
            window.fetch = originalFetch;
        };
    }, []);

    return { isLoading };
};
