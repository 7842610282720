import React, { useMemo, useState } from "react";
import { useAnalyticsPageTracking, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { CalendarEvents, Container, Page } from "@components";
import { StackEnum } from "@utilities/enums";
import { formatDate, formatTime } from "@utilities/functions";

export const JobOffersAppointment:React.FC = ()=> {

    useAnalyticsPageTracking('JobOffersAppointment');
    const translate = useTranslation();
    const { 
        itemsMenuNav,
        recruiterAppointment,
        handleAddNewTimeSlot,
        handleDeletedTimeSlot,
    } = useController();

    const [selectedDate, setSelectedDate] = useState<string>('');

    const recruiterEvents = useMemo(()=> (
        recruiterAppointment.map(el=> (
            {
                ...el.event,
                start: new Date(el.event.start),
                end: new Date(el.event.end),
            }
        ))
    ), [recruiterAppointment]);

    const selectedSlots = useMemo(()=> {
        const events = recruiterEvents.filter(el=> (
            formatDate(el.start) === selectedDate
            && el.isEnabled
        ));
        events.sort((a, b) => a.start.getTime() - b.start.getTime());

        return events.map(el=> (
            `${formatTime(el.start)} - ${formatTime(el.end)}`
        ));
    }, [
        selectedDate,
        recruiterEvents,
    ]);

    return (
        <TabNavigation 
            customBG="#EFECE6"
            activeStack={StackEnum.JobOfferManagement}
        >
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[2].label}
                />

                <Container>
                    <CalendarEvents 
                        myEventsList={recruiterEvents}
                        timeSlots={selectedSlots}
                        onAddNewTimeSlot={handleAddNewTimeSlot}
                        onDeletedTimeSlot={handleDeletedTimeSlot}
                        onSelectedDate={setSelectedDate}
                    />
                </Container>
            </Page>
        </TabNavigation>
    )
}
