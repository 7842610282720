import React, { CSSProperties, useMemo } from "react";
import { useAnalyticsPageTracking, useScreenSize, useTranslation } from "@utilities/hooks";
import { SubMenu, TabNavigation } from "@connectedComponents";
import { useController } from "../useControllers";
import { 
    CardPage, CardPageTitle, 
    Container, 
    FixedModalFooter, InfoLabel, 
    InfosWrapper, Modal, ModalContainer, 
    ModalSubTitle, 
    ModalTitle, 
    Page, SimpleBadge, SimpleButton,
} from "@components";

import AvatarDefault from '@assets/images/defaultImg.png';
import { Avatar, Column, Job, ProfileSection, UserName } from "./styles";

export const RecruiterAccount:React.FC = ()=> {

    useAnalyticsPageTracking('RecruiterAccount');
    const translate = useTranslation();
    const { isMobile } = useScreenSize();
    const {  
        itemsMenuNav,
        recruiter,
        isOpenChangeInfosModal,
        isOpenChangeContactsModal,
        isOpenChangePasswordModal,
        toggleIsOpenChangeInfosModal,
        toggleIsOpenChangeContactsModal,
        toggleIsOpenChangePasswordModal,
        handleSaveInfos,
        handleSaveContacts,
        handleSavePassword,
    } = useController();

    const simpleButtonStyles:CSSProperties = useMemo(()=> (
        {
            position: 'absolute',
            top: 30,
            right: 30,
        }
    ), []);

    const simpleButtonOutlineStyle:CSSProperties = useMemo(()=> (
        {
            backgroundColor: 'transparent',
            color: '#101214',
            border: 'solid 1px #101214',
        }
    ), []);

    return (
        <TabNavigation customBG="#EFECE6">
            <Page>
                <SubMenu 
                    navMenu={itemsMenuNav}
                    active={itemsMenuNav[0].label}
                />

                <Container>
                    <CardPage>
                        <CardPageTitle>Profil</CardPageTitle>

                        <SimpleButton style={simpleButtonStyles} onClick={toggleIsOpenChangeInfosModal}>
                            Modifier
                        </SimpleButton>

                        <ProfileSection>
                            <Avatar src={AvatarDefault} />

                            <Column>
                                <UserName>{recruiter?.firstname} {recruiter?.lastname}</UserName>
                                <Job>Responsable RH</Job>
                            </Column>
                        </ProfileSection>
                    </CardPage>

                    <CardPage>
                        <CardPageTitle>Informations de contact</CardPageTitle>

                        <SimpleButton style={simpleButtonStyles} onClick={toggleIsOpenChangeContactsModal}>
                            Modifier
                        </SimpleButton>

                        <InfosWrapper>
                            <InfoLabel>＠ Email</InfoLabel>
                            <SimpleBadge>{recruiter?.mail}</SimpleBadge>
                        </InfosWrapper>

                        {
                            recruiter?.phone && (
                                <InfosWrapper>
                                    <InfoLabel>📞 Téléphone</InfoLabel>
                                    <SimpleBadge>{recruiter.phone}</SimpleBadge>
                                </InfosWrapper>
                            )
                        }
                    </CardPage>

                    <CardPage>
                        <CardPageTitle>Sécurité</CardPageTitle>

                        <SimpleButton style={simpleButtonStyles} onClick={toggleIsOpenChangePasswordModal}>
                            Modifier
                        </SimpleButton>

                        <InfosWrapper>
                            <InfoLabel>🔐 Mot de passe</InfoLabel>
                            <SimpleBadge>**********</SimpleBadge>
                        </InfosWrapper>
                    </CardPage>
                </Container>

                <Modal
                    isVisible={isOpenChangeInfosModal}
                    onClose={toggleIsOpenChangeInfosModal}
                >
                    <>
                        <ModalContainer>
                            <ModalTitle>
                                Profil
                            </ModalTitle>

                            <ModalSubTitle>
                                Modifications de mes informations
                            </ModalSubTitle>
                        </ModalContainer>

                        <FixedModalFooter>
                            <SimpleButton onClick={handleSaveInfos}>
                                Enregistrer
                            </SimpleButton>

                            <SimpleButton onClick={toggleIsOpenChangeInfosModal} style={simpleButtonOutlineStyle}>
                                Annuler
                            </SimpleButton>
                        </FixedModalFooter>
                    </>
                </Modal>

                <Modal
                    isVisible={isOpenChangeContactsModal}
                    onClose={toggleIsOpenChangeContactsModal}
                >
                    <>
                        <ModalContainer>
                            <ModalTitle>
                                Contacts
                            </ModalTitle>

                            <ModalSubTitle>
                                Modifications de mes informations de contacts
                            </ModalSubTitle>
                        </ModalContainer>

                        <FixedModalFooter>
                            <SimpleButton onClick={handleSaveContacts}>
                                Enregistrer
                            </SimpleButton>

                            <SimpleButton onClick={toggleIsOpenChangeContactsModal} style={simpleButtonOutlineStyle}>
                                Annuler
                            </SimpleButton>
                        </FixedModalFooter>
                    </>
                </Modal>

                <Modal
                    isVisible={isOpenChangePasswordModal}
                    onClose={toggleIsOpenChangePasswordModal}
                >
                    <>
                        <ModalContainer>
                            <ModalTitle>
                                Sécurité
                            </ModalTitle>

                            <ModalSubTitle>
                                Modifications du mot de passe
                            </ModalSubTitle>
                        </ModalContainer>

                        <FixedModalFooter>
                            <SimpleButton onClick={handleSavePassword}>
                                Enregistrer
                            </SimpleButton>

                            <SimpleButton onClick={toggleIsOpenChangePasswordModal} style={simpleButtonOutlineStyle}>
                                Annuler
                            </SimpleButton>
                        </FixedModalFooter>
                    </>
                </Modal>
            </Page>
        </TabNavigation>
    )
}
