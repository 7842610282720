import { FontFamilyEnum } from "@utilities/enums";
import { CSSProperties, FC } from "react";
import styled from "styled-components";

interface Props {
    placeholder: string
    value: string
    onChange: (e: string)=> void
    options: Option[]
    styles?: CSSProperties
    bgColor?: string
    color?: string
    disabled?: boolean
}

interface Option {
    label: string
    value: string
}

const SelectWrapper = styled.div<{bgColor?: string}>`
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px ${props=> props.bgColor || '#F5F4F2'};
    background-color: ${props=> props.bgColor || '#ffffff'};
    display: flex;
    align-items: center;

    @media (min-width: 760px){
        height: 48px;
        border-radius: 12px;
    }
`;
const Select = styled.select<{color?: string}>`
    flex: 1;
    height: 38px;
    border: none;
    outline: none;
    background-color: transparent;
    color: ${props=> props.color || '#101214'};
    font-size: 12px;
    padding-left: 14px;
    padding-right: 14px;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};

    ::placeholder {
        color: #b4b4b4;
    }

    @media (min-width: 760px){
        height: 46px;
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
        font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
    }
`;

export const SimpleSelect:FC<Props> = ({ 
    placeholder,
    value,
    onChange,
    options,
    styles,
    bgColor,
    color,
    disabled = false,
 })=> {

    return (
        <SelectWrapper style={styles} bgColor={bgColor}>
            <Select 
                disabled={disabled}
                value={value}
                onChange={e=> {
                    if(e.target.value !== placeholder){
                        onChange(e.target.value)
                    }
                }}
                color={color}
            >
                <option selected value={placeholder}>{placeholder}</option>

                {
                    options.map((option, i)=> {
                        return (
                            <option key={i} value={option.value}>{option.label}</option>
                        )
                    })
                }
            </Select>
        </SelectWrapper>
    )
}