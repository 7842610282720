import { CSSProperties, FC, useCallback, useMemo } from "react";
import { Title } from '../../styles';
import { useScreenSize } from "@utilities/hooks";
import { JobOfferDescription, JobOfferFAQ, JobOfferSettings } from "./components";
import { 
    AnimatedGradianButton, CardPage, 
    CardPageTitle, Container, Icon, 
    InfosWrapper, SimpleButton,
} from "@components";
import { IconNameEnum } from "@utilities/enums";
import { Props } from "./interface";

export const CreateJobOffer:FC<Props> = ({
    startDate, teleworking, workingTime, conductOfInterviews, 
    contratType,  department, entitle, experienceLevel, faq,
    postDescription, profileSought, schoolLevel, sector,
    maxSalary, minSalary, office, 
    onSaveJobOffer, handleCloseForm, 
    setWorkingTime, deletedFaq, addFaq,
    handleUpdatedFaqQuestion, handleUpdatedFaqResponse, 
    setConductOfInterviews, setContratType, setDepartment, 
    setEntitle, setExperienceLevel, setMaxSalary, setMinSalary, 
    setOffice, setPostDescription, setProfileSought, 
    setSchoolLevel, setSector, setStartDate, setTeleworking, 
})=> {

    const { isMobile } = useScreenSize();

    const buttonStyles:CSSProperties = useMemo(()=> (
        {
            color: '#101214',
            backgroundColor: '#F4F3EF',
            position: 'absolute',
            zIndex: 9,
            left: isMobile ? 10 : 20,
            top: isMobile ? 70 : 100,
        }
    ), [isMobile]);

    const cardPageStyles:CSSProperties = useMemo(()=> (
        {
            marginTop: 50, 
            background: '#F4F3EF'
        }
    ), []);

    const renderJobDescription = useCallback(()=> (
        <JobOfferDescription
            cardPageStyles={cardPageStyles}
            entitle={entitle}
            setEntitle={setEntitle}
            sector={sector}
            setSector={setSector}
            office={office}
            setOffice={setOffice}
            department={department}
            setDepartment={setDepartment}
            postDescription={postDescription}
            setPostDescription={setPostDescription}
            profileSought={profileSought}
            setProfileSought={setProfileSought}
            conductOfInterviews={conductOfInterviews}
            setConductOfInterviews={setConductOfInterviews}
            schoolLevel={schoolLevel}
            setSchoolLevel={setSchoolLevel}
            experienceLevel={experienceLevel}
            setExperienceLevel={setExperienceLevel}
        />
    ), [
        cardPageStyles,
        conductOfInterviews, department, entitle, 
        experienceLevel, office, postDescription, 
        profileSought, schoolLevel, sector, 
        setConductOfInterviews, setDepartment, 
        setEntitle, setExperienceLevel, setOffice, 
        setPostDescription, setProfileSought, 
        setSchoolLevel, setSector,
    ]);

    const renderJobSettings = useCallback(()=> (
        <JobOfferSettings
            cardPageStyles={cardPageStyles}
            contratType={contratType}
            setContratType={setContratType}
            startDate={startDate} 
            setStartDate={setStartDate}
            teleworking={teleworking}
            setTeleworking={setTeleworking}
            workingTime={workingTime}
            setWorkingTime={setWorkingTime}
            minSalary={minSalary}
            setMinSalary={setMinSalary}
            maxSalary={maxSalary}
            setMaxSalary={setMaxSalary}
        />
    ), [
        cardPageStyles, contratType, maxSalary, minSalary, 
        startDate, teleworking, workingTime,
        setContratType, setMaxSalary, setMinSalary, 
        setStartDate, setTeleworking, setWorkingTime,
    ]);

    const renderJobFAQ = useCallback(()=> (
        <JobOfferFAQ 
            cardPageStyles={cardPageStyles}
            faq={faq}
            addFaq={addFaq}
            handleUpdatedFaqQuestion={handleUpdatedFaqQuestion}
            handleUpdatedFaqResponse={handleUpdatedFaqResponse}
            deletedFaq={deletedFaq}
        />
    ), [
        addFaq, cardPageStyles, deletedFaq, 
        faq, handleUpdatedFaqQuestion, 
        handleUpdatedFaqResponse,
    ]);

    return (
        <Container style={{position: 'relative'}}>
            <Title>Rédaction de l’offre</Title>            
            
            <SimpleButton style={buttonStyles} onClick={handleCloseForm}>
                <Icon iconName={IconNameEnum.arrowLeft} color="#101214" /> Retour
            </SimpleButton>

            {renderJobDescription()}

            {renderJobSettings()}
            
            {renderJobFAQ()}

            <CardPage style={cardPageStyles}>
                <CardPageTitle>Vous y êtes presque !</CardPageTitle>

                <InfosWrapper style={{alignItems: 'center'}}>
                    <AnimatedGradianButton onClick={onSaveJobOffer}>
                        Enregistrer et prévisualiser votre offre
                        <Icon iconName={IconNameEnum.arrowRight} color="#fff" />
                    </AnimatedGradianButton>
                </InfosWrapper>
            </CardPage>
        </Container>
    )
}