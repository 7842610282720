import { CSSProperties, FC } from "react";
import { 
    DisplayAllQuestion, ModalContainer, 
    ModalQuestion, QuestionSection, 
    QuestionTxt, QuestionWrapper,
} from "../styles";
import { AnimatedGradianButton, SimpleBadge, SimpleButton } from "@components";
import { ModalStepsEnum } from "../interfaces";
import { qcmTestType } from "@utilities/types";
import { FontFamilyEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    responses: string[]
    currentQuestion: qcmTestType | null
    currentQuestionIndex: number
    isRespondedAllQuestion: boolean
    countDown: ()=> JSX.Element
    handleSelectedResponse: (e: string)=> void
    handleNextQuestion: ()=> void
    handleValidateQcm: ()=> void
    setModalSteps: (e: ModalStepsEnum)=> void
}

export const QuizzComponent:FC<Props> = ({
    responses,
    currentQuestion,
    currentQuestionIndex,
    isRespondedAllQuestion,
    countDown,
    handleSelectedResponse,
    handleNextQuestion,
    handleValidateQcm,
    setModalSteps,
})=> {

    const { isMobile } = useScreenSize();

    const customModalContainer:CSSProperties = {
        justifyContent: 'flex-start',
        paddingTop: 20,
        ...(isMobile ? {} : {paddingLeft: '10%'}),
        ...(isMobile ? {} : {paddingRight: '10%'})
    };

    const simpleBadgeQuestion:CSSProperties = {
        marginTop: 25
    };

    const simpleButtonNextQuestion:CSSProperties = {
        fontSize: 14,
        width: '100%'
    };

    const selectedQuestionWrapper:CSSProperties = {
        border: 'solid 3px #6F6D69',
        backgroundColor: "#EFECE6"
    };

    const selectedQuestionTxt:CSSProperties = {
        color: '#6F6D69',
        fontSize: 14,
        fontFamily: FontFamilyEnum.SF_Pro_Display_Bold
    };

    const customAnimatedButtonStyles:CSSProperties = {
        width: '100%',
        marginTop: 5,
        borderRadius: 8,
        fontSize: 14,
        height: 44
    };

    return (
        <ModalContainer style={customModalContainer}>
            {countDown()}

            <SimpleBadge style={simpleBadgeQuestion}>
                Question {currentQuestionIndex}/20
            </SimpleBadge>

            <ModalQuestion>
                {currentQuestion?.question}
            </ModalQuestion>

            <QuestionSection>
                {
                    responses.map((el, i)=> {
                        const isSelected = el === currentQuestion?.selected_response;
                        return (
                            <QuestionWrapper 
                                key={i}
                                onClick={()=> handleSelectedResponse(el)}
                                style={isSelected ? selectedQuestionWrapper : {}}
                            >
                                <QuestionTxt style={isSelected ? selectedQuestionTxt : {}}>
                                    {el}
                                </QuestionTxt>
                            </QuestionWrapper>
                        )
                    })
                }
            </QuestionSection>

            {
                currentQuestionIndex < 20 && (
                    <SimpleButton
                        style={simpleButtonNextQuestion}
                        onClick={handleNextQuestion}
                    >
                        Question suivante
                    </SimpleButton>
                )
            }

            {
                isRespondedAllQuestion && (
                    <AnimatedGradianButton
                        style={customAnimatedButtonStyles}
                        onClick={handleValidateQcm}
                    >
                        Finir le test
                    </AnimatedGradianButton>
                )
            }

            <DisplayAllQuestion
                onClick={()=> setModalSteps(ModalStepsEnum.QUESTION_LIST)}
            >
                Voir toutes les questions
            </DisplayAllQuestion>
        </ModalContainer>
    )
}