import { CSSProperties, FC, useMemo } from "react";
import { 
    BadgeWrapper, CardPage, 
    CardPageTitle, Divider, 
    SimpleBadge, SimpleButton,
    SimpleSelect,
} from "@components";
import { 
    Avatar, ButtonGroup, 
    ButtonsGroupSection, CardPageIASection,
    CardPageIASubSection, CardPageIAText, 
    CardPageIATitle, CardPageSection, 
    ProfileContainer, ProfileSubContainer, 
    RightSectionContainer, TalentCvDescription, 
    TalentCvTitle, TalentName,
} from "../styles";
import { enumToObjectsArray, formatDate } from "@utilities/functions";
import { matchingType } from "@utilities/types";
import { ApplicationStatusEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";

interface Props {
    handleDisplayCV: ()=> void
    toggleDisplayJobOffer: ()=> void
    DefaultAvatar: string
    seletedTalent: matchingType
    toggleDisplayQcmResult: ()=> void
    handleChangeUseApplicationStatus: (applicationStatus: string)=> void
}

export const RightSection:FC<Props> = ({
    handleDisplayCV,
    toggleDisplayJobOffer,
    DefaultAvatar,
    seletedTalent,
    toggleDisplayQcmResult,
    handleChangeUseApplicationStatus,
})=> {

    const { isMobile } = useScreenSize();
    const topButtonStyles:CSSProperties = useMemo(()=> ({
        backgroundColor: '#F9F9F9',
        color: '#101214',
        borderRadius: 100,
    }), []);

    const selectStyles:CSSProperties = useMemo(()=> ({
        width: isMobile ? '100%' : 300,
    }), [isMobile])

    const applicationStatusOptions = useMemo(()=> enumToObjectsArray(ApplicationStatusEnum), []);

    return (
        <RightSectionContainer>
            <CardPage style={{marginTop: 0}}>

                <CardPageSection>
                    <ButtonGroup>
                        <ButtonsGroupSection>
                            <SimpleButton onClick={handleDisplayCV} style={topButtonStyles}>📄  Voir CV</SimpleButton>
                            <SimpleButton onClick={toggleDisplayJobOffer} style={topButtonStyles}>📝  Voir offre d’emploi</SimpleButton>
                        </ButtonsGroupSection>

                        <SimpleSelect 
                            placeholder="Envoyer vers"
                            value={seletedTalent.applicationStatus as string} 
                            onChange={handleChangeUseApplicationStatus} 
                            options={applicationStatusOptions}
                            styles={selectStyles}
                            color="#fff"
                            bgColor="#101214"
                        />
                    </ButtonGroup>

                    <ProfileContainer>
                        <Avatar src={DefaultAvatar} alt='avatar' />

                        <ProfileSubContainer>
                            <TalentName>{seletedTalent?.talent?.firstname} {seletedTalent?.talent?.lastname}</TalentName>
                            <TalentCvTitle>{seletedTalent?.cv?.title}</TalentCvTitle>
                        </ProfileSubContainer>
                    </ProfileContainer>

                    <TalentCvDescription>
                        {seletedTalent?.cv?.shortDescription}
                    </TalentCvDescription>

                    <BadgeWrapper style={{marginTop: 30}}>
                        <SimpleBadge>✉️ {seletedTalent?.talent?.mail}</SimpleBadge>
                        {seletedTalent?.talent?.phone && (<SimpleBadge>📞 {seletedTalent?.talent?.phone}</SimpleBadge>)}
                        {seletedTalent?.talent?.birth && (<SimpleBadge>🎂 {formatDate(seletedTalent?.talent?.birth)}</SimpleBadge>)}
                        {seletedTalent?.talent?.gender && (<SimpleBadge>⚧️ {seletedTalent?.talent?.gender}</SimpleBadge>)}
                    </BadgeWrapper>
                </CardPageSection>

                <Divider />

                <CardPageIASection>
                    <CardPageTitle>IA score : {seletedTalent?.score}/100</CardPageTitle>
                    
                    <CardPageIASubSection>
                        <CardPageIATitle>Points forts (généré par IA)</CardPageIATitle>
                        <CardPageIAText>
                            {seletedTalent?.highlights}
                        </CardPageIAText>
                    </CardPageIASubSection>

                    <CardPageIASubSection>
                        <CardPageIATitle>Points faibles (généré par IA)</CardPageIATitle>
                        <CardPageIAText>
                            {seletedTalent?.weakPoints}
                        </CardPageIAText>
                    </CardPageIASubSection>
                </CardPageIASection>

                {
                    seletedTalent?.qcmResult && ( //getLevelColors
                        <>
                            <Divider style={{marginTop: 40}} />

                            <CardPageIASection>
                                <CardPageTitle>Resultat du test : {seletedTalent.qcmResult.score}/20</CardPageTitle>
                                
                                <CardPageIASubSection>
                                    <CardPageIATitle>{seletedTalent.qcmResult.entitle}</CardPageIATitle>
                                    <BadgeWrapper>
                                        <SimpleBadge>{seletedTalent.qcmResult.level}</SimpleBadge>
                                        <SimpleButton onClick={toggleDisplayQcmResult}>Voir les resultats</SimpleButton>
                                    </BadgeWrapper>
                                </CardPageIASubSection>
                            </CardPageIASection>
                        </>
                    )
                }
            </CardPage>
        </RightSectionContainer>
    )
}