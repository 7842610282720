import { EnumContratType } from "@utilities/enums"
import { Matching } from "./matching.types"

export interface Post {
    _id?: string
    _organisationID?: string
    _recruiterID?: string
    _qcmID?: string
    entitle: string
    sector: string
    office: string
    department: string
    description: string
    profileSought: string
    conductOfInterviews: string
    levelOfStudy: string
    experience: number
    criteria: CriteriaType
    faq: FaqType[]
    location: string
    startAt: Date
    createAt?: Date
    talentWhoApplied?: string[]
    isArchieved?: boolean
}

export interface CriteriaType {
    contratType: EnumContratType
    teleworking: string
    workTime: EnumWorkTime
    minSalaryMonth: number
    maxSalaryMonth: number
}

export interface FaqType {
    question: string
    response: string
}

export enum EnumWorkTime {
    Travail_journee = 'Travail en journée',
    Lundi_vendredi = 'Du lundi au vendredi',
    Travail_soiree = 'Travail en soirée',
    Disponible_weekend = 'Disponible le week-end',
}

export interface PostInitialStateType {
    posts: Post[]
    organisationPosts: Post[]
    userTalentPosts: Post[]
    postMatching: Matching | null
    talentMatchings: Matching[]
}
