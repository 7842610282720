import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Matching,
  Post,
  PostInitialStateType,
} from '@utilities/types';
import { SLICES_NAMES } from '../slicesNames';

const initialState: PostInitialStateType = {
  posts: [],
  organisationPosts: [],
  userTalentPosts: [],
  postMatching: null,
  talentMatchings: [],
};

const postSlice = createSlice({
  name: SLICES_NAMES.post,
  initialState, 
  reducers: {
    saveAllPosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload
    },
    saveOrganisationPosts: (state, action: PayloadAction<Post[]>) => {
      state.organisationPosts = action.payload
    },
    saveUserTalentPosts: (state, action: PayloadAction<Post[]>) => {
      state.userTalentPosts = action.payload
    },
    savePostMatching: (state, action: PayloadAction<Matching | null>)=> {
      state.postMatching = action.payload
    },
    saveTalentMatching: (state, action: PayloadAction<Matching[]>)=> {
      state.talentMatchings = action.payload
    }
  },
});

export const { 
  saveAllPosts,
  saveOrganisationPosts,
  saveUserTalentPosts,
  savePostMatching,
  saveTalentMatching,
} = postSlice.actions;

export const postReducers = postSlice.reducer;
