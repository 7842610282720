import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Logo = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    object-fit: contain;
    background-color: #EFECE6;
`;

export const SocialMediaWrapper = styled.section`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
`;

export const IconWrapper = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

export const Text = styled.p`
    margin: 0%;
    margin-top: 20px;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.PlusJakartSans_Medium};
`;