import { 
    saveTalent,
    // saveTalentToken,
    saveTalentCV,
    saveTalentSavedPost,
    saveTalentTests,
    saveTalentTestResults,
} from "@data/reducers";
import { 
    fetchDeletedTalentPostRequest,
    fetchGenerateTestRequest,
    fetchGetTalentCVRequest,
    fetchGetTalentDataRequest,
    fetchGetTalentDataWithOTPRequest,
    fetchGetTalentSavedPostRequest,
    fetchGetTalentTestRequest,
    fetchGetTalentTestsResultRequest,
    fetchOnChangePasswordRequest,
    fetchOnVerifyForgotPasswordOTPRequest,
    fetchResendTalentMailConfirmRequest,
    fetchSavedTalentPostRequest,
    fetchSaveTalentCVRequest,
    fetchSendTalentMailForgotPasswordRequest,
    fetchSigninTalentRequest,
    fetchSignupTalentRequest,
    fetchUpdatedTalentAvatarDataRequest,
    fetchUpdatedTalentDataRequest,
    fetchUserTestFixRequest,
    fetchValidateUserTalentRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { 
    CV,
    FetchResponseType, 
    SavedPost, 
    SignInActionPayloadRequest,
    TalentSavedPost,
    TEST_QCM,
    UserQCMSolution,
    UserTalent,
} from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useTalentSelectors } from "../selectors";

export const useTalentDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { talent, talentCV } = useTalentSelectors();

    const onGetTalentData = useCallback(async() => {
        const req:FetchResponseType = await fetchGetTalentDataRequest(talent?.token!);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentTmp:UserTalent = req.data;
        dispatch(saveTalent(talentTmp));
    }, [dispatch, talent]);

    const onSignWithOTP = useCallback(async(otp: string) => {
        const req:FetchResponseType = await fetchGetTalentDataWithOTPRequest(otp);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentTmp:UserTalent = req.data;
        dispatch(saveTalent(talentTmp));
    }, [dispatch]);

    const onTalentSignin = useCallback(async(signinData: SignInActionPayloadRequest) => {
        const req:FetchResponseType = await fetchSigninTalentRequest(signinData);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talent:UserTalent = req.data;
        dispatch(saveTalent(talent));
    }, [dispatch]);

    const onTalentSignup = useCallback(async(talentData: UserTalent) => {
        const req:FetchResponseType = await fetchSignupTalentRequest(talentData);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talent:UserTalent = req.data;
        dispatch(saveTalent(talent));
    }, [dispatch]);

    const onUpdatedUserTalent = useCallback(async(talentData: UserTalent) => {
        const req:FetchResponseType = await fetchUpdatedTalentDataRequest(talentData);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentTmp:UserTalent = req.data;
        dispatch(saveTalent(talentTmp));
    }, [dispatch]);

    const onUpdatedTalentAvatarData = useCallback(async(avatarFile: File) => {
        const req:FetchResponseType = await fetchUpdatedTalentAvatarDataRequest({token: talent?.token!, avatarFile});
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentTmp:UserTalent = req.data;
        dispatch(saveTalent(talentTmp));
    }, [dispatch, talent]);

    const onGetTalentCVRequest = useCallback(async() => {
        const req:FetchResponseType = await fetchGetTalentCVRequest(talent?.token!);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const getTalentCV:CV = req.data;
        dispatch(saveTalentCV(getTalentCV));
    }, [dispatch, talent]);

    const onSaveTalentCVRequest = useCallback(async(updatedTalentCV: CV) => {
        const req:FetchResponseType = await fetchSaveTalentCVRequest({token: talent?.token!, cv: updatedTalentCV});
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        await onGetTalentCVRequest();
    }, [onGetTalentCVRequest, talent]);

    const onGetTalentSavedPostRequest = useCallback(async(userID: string) => {
        const req:FetchResponseType = await fetchGetTalentSavedPostRequest(userID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentSavedPost:TalentSavedPost[] = req.data;
        dispatch(saveTalentSavedPost(talentSavedPost));
    }, [dispatch]);

    const onSaveTalentPostRequest = useCallback(async(savedPost: SavedPost) => {
        const req:FetchResponseType = await fetchSavedTalentPostRequest(savedPost);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
    }, []);

    const onDeleteTalentPostRequest = useCallback(async(savedPostID: string) => {
        const req:FetchResponseType = await fetchDeletedTalentPostRequest(savedPostID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
    }, []);

    const onGetTalentTestQcm = useCallback(async() => {
        const req:FetchResponseType = await fetchGetTalentTestRequest(talent?.token!);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const talentTests:TEST_QCM[] = req.data;
        if(talentTests.length > 0 && talentCV){
            onSaveTalentCVRequest({
                ...talentCV,
                qcmIsLoading: false,
            });
        }
        dispatch(saveTalentTests(talentTests));
    }, [dispatch, onSaveTalentCVRequest, talent, talentCV]);

    const onGetTalentTestResultsRequest = useCallback(async() => {
        const req:FetchResponseType = await fetchGetTalentTestsResultRequest(talent?.token!);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const userQcmSolution:UserQCMSolution[] = req.data;
        dispatch(saveTalentTestResults(userQcmSolution));
    }, [dispatch, talent]);

    const onGenerateTestQcm = useCallback(async(cvID: string) => {
        const req:FetchResponseType = await fetchGenerateTestRequest(cvID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        await onGetTalentTestQcm();
    }, [onGetTalentTestQcm]);

    const onUserTestFixRequest = useCallback(async(qcm: TEST_QCM) => {
        const req:FetchResponseType = await fetchUserTestFixRequest({userQcmSolution: qcm, token: talent?.token!});
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        await onGetTalentTestResultsRequest();
        await onGetTalentTestQcm();
    }, [onGetTalentTestResultsRequest, onGetTalentTestQcm, talent]);

    const onValidateTalentMailRequest = useCallback(async(otp: string) => {
        const req:FetchResponseType = await fetchValidateUserTalentRequest({otp: otp, token: talent?.token!});
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        await onGetTalentData();
    }, [onGetTalentData, talent]);

    const onSendNewMail = useCallback(async()=> {
        const req:FetchResponseType = await fetchResendTalentMailConfirmRequest(talent?.token!);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
    }, [talent]);

    const onSendMailForgotPassword = useCallback(async(mail: string)=> {
        const req:FetchResponseType = await fetchSendTalentMailForgotPasswordRequest(mail);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'succes !'
    }, []);

    const onVerifyForgotPasswordOTP = useCallback(async(otp: string)=> {
        const req:FetchResponseType = await fetchOnVerifyForgotPasswordOTPRequest(otp);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return null;
        }
        return req.data.token as string;
    }, []);

    const onChangePassword = useCallback(async(newPassword: string, token: string)=> {
        const req:FetchResponseType = await fetchOnChangePasswordRequest({token, newPassword});
        if(req.code === 500){
            console.log('===error=>', req.data);
            return null;
        }
        return 'succes !'
    }, []);

    const onTalentLogout = useCallback(() => {
        dispatch(saveTalent(null));
        // dispatch(saveTalentToken(''));
        dispatch(saveTalentCV(null));
        dispatch(saveTalentSavedPost([]));
        dispatch(saveTalentTests([]));
        dispatch(saveTalentTestResults([]));
    }, [dispatch]);

    return {
        onGetTalentData,
        onSignWithOTP,
        onTalentSignin,
        onTalentSignup,
        onUpdatedUserTalent,
        onUpdatedTalentAvatarData,
        onGetTalentCVRequest,
        onSaveTalentCVRequest,
        onGetTalentSavedPostRequest,
        onSaveTalentPostRequest,
        onDeleteTalentPostRequest,
        onGetTalentTestQcm,
        onGetTalentTestResultsRequest,
        onGenerateTestQcm,
        onUserTestFixRequest,
        onValidateTalentMailRequest,
        onSendNewMail,
        onSendMailForgotPassword,
        onVerifyForgotPasswordOTP,
        onChangePassword,
        onTalentLogout,
    };
};
