import { 
    saveAllPosts,
    saveOrganisationPosts,
    savePostMatching,
    saveTalentMatching,
    saveUserTalentPosts,
} from "@data/reducers";
import { 
    fetchGetOrganisationPostRequest,
    fetchGetPostRequest, 
    fetchGetResponseToJobOfferRequest, 
    fetchGetTalentApplicationTrackingRequest, 
    fetchGetTalentResponseToJobRequestRequest, 
    fetchOnTalentResponseToJobOfferRequest, 
    fetchSaveOrganisationPostRequest, 
    fetchUpdatedOrganisationPostRequest,
    fetchUpdatedTalentApplicationStatusRequest,
} from "@data/services";
import { AppDispatch } from "@data/store";
import { FetchResponseType, Matching, Post } from "@utilities/types";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useRecruiterSelectors, useTalentSelectors } from "../selectors";

export const usePostsDispatch = () => {

    const dispatch = useDispatch<AppDispatch>();
    const { recruiter } = useRecruiterSelectors();
    const { talent, talentCV } = useTalentSelectors();

    const onGetAllPostRequest = useCallback(async() => {
        const req:FetchResponseType = await fetchGetPostRequest(talent?.token!);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const allPost:Post[] = req.data;
        dispatch(saveAllPosts(allPost));
    }, [dispatch]);

    const onGetOrganisationPostsRequest = useCallback(async(organisationID: string) => {
        const req:FetchResponseType = await fetchGetOrganisationPostRequest(organisationID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const organisationPost:Post[] = req.data;
        dispatch(saveOrganisationPosts(organisationPost));
    }, [dispatch]);

    const onSaveOrganisationPostRequest = useCallback(async(post: Post) => {
        const req:FetchResponseType = await fetchSaveOrganisationPostRequest({
            token: recruiter?.token!, 
            post: {
                ...post,
                _organisationID: recruiter?._organisationID,
                _recruiterID: recruiter?._id,
            },
        });
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }

        await onGetOrganisationPostsRequest(recruiter?._organisationID!);
        return 'success !';
    }, [
        recruiter,
        onGetOrganisationPostsRequest
    ]);

    const onUpdatedOrganisationPostRequest = useCallback(async(post: Post) => {
        const req:FetchResponseType = await fetchUpdatedOrganisationPostRequest(post);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        return 'success !';
    }, []);
    
    const onGetTalentResponseToJobRequest = useCallback(async(talentID: string)=> {
        const req:FetchResponseType = await fetchGetTalentResponseToJobRequestRequest(talentID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }
        const userTalentPost:Post[] = req.data;
        dispatch(saveUserTalentPosts(userTalentPost));
    }, [dispatch]);

    const onTalentResponseToJobOfferRequest = useCallback(async(postID: string)=> {
        const req:FetchResponseType = await fetchOnTalentResponseToJobOfferRequest({
            postID, 
            talentToken: talent?.token!, 
            talentCVID: talentCV?._id!,
        });
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }

        return 'success !';
    }, [talent, talentCV]);

    const onGetTalentApplicationTrackingRequest = useCallback(async(talentCvId: string)=> {
        const req:FetchResponseType = await fetchGetTalentApplicationTrackingRequest(talentCvId);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }

        const talentMatchings:Matching[] = req.data;
        dispatch(saveTalentMatching(talentMatchings));
    }, [dispatch]);

    const onGetResponseToJobOfferRequest = useCallback(async(postID: string)=> {
        const req:FetchResponseType = await fetchGetResponseToJobOfferRequest(postID);
        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }

        const matchingPost:Matching | null = req.data;
        dispatch(savePostMatching(matchingPost));
    }, [dispatch]);

    const onUpdatedTalentApplicationStatusRequest = useCallback(async(
        matchingID: string,
        talentToken: string,
        newTalentApplictionStatus: string,
    )=> {
        const req:FetchResponseType = await fetchUpdatedTalentApplicationStatusRequest({
            matchingID, talentToken, newTalentApplictionStatus,
        });

        if(req.code === 500){
            console.log('===error=>', req.data);
            return;
        }

        return 'success';
    }, []);

    const onResetPostsRequest = useCallback(()=> {
        dispatch(saveAllPosts([]));
        dispatch(saveOrganisationPosts([]));
        dispatch(saveUserTalentPosts([]));
        dispatch(savePostMatching(null));
    }, [dispatch]);

    return {
        onGetAllPostRequest,
        onGetOrganisationPostsRequest,
        onSaveOrganisationPostRequest,
        onUpdatedOrganisationPostRequest,
        onGetTalentResponseToJobRequest,
        onTalentResponseToJobOfferRequest,
        onGetTalentApplicationTrackingRequest,
        onGetResponseToJobOfferRequest,
        onUpdatedTalentApplicationStatusRequest,
        onResetPostsRequest,
    };
};
