import { RootState } from "@data/store";
import { useSelector } from "react-redux";

export const useAppointmentSelectors = () => {

    const { 
        talentAppointment,
        recruiterAppointment,
    } = useSelector((state: RootState) => state.appointment);

    return {
        talentAppointment,
        recruiterAppointment,
    };
};

