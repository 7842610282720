import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const PageTitle = styled.p`
    margin: 0%;
    margin-top: 35px;
    color: #101214;
    font-size: 22px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Heavy};

    @media (min-width: 760px) {
        font-size: 44px;
    }
`;

export const CardWrapper = styled.section`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
`;

export const SearchSectionContainer = styled.section`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    gap: 10px;
    width: 100%;
    background-color: #EFECE6;

    @media (min-width: 760px) {
        padding: 50px;
        border-radius: 12px;
    }
`;

export const SearchSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (min-width: 760px) {
        flex-direction: row;
        gap: 0px;
    }
`;

export const Location = styled.p`
    margin: 0%;
    font-size: 16px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
`;
