import { matchingType } from "@utilities/types";
import { CSSProperties, FC } from "react";
import { ListItem, TalentItemName, TalentItemtxt, TalentList, TxtGroup } from "../styles";
import { getColorByNumber } from "@utilities/functions";

interface Props {
    activeMatching: matchingType[]
    activeIndex: number
    setActiveIndex: (e: number)=> void
    setSeletedTalent: (e: matchingType | null)=> void
}

export const TalentListComponent:FC<Props> = ({
    activeMatching, 
    activeIndex, 
    setActiveIndex, 
    setSeletedTalent,
})=> {

    const talents = activeMatching.map(el=> ({
        talentName: `${el.talent?.firstname} ${el.talent?.lastname}`,
        score: el.score,
        location: el.cv?.criteria.localisation,
        talent: el,
    }));

    return (
        <TalentList>
            {
                talents.map((el, i)=> {

                    const colors = getColorByNumber(el.score);

                    const activeElement:CSSProperties = {
                        border: `solid 2px #101214`,
                        backgroundColor: colors.bg,
                    };

                    return (
                        <ListItem
                            key={i} style={i === activeIndex ? activeElement : {
                                backgroundColor: colors.bg,
                                border: `solid 2px ${colors.border}`,
                            }}
                            onClick={()=> {
                                setActiveIndex(i);
                                setSeletedTalent(el.talent);
                            }}
                        >
                            <TxtGroup>
                                <TalentItemName>{el.talentName}</TalentItemName>
                                <TalentItemtxt>postulé il y a 4 jours</TalentItemtxt>
                            </TxtGroup>

                            <TxtGroup>
                                <TalentItemtxt>{el.score}/100</TalentItemtxt>
                                <TalentItemName>{el.location}</TalentItemName>
                            </TxtGroup>
                        </ListItem>
                    )
                })
            }
        </TalentList>
    )
}
