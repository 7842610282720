import { CardPage, CardPageTitle, InfoLabel, InfosWrapper, Input, SimpleSelect } from "@components";
import { CSSProperties, FC, useMemo } from "react";
import {Form, FormSection } from "../../../styles";
import { enumToObjectsArray } from "@utilities/functions";
import { EnumJobCategory } from "@utilities/enums";

interface Props {
    cardPageStyles: CSSProperties
    entitle: string
    setEntitle: (e: string)=> void
    sector: string
    setSector: (e: string)=> void
    office: string
    setOffice: (e: string)=> void
    department: string
    setDepartment: (e: string)=> void
    postDescription: string
    setPostDescription: (e: string)=> void
    profileSought: string
    setProfileSought: (e: string)=> void
    conductOfInterviews: string
    setConductOfInterviews: (e: string)=> void
    schoolLevel: string 
    setSchoolLevel: (e: string)=> void
    experienceLevel: string
    setExperienceLevel: (e: string)=> void
}

export const JobOfferDescription:FC<Props> = ({
    cardPageStyles,
    entitle,
    setEntitle,
    sector,
    setSector,
    office,
    setOffice,
    department,
    setDepartment,
    postDescription,
    setPostDescription,
    profileSought,
    setProfileSought,
    conductOfInterviews,
    setConductOfInterviews,
    schoolLevel,
    setSchoolLevel,
    experienceLevel,
    setExperienceLevel,
})=> {

    const secteurOptions = useMemo(()=> enumToObjectsArray(EnumJobCategory), []);

    const schoolLevelOptions = useMemo(()=> (
        Array.from({ length: 10 }, (_, i) => ({
            label: `+ ${i.toString()}`,
            value: i.toString()
        }))
    ), []);

    const experienceLevelOptions = useMemo(()=> (
        Array.from({ length: 10 }, (_, i) => ({
            label: `+ ${i.toString()}`,
            value: i.toString()
        }))
    ), []);

    return (
        <CardPage style={cardPageStyles}>
            <CardPageTitle>Description de l’offre</CardPageTitle>

            <Form>
                <InfosWrapper>
                    <InfoLabel>Intitulé du poste</InfoLabel>
                    <Input
                        placeholder="Intitul du poste"
                        value={entitle}
                        onChange={setEntitle}
                    />
                </InfosWrapper>

                <FormSection>
                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Secteur</InfoLabel>
                        <SimpleSelect
                            placeholder="Selectionner"
                            value={sector}
                            onChange={setSector}
                            options={secteurOptions}
                        />
                    </InfosWrapper>

                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Bureau</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={office}
                            onChange={setOffice}
                            options={[]}
                        />
                    </InfosWrapper>

                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Département</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={department}
                            onChange={setDepartment}
                            options={[]}
                        />
                    </InfosWrapper>
                </FormSection>

                <InfosWrapper>
                    <InfoLabel>Descriptif du poste</InfoLabel>
                    <Input 
                        inputType='textarea'
                        placeholder="Descriptif du poste"
                        value={postDescription}
                        onChange={setPostDescription}
                    />
                </InfosWrapper>

                <InfosWrapper>
                    <InfoLabel>Profil recherché</InfoLabel>
                    <Input 
                        inputType='textarea'
                        placeholder="Profil recherché"
                        value={profileSought}
                        onChange={setProfileSought}
                    />
                </InfosWrapper>

                <InfosWrapper>
                    <InfoLabel>Déroulement des entretiens</InfoLabel>
                    <Input 
                        inputType='textarea'
                        placeholder="Déroulement des entretiens"
                        value={conductOfInterviews}
                        onChange={setConductOfInterviews}
                    />
                </InfosWrapper>

                <FormSection>
                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Niveau d’étude</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={schoolLevel}
                            onChange={setSchoolLevel}
                            options={schoolLevelOptions}
                        />
                    </InfosWrapper>

                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Expérience</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={experienceLevel}
                            onChange={setExperienceLevel}
                            options={experienceLevelOptions}
                        />
                    </InfosWrapper>
                </FormSection>
            </Form>
        </CardPage>
    )
}