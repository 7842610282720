import { FontFamilyEnum } from "@utilities/enums";
import styled from "styled-components";

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    
    margin-top: 135px;
    background-color: #EFECE6;
    padding-bottom: 100px;

    @media (min-width: 760px){

    }
`;

export const TestCard = styled.div<{color: string, bgColor?: string}>`
    position: relative;
    width: 100%;
    height: 90px;
    background-color: ${props=> props.bgColor || '#fff'};
    padding: 10px 5px 10px 5px;
    border: solid 5px ${props=> props.color};
    margin-bottom: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    @media (min-width: 760px){
        height: 152px;
        padding: 35px 30px 35px 30px;
        border: solid 7px ${props=> props.color};
        margin-bottom: 40px;
        border-radius: 25px;
    }
`;
export const TestCardSolution = styled.div`
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    border-radius: 8px;
    background-color: #00000060;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 760px){
        border-radius: 19px;
    }
`;
export const TestCardBadge = styled.div<{color: string}>`
    position: absolute;
    z-index: 9;
    right: 20px;
    top: -13px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};
    font-size: 12px;
    color: #101214;
    height: 20px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${props=> props.color};
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 760px){
        right: 40px;
        top: -22px;
        font-size: 18px;
        height: 35px;
        border-radius: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const TestSubCard = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

export const Details = styled.div`
    flex: 1;
    margin-left: 10px;
    margin-right: 10px;

    @media (min-width: 760px){
        margin-left: 20px;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: #101214;
    cursor: pointer;

    @media (min-width: 760px){
        height: 60px;
        width: 60px;
        border-radius: 60px;
    }
`;

export const Image = styled.img`
    width: 45px;
    height: 45px;
    margin-left: 5px;
    border-radius: 8px;
    object-fit: cover;

    @media (min-width: 760px){
        height: 90px;
        width: 90px;
    }
`;

export const TestCardTitle = styled.p`
    margin: 0%;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};

    @media (min-width: 760px){
        font-size: 22px;
    }
`;

export const ModalSection = styled.div`
    margin-top: 50px;
    width: 100%;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Section = styled.section`
    width: 100%;
    min-height: ${window.innerHeight - 458}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    width: 80%;
    color: #101214;
`;

export const LevelSection = styled.div`
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: #fff;
`;

export const WarningText = styled.p`
    margin: 0%;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
    font-size: 14px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 90%;
    color: #FFA86F;

    @media (min-width: 760px){
        width: 50%;
    }
`;
export const WarningTextLabel = styled.label`
    font-family: ${FontFamilyEnum.SF_Pro_Display_Black};
`;

export const TestCardSubTitle = styled.p`
    margin: 0%;
    font-size: 12px;
    margin-top: 4px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_SEMI_BOLD};

    @media (min-width: 760px){
        font-size: 18px;
        margin-top: 10px;
    }
`;
