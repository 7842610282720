import { FC } from "react";
import { Details, IconWrapper, Image, TestCard, TestCardBadge, TestCardSolution, TestCardSubTitle, TestCardTitle, TestSubCard } from "../styles";
import { Icon, SimpleButton } from "@components";
import { IconNameEnum } from "@utilities/enums";
import { UserQCMSolution } from "@utilities/types";

import DefaultImg from '@assets/images/defaultImg.png';
import { useScreenSize } from "@utilities/hooks";
import { getLevelColors } from "@utilities/functions";

interface Props {
    index: number
    solution: UserQCMSolution
    setSelectedQCMSolution: (e: UserQCMSolution)=> void
}

export const TestSolutionCard:FC<Props> = ({
    index,
    solution,
    setSelectedQCMSolution,
})=> {

    const { isMobile } = useScreenSize();
    const { color, bgColor } = getLevelColors(solution.level);

    return (
        <TestCard 
            key={index} 
            color={color}
            bgColor={bgColor}
        >
            <TestCardSolution>
                <SimpleButton onClick={()=> setSelectedQCMSolution(solution)}>
                    Voir le résultat <Icon iconName={IconNameEnum.arrowRight} color="#fff" />
                </SimpleButton>
            </TestCardSolution>

            <TestCardBadge color={color}>{solution.level}</TestCardBadge>
            <TestSubCard>
                <Image
                    src={DefaultImg}
                />
                <Details>
                    <TestCardTitle>
                        {
                            isMobile ? 'Évaluez-vous avec ce test'
                            : 'Évaluez-vous avec ce test personnalisé basé sur votre CV'
                        }
                    </TestCardTitle>
                    
                    <TestCardSubTitle>
                        📚 {solution.entitle}
                    </TestCardSubTitle>
                </Details>

                <IconWrapper>
                    <Icon
                        iconName={IconNameEnum.arrowRight}
                        color="#fff"
                        size={isMobile ? '1x' : 'xl'}
                    />
                </IconWrapper>
            </TestSubCard>
        </TestCard>
    )
}