import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

// Styled Components
const TimeSlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimeSlotButton = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 50px;
  width: ${({ isSelected }) => (isSelected ? '95px' : '200px')};
  margin: 5px;
  border: 2px solid ${({ isSelected }) => (isSelected ? '#717171' : '#0069FF')};
  background-color: ${({ isSelected }) => (isSelected ? '#717171' : 'white')};
  color: ${({ isSelected }) => (isSelected ? 'white' : '#0069FF')};
  cursor: pointer;
  border-radius: 5px;
  position: relative;
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: #40D794;
  border-radius: 50%;
  display: inline-block;
`;

const Button = styled.button<{isDangerMode: boolean}>`
  width: 95px;
  height: 50px;
  margin-left: 5px;
  background-color: ${({ isDangerMode })=> (isDangerMode ? '#fe5e19' : '#0069FF')};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const TimeSlotRow = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const TimeSlotList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

interface Props {
    isDangerMode?: boolean
    timeSlots: string[]; //ex: ['13:15', '14:30']
    onTimeSlotSelect: (e: string)=> void
}

// Composant principal
export const TimeSlots:FC<Props> = ({
    timeSlots,
    isDangerMode = false,
    onTimeSlotSelect,
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string | null>(null);

  const handleTimeSlotClick = useCallback((slot: string | null) => {
    setSelectedTimeSlot(slot);
  }, []);

  return (
    <TimeSlotContainer>
      <TimeSlotList>
        {timeSlots.map((slot) => {
            const isSelectedTimeSlot = selectedTimeSlot === slot;

            return (
                <TimeSlotRow key={slot}>
                    <TimeSlotButton
                        isSelected={isSelectedTimeSlot}
                        onClick={() => handleTimeSlotClick(isSelectedTimeSlot ? null : slot)}
                    >
                        {!isSelectedTimeSlot && <Dot />}
                        {slot}
                    </TimeSlotButton>
        
                    {
                        isSelectedTimeSlot && (
                            <Button 
                                isDangerMode={isDangerMode}
                                onClick={()=> onTimeSlotSelect(slot)}
                            >
                                {isDangerMode ? 'Supprimer' : 'Suivant'}
                            </Button>
                        )
                    }
                </TimeSlotRow>
              )
        })}
      </TimeSlotList>
    </TimeSlotContainer>
  );
};
