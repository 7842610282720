import { FC } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import "./styles.css";

//@ts-ignore
import { fr } from "react-day-picker/locale";

interface Props {
  enablesDays: Date[]
  selectedDate: Date | undefined
  setSelectedDate: (date: Date | undefined)=> void
}

// const enablesDays = [new Date(2024, 9, 17), new Date(2024, 9, 20)];

export const DatePickerCalendar:FC<Props> = ({
  enablesDays,
  selectedDate,
  setSelectedDate
})=> {

  const modifiers = {
    special: enablesDays,
  };

  const isSpecialDay = (day: Date) => {
    return enablesDays.some(specialDay => 
      day.getFullYear() === specialDay.getFullYear() &&
      day.getMonth() === specialDay.getMonth() &&
      day.getDate() === specialDay.getDate()
    );
  };

  return (
    <DayPicker
      locale={fr}
      captionLayout="dropdown"
      mode="single"
      selected={selectedDate}
      onSelect={setSelectedDate}
      disabled={(day) => !isSpecialDay(day)}
      startMonth={new Date(2024, 0)}
      endMonth={new Date(2030, 9)}
      modifiers={modifiers}
      modifiersClassNames={{
        special: "enableDay",
      }}
      // footer={
      //   selected ? `Selected: ${selected.toLocaleDateString()}` : "Pick a day."
      // }
      components={{
          DayButton: (props) => {
            const { day, modifiers, ...buttonProps } = props;
            return (
              <button
                {...buttonProps}
                // Prevent the default click event
                onClick={() => setSelectedDate(day.date)}
                // style={{background: 'red'}}
              />
            );
          }
      }}
    />
  )
}