import { ApplicationStatusEnum } from "@utilities/enums";
import { usePostsDispatch } from "@utilities/hooks";
import { Matching, matchingType } from "@utilities/types";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface navStepType {
    label: ApplicationStatusEnum
    count: number
    matching: matchingType[]
}

export const useControllers = (postMatching: Matching | null)=> {

    const {
        onGetResponseToJobOfferRequest,
        onUpdatedTalentApplicationStatusRequest,
    } = usePostsDispatch();

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [activeSection, setActiveSection] = useState<ApplicationStatusEnum>(ApplicationStatusEnum.All);
    const [activeMatching, setActiveMatching] = useState<matchingType[]>([]);
    const [displayTalentCV, setDisplayTalentCV] = useState<boolean>(false);
    const [seletedTalent, setSeletedTalent] = useState<matchingType | null>(null);
    const [displayJobOffer, setDisplayJobOffer] = useState<boolean>(false);

    const [displayQcmResult, setDisplayQcmResult] = useState<boolean>(false);

    const matchings = useMemo(()=> postMatching?.matching || [], [postMatching]);

    const applicationStatusAllMatching = useMemo(()=> (
        matchings.filter(el=> el.applicationStatus === ApplicationStatusEnum.All) || []
    ), [matchings]);

    const applicationStatusPreSelectedMatching = useMemo(()=> (
        matchings.filter(el=> el.applicationStatus === ApplicationStatusEnum.PreSelected) || []
    ), [matchings]);

    const applicationStatusTestToSendMatching = useMemo(()=> (
        matchings.filter(el=> el.applicationStatus === ApplicationStatusEnum.TestToSend) || []
    ), [matchings]);

    const applicationStatusInterviewMatching = useMemo(()=> (
        matchings.filter(el=> el.applicationStatus === ApplicationStatusEnum.Interview) || []
    ), [matchings]);

    const applicationStatusHiredMatching = useMemo(()=> (
        matchings.filter(el=> el.applicationStatus === ApplicationStatusEnum.Hired) || []
    ), [matchings]);

    const applicationStatusRejectedMatching = useMemo(()=> (
        matchings.filter(el=> el.applicationStatus === ApplicationStatusEnum.Rejected) || []
    ), [matchings]);

    const navsSteps:navStepType[] = useMemo(()=> [
        {
            label: ApplicationStatusEnum.All,
            count: applicationStatusAllMatching.length,
            matching: applicationStatusAllMatching,
        },
        {
            label: ApplicationStatusEnum.PreSelected,
            count: applicationStatusPreSelectedMatching.length,
            matching: applicationStatusPreSelectedMatching,
        },
        {
            label: ApplicationStatusEnum.TestToSend,
            count: applicationStatusTestToSendMatching.length,
            matching: applicationStatusTestToSendMatching,
        },
        {
            label: ApplicationStatusEnum.Interview,
            count: applicationStatusInterviewMatching.length,
            matching: applicationStatusInterviewMatching,
        },
        {
            label: ApplicationStatusEnum.Hired,
            count: applicationStatusHiredMatching.length,
            matching: applicationStatusHiredMatching,
        },
        {
            label: ApplicationStatusEnum.Rejected,
            count: applicationStatusRejectedMatching.length,
            matching: applicationStatusRejectedMatching,
        },
    ], [
        applicationStatusAllMatching, 
        applicationStatusHiredMatching, 
        applicationStatusInterviewMatching, 
        applicationStatusPreSelectedMatching, 
        applicationStatusRejectedMatching, 
        applicationStatusTestToSendMatching,
    ]);

    const handleNavStepSelected = useCallback((navStep: navStepType)=> {
        setActiveSection(navStep.label);
        setActiveMatching(navStep.matching);
        setSeletedTalent(navStep.matching[0] || null);
    }, []);

    const toggleDisplayQcmResult = useCallback(()=> {
        setDisplayQcmResult(!displayQcmResult);
    }, [displayQcmResult]);

    const handleDisplayCV = useCallback(()=> {
        setDisplayTalentCV(true);
    }, []);

    const toggleDisplayJobOffer = useCallback(()=> {
        setDisplayJobOffer(!displayJobOffer);
    }, [displayJobOffer]);

    const handleChangeUseApplicationStatus = useCallback(async(selectedApplicationStatus: string)=> {
        if(seletedTalent){
            await onUpdatedTalentApplicationStatusRequest(
                postMatching?._id!, 
                seletedTalent.talent?.token!, 
                selectedApplicationStatus,
            );

            await onGetResponseToJobOfferRequest(postMatching?.postID!);
        }
    }, [
        seletedTalent, postMatching,
        onGetResponseToJobOfferRequest,
        onUpdatedTalentApplicationStatusRequest,
    ]);

    useEffect(()=> {
        if(applicationStatusAllMatching){
            setSeletedTalent(applicationStatusAllMatching[0]);
            setActiveMatching(applicationStatusAllMatching);
        }
    }, [applicationStatusAllMatching]);

    return {
        activeIndex,
        activeSection,
        activeMatching,
        displayTalentCV,
        seletedTalent,
        displayJobOffer,
        displayQcmResult,
        navsSteps,
        setActiveIndex,
        setDisplayTalentCV,
        setSeletedTalent,
        handleNavStepSelected,
        toggleDisplayQcmResult,
        handleDisplayCV,
        toggleDisplayJobOffer,
        handleChangeUseApplicationStatus,
    }
}
