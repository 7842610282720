import { CardPage, CardPageTitle, InfoLabel, InfosWrapper, Input, SimpleSelect } from "@components";
import { CSSProperties, FC, useMemo } from "react";
import {Form, FormSection } from "../../../styles";
import { enumToObjectsArray } from "@utilities/functions";
import { EnumContratType, TeleWorkingTypeEnum } from "@utilities/enums";
import { EnumWorkTime } from "@utilities/types";

interface Props {
    cardPageStyles: CSSProperties
    contratType: string 
    setContratType: (e: string) => void
    startDate: string 
    setStartDate: (e: string) => void 
    teleworking: string 
    setTeleworking: (e: string) => void 
    workingTime: string 
    setWorkingTime: (e: string) => void
    minSalary: string
    setMinSalary: (e: string) => void
    maxSalary: string
    setMaxSalary: (e: string) => void
}

export const JobOfferSettings:FC<Props> = ({
    cardPageStyles,
    contratType,
    setContratType,
    startDate, 
    setStartDate,
    teleworking,
    setTeleworking,
    workingTime,
    setWorkingTime,
    minSalary,
    setMinSalary,
    maxSalary,
    setMaxSalary,
})=> {

    const contratTypeOptions = useMemo(()=> enumToObjectsArray(EnumContratType), []);
    const teleworkingOptions = useMemo(()=> enumToObjectsArray(TeleWorkingTypeEnum), []);
    const workingTimeOptions = useMemo(()=> enumToObjectsArray(EnumWorkTime), []);

    return (
        <CardPage style={cardPageStyles}>
            <CardPageTitle>Paramètres de l’offre</CardPageTitle>

            <Form>
                <FormSection>
                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Type de contrat</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={contratType}
                            onChange={setContratType}
                            options={contratTypeOptions}
                        />
                    </InfosWrapper>

                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Début du contrat</InfoLabel>
                        <Input 
                            inputType='date'
                            placeholder="Selectionner"
                            value={startDate}
                            onChange={setStartDate}
                        />
                    </InfosWrapper>
                </FormSection>

                <FormSection>
                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Télétravail</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={teleworking}
                            onChange={setTeleworking}
                            options={teleworkingOptions}
                        />
                    </InfosWrapper>

                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Temps de travail</InfoLabel>
                        <SimpleSelect 
                            placeholder="Selectionner"
                            value={workingTime}
                            onChange={setWorkingTime}
                            options={workingTimeOptions}
                        />
                    </InfosWrapper>
                </FormSection>

                <FormSection>
                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Salaire min</InfoLabel>
                        <Input 
                            placeholder="min"
                            value={minSalary}
                            onChange={setMinSalary}
                        />
                    </InfosWrapper>

                    <InfosWrapper style={{flex: 1}}>
                        <InfoLabel>Salaire max</InfoLabel>
                        <Input 
                            placeholder="max"
                            value={maxSalary}
                            onChange={setMaxSalary}
                        />
                    </InfosWrapper>
                </FormSection>
            </Form>
        </CardPage>
    )
}