import { EnumUserLevel } from "@utilities/types"

export const getLevelColors = (level: EnumUserLevel)=> {

    const color = level === EnumUserLevel.debutant ? '#FFFACD'
                : level === EnumUserLevel.intermediaire ? '#E6E6FA'
                : level === EnumUserLevel.confirme ? '#87CEEB'
                : '#c3c3c3';

    const bgColor = level === EnumUserLevel.debutant ? '#FFFEF7'
                    : level === EnumUserLevel.intermediaire ? '#F9F9FF'
                    : level === EnumUserLevel.confirme ? '#ECFAFF'
                    : '#fff';

    return {
        color,
        bgColor,
    }
}