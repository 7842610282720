import { 
    CardPage, CardPageTitle, 
    FixedModalFooter, Icon, InfoLabel, 
    InfosWrapper, Input, InputLabel, 
    InputWrapper, Modal, ModalContainer, 
    ModalSubTitle, ModalTitle, 
    SimpleBadge, SimpleButton,
} from "@components";
import { CSSProperties, FC, useCallback, useState } from "react";
import { IconWrapper, SocialMediaWrapper } from "../styles";
import { IconNameEnum } from "@utilities/enums";
import { Organisation } from "@utilities/types";

interface Props {
    organisation: Organisation | null
    simpleButtonStyles: CSSProperties
    simpleButtonOutlineStyle: CSSProperties
    onUpdatedOrganisation: (organisation: Organisation)=> void
}

export const SocialMedia:FC<Props> = ({
    organisation,
    simpleButtonStyles,
    simpleButtonOutlineStyle,
    onUpdatedOrganisation,
})=> {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [facebook, setFacebook] = useState<string>(organisation?.media?.facebook || '');
    const [instagram, setInstagram] = useState<string>(organisation?.media?.instagram || '');
    const [linkedin, setLinkedin] = useState<string>(organisation?.media?.linkedin || '');
    const [site1, setSite1] = useState<string>(organisation?.media?.site1 || '');
    const [site2, setSite2] = useState<string>(organisation?.media?.site2 || '');

    const toggleIsOpen = useCallback(()=> {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const handleSave = useCallback(()=> {
        if(organisation){
            onUpdatedOrganisation({
                ...organisation,
                media: {
                    ...(facebook ? { facebook } : {}),
                    ...(instagram ? { instagram } : {}),
                    ...(linkedin ? { linkedin } : {}),
                    ...(site1 ? { site1 } : {}),
                    ...(site2 ? { site2 } : {}),
                },
            });
    
            toggleIsOpen();
        }
    }, [
        organisation,
        facebook, instagram,
        linkedin, site1, site2,
        onUpdatedOrganisation,
        toggleIsOpen,
    ]);

    return (
        <>
            <CardPage>
                <CardPageTitle>Media</CardPageTitle>

                <SimpleButton style={simpleButtonStyles} onClick={toggleIsOpen}>
                    Modifier
                </SimpleButton>

                {
                    (
                        organisation?.media?.facebook ||
                        organisation?.media?.instagram ||
                        organisation?.media?.linkedin
                    ) && (
                        <SocialMediaWrapper>
                            {
                                organisation.media.facebook && (
                                    <IconWrapper style={{backgroundColor: '#1877F2'}}>
                                        <Icon
                                            iconName={IconNameEnum.facebook} 
                                            size='2x'
                                            color="#fff"
                                        />
                                    </IconWrapper>
                                )
                            }

                            {
                                organisation.media.instagram && (
                                    <IconWrapper style={{backgroundColor: '#fccc63'}}>
                                        <Icon 
                                            iconName={IconNameEnum.insta} 
                                            size='2x'
                                            color="#fff"
                                        />
                                    </IconWrapper>
                                )
                            }

                            {
                                organisation.media.linkedin && (
                                    <IconWrapper style={{backgroundColor: '#0077B5'}}>
                                        <Icon 
                                            iconName={IconNameEnum.linkedin}
                                            size='2x'
                                            color="#fff"
                                        />
                                    </IconWrapper>
                                )
                            }
                        </SocialMediaWrapper>
                    )
                }

                {
                    organisation?.media?.site1 && (
                        <InfosWrapper>
                            <InfoLabel>
                                Site 1
                            </InfoLabel>

                            <SimpleBadge>🌐 {organisation?.media?.site1}</SimpleBadge>
                        </InfosWrapper>
                    )
                }

                {
                    organisation?.media?.site2 && (
                        <InfosWrapper>
                            <InfoLabel>
                                Site 2
                            </InfoLabel>

                            <SimpleBadge>🌐 {organisation?.media?.site2}</SimpleBadge>
                        </InfosWrapper>
                    )
                }
            </CardPage>

            <Modal
                isVisible={isOpen}
                onClose={toggleIsOpen}
            >
                <>
                    <ModalContainer>
                        <ModalTitle>
                            Media
                        </ModalTitle>

                        <ModalSubTitle>
                            Modification des medias
                        </ModalSubTitle>

                        <InputWrapper>
                            <InputLabel>
                                Facebook
                            </InputLabel>
                            <Input
                                placeholder="Facebook"
                                value={facebook}
                                onChange={setFacebook}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Instagram
                            </InputLabel>
                            <Input
                                placeholder="Instagram"
                                value={instagram}
                                onChange={setInstagram}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Linkedin
                            </InputLabel>
                            <Input
                                placeholder="Linkedin"
                                value={linkedin}
                                onChange={setLinkedin}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Site 1
                            </InputLabel>
                            <Input
                                placeholder="ex: http://google.com"
                                value={site1}
                                onChange={setSite1}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>
                                Site 2
                            </InputLabel>
                            <Input
                                placeholder="ex: http://google.com"
                                value={site2}
                                onChange={setSite2}
                            />
                        </InputWrapper>
                    </ModalContainer>

                    <FixedModalFooter>
                        <SimpleButton onClick={handleSave}>
                            Enregistrer
                        </SimpleButton>

                        <SimpleButton onClick={toggleIsOpen} style={simpleButtonOutlineStyle}>
                            Annuler
                        </SimpleButton>
                    </FixedModalFooter>
                </>
            </Modal>
        </>
    )
}