import { FC, useCallback, useState } from "react";
import styled from "styled-components";
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { TimeSlots } from "./TimeSlots";
import { SimpleButton } from "./simpleButton";
import { TimePicker } from "./timePicker";
import { FontFamilyEnum } from "@utilities/enums";
import { useScreenSize } from "@utilities/hooks";
import { Event } from "@utilities/types";
import { formatDate } from "@utilities/functions";

const CalendarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;

    @media (min-width: 760px){
        flex-direction: row;
        gap: 50px;
        padding: 50px;
        border-radius: 20px;
    }
`;

const TimeSlotsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    @media (min-width: 760px){
        margin-top: 0px;
    }
`;

const TimePickerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const SelectedDateTxt = styled.p`
    margin: 0%;
    text-align: center;
    font-size: 20px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_Bold};
`;

const SectionTitle = styled.p`
    margin: 0%;
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
`;

const Dot = styled.span`
  height: 10px;
  width: 10px;
  background-color: #40D794;
  border-radius: 50%;
  display: inline-block;
`;

const EnventTxt = styled.p`
    margin: 0%;
    margin-top: 5px;
    font-size: 14px;
    color: #101214;
    font-family: ${FontFamilyEnum.SF_Pro_Display_REGULAR};
`;

interface Props {
    myEventsList: Event[]
    timeSlots: string[]
    onAddNewTimeSlot: (
        start: Date, end: Date, 
        startTimeSlot: string, endTimeSlot: string,
    )=> void
    onDeletedTimeSlot: (
        timeSlotStartDate: Date,
        timeSlotEndDate: Date,
    )=> void
    onSelectedDate: (e: string)=> void
}

moment.locale('fr');
const localizer = momentLocalizer(moment);

const messages = {
    allDay: 'Toute la journée',
    previous: 'Précédent',
    next: 'Suivant',
    today: "Aujourd'hui",
    month: 'Mois',
    week: 'Semaine',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Événement',
};

export const CalendarEvents:FC<Props> = ({
    myEventsList,
    timeSlots,
    onAddNewTimeSlot,
    onDeletedTimeSlot,
    onSelectedDate,
})=> {

    const { isMobile } = useScreenSize();

    const [currentView, setCurrentView] = useState<View>('month');
    const [newTimeSlotStart, setNewTimeSlotStart] = useState<string>('');
    const [newTimeSlotEnd, setNewTimeSlotEnd] = useState<string>('');

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const minTime = new Date();
    minTime.setHours(6, 0, 0);

    const maxTime = new Date();
    maxTime.setHours(23, 0, 0);

    const renderEventComponent = useCallback(({ event }: { event: Event }) => (
        <div>
            {event.isEnabled && <Dot />} <label>{event.title}</label>
            {
                (currentView === 'agenda' && event.resource) && (
                    <>
                        <EnventTxt>{event.resource.location}</EnventTxt>
                        <EnventTxt>{event.resource.notes}</EnventTxt>
                    </>
                )
            }
        </div>
    ), [currentView]);

    const handleSelectSlot = useCallback(({ start }: { start: Date }) => {
        onSelectedDate(formatDate(start));
        setSelectedDate(
            selectedDate && start.getTime() === selectedDate.getTime() ? null : start
        );
    }, [
        selectedDate, onSelectedDate,
    ]);

    const handleAddNewTimeSlot = useCallback(()=> {
        if(newTimeSlotStart && newTimeSlotEnd && selectedDate){
            // Convertir les heures en "HH:mm" en Date pour les comparer
            const [startHour, startMinute] = newTimeSlotStart.split(':').map(Number);
            const [endHour, endMinute] = newTimeSlotEnd.split(':').map(Number);

            // Créer des copies de selectedDate pour éviter de modifier l'original
            const newTimeSlotStartDate = new Date(selectedDate.getTime());
            newTimeSlotStartDate.setHours(startHour, startMinute, 0, 0);

            const newTimeSlotEndDate = new Date(selectedDate.getTime());
            newTimeSlotEndDate.setHours(endHour, endMinute, 0, 0);

            // Vérification si l'heure de fin est bien après l'heure de début
            if (newTimeSlotEndDate > newTimeSlotStartDate) {
                onAddNewTimeSlot(
                    newTimeSlotStartDate, newTimeSlotEndDate,
                    newTimeSlotStart, newTimeSlotEnd,
                );
                setNewTimeSlotStart('');
                setNewTimeSlotEnd('');
            } else {
                console.log('newTimeSlotEnd n\'est pas après newTimeSlotStart');
            }
        }
    }, [
        newTimeSlotStart,
        newTimeSlotEnd, 
        selectedDate,
        onAddNewTimeSlot,
    ]);

    const handleDeletedTimeSlot = useCallback((timeSlot: string)=> {
        if(selectedDate){
            const [startDate, endDate] = timeSlot.split('-').map(String);
            const [startDateHoure, startDateMinute] = startDate.split(':').map(Number);
            const [endDateHoure, endDateMinute] = endDate.split(':').map(Number);

            const timeSlotStartDate = new Date(selectedDate.getTime());
            timeSlotStartDate.setHours(startDateHoure, startDateMinute, 0, 0);

            const timeSlotEndDate = new Date(selectedDate.getTime());
            timeSlotEndDate.setHours(endDateHoure, endDateMinute, 0, 0);

            onDeletedTimeSlot(timeSlotStartDate, timeSlotEndDate);
        }
    }, [selectedDate, onDeletedTimeSlot]);

    const handleViewChange = useCallback((view: View) => {
        setCurrentView(view);
    }, []);

    return (
        <CalendarWrapper>
            <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"
                min={minTime}
                max={maxTime}
                messages={messages}
                selectable={true}
                onSelectSlot={handleSelectSlot}
                view={currentView}
                onView={handleViewChange}
                components={{
                    event: renderEventComponent,
                }}
                style={{ ...(isMobile ? {} : { flex: 1 }), height: 500 }}
            />

            {
                selectedDate && (
                    <TimeSlotsWrapper>
                        <SelectedDateTxt>
                            {selectedDate.toLocaleDateString()}
                        </SelectedDateTxt>

                        <div>
                            <SectionTitle>
                                Ajouter une disponibilité
                            </SectionTitle>

                            <TimePickerWrapper>
                                <TimePicker value={newTimeSlotStart} onChange={e=> setNewTimeSlotStart(e.target.value)} />
                                <TimePicker value={newTimeSlotEnd} onChange={e=> setNewTimeSlotEnd(e.target.value)} />

                                <SimpleButton onClick={handleAddNewTimeSlot}>Ajouter</SimpleButton>
                            </TimePickerWrapper>
                        </div>

                        <TimeSlots 
                            isDangerMode
                            timeSlots={timeSlots}
                            onTimeSlotSelect={(e)=> handleDeletedTimeSlot(e)}
                        />
                    </TimeSlotsWrapper>
                )
            }
        </CalendarWrapper>
    )
}